import { UUID } from 'angular2-uuid';

export class ViewButton {
    public id: string;
    public title: string;
    public tooltipText: string;
    public color: string;
    public background: string;
    public onClick: any;
    public children: any;
    public type: string;



    constructor(title, tooltipText, color, background,type, onClick) {
        
        this.id = UUID.UUID();
        this.type = type;
        this.title = title;
        this.tooltipText = tooltipText;
        this.color = color;
        this.background = background;
        this.children = null;
        this.onClick = onClick;
      


    }



}




