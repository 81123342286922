import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-photospheres-tool',
  templateUrl: './photospheres-tool.component.html',
  styleUrls: ['./photospheres-tool.component.scss']
})
export class PhotospheresToolComponent implements OnInit, OnDestroy {
  subscriptions = [];
  photospheres = {};
  openedPhotospheres = {};
  photosphereWaitingForPosition = null;
  inUpload = false;

  @ViewChild('photosphereFile', { static: false }) photosphereFile: ElementRef;

  constructor(public dataService: DataService, private appActions: AppActionsService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscriptions.push(this.dataService.photospheresUpdated.subscribe(() => {
      this.photospheres = this.dataService.photospheres;
    }))

    this.subscriptions.push(this.appActions.threejsClick.subscribe(e => {
      if (this.photosphereWaitingForPosition) {
        e.positionModelSpace.z += 1.5;
        this.dataService.updatePhotosphere(this.photosphereWaitingForPosition, { position: e.positionModelSpace })
        this.photosphereWaitingForPosition = null
      }

    }))

    this.subscriptions.push(this.appActions.photsphereLabelClicked.subscribe(id => {
      this.openedPhotospheres[id] = true;
      this.cdr.detectChanges();
      document.getElementById('photosphere_container_' + id).scrollIntoView({ behavior: "smooth", block: "end" });
    }))



  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  test() {

    console.log('config:', this.dataService.viewConfig.photospheresConfig)

  }



  photospherePositionChanged(id, coordinate, event) {
    let position = this.photospheres[id].position;
    position[coordinate] = +event.target.value;
    this.dataService.updatePhotosphere(id, { position: position })
  }

  photosphereOpacityChanged(id, event) {
    const opacity = Math.max(0, Math.min(1, +event.target.value));

    this.dataService.updatePhotosphere(id, { opacity: opacity })
  }

  photosphereRadiusChanged(id, event) {
    const radius = Math.max(1, +event.target.value);

    this.dataService.updatePhotosphere(id, { radius: radius })
  }

  toggleProperites(id) {
    this.photosphereWaitingForPosition = null;
    if (this.openedPhotospheres[id]) {
      delete this.openedPhotospheres[id]
    } else {
      this.openedPhotospheres[id] = true;
    }
  }

  toggleVisibility(id) {
   
    if( this.dataService.viewConfig.photospheresConfig[id] == null) {
      this.dataService.viewConfig.photospheresConfig[id] = {visible:false}
    }
    this.dataService.viewConfig.photospheresConfig[id].visible = !this.dataService.viewConfig.photospheresConfig[id].visible;
    console.log(this.dataService.viewConfig.photospheresConfig)
    this.dataService.photospheresUpdated.next();

  }



  deletePhotosphere(id) {
    this.dataService.deletePhotosphere(id)
  }

  pinPhotosphereClicked(id) {
    if (this.photosphereWaitingForPosition == id) {
      this.photosphereWaitingForPosition = null;
    } else {
      this.photosphereWaitingForPosition = id;
    }

  }

  onFileInputChanged(event) {
    const file = this.photosphereFile.nativeElement.files[0];

    if (file) {
      this.inUpload = true;
      this.dataService.addNewPhotoSphere(file).finally(() => {
        this.inUpload = false;
      }).catch(err => {
        console.warn(err)
        this.appActions.notify('Error uploading Photosphere', 'error', 5)
      })
    }

  }

  photospherePropertyChanged(id, property, value) {

    let updates = {};
    updates[property] = value;
    this.dataService.updatePhotosphere(id, updates)
  }

  goToPhotosphereClick(id) {
    this.appActions.flyToPhotosphereClicked.next(id)
  }

}
