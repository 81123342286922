import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-user-alert',
  templateUrl: './user-alert.component.html',
  styleUrls: ['./user-alert.component.scss']
})
export class UserAlertComponent implements OnInit {

  @Input() message:string;
  @Input() title:string;
  @Output() closeClicked = new EventEmitter();
  @Input() color:string;
  constructor() { }

  ngOnInit() {
  }

  close() {
    this.closeClicked.emit();
  }

}
