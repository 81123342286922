export class AppConfig {
    public firebaseConfig: any;
    public siteDomain: string;
    public bimserverConfig: any;
    public ifcToColladaConfig: any;
    public firebaseAccessConfig: any;

    constructor() {
        this.firebaseConfig = {
            apiKey: "AIzaSyBVzudmKVGnE3Lmgyuttx2NF7zhG0Q0ShA",
            authDomain: "bimshow-dev1.firebaseapp.com",
            databaseURL: "https://bimshow-dev1.firebaseio.com",
            projectId: "bimshow-dev1",
            storageBucket: "bimshow-dev1.appspot.com",
            messagingSenderId: "337558794368",
            appId: "1:337558794368:web:9829c299c9c46af1f8b665"
        };

        this.siteDomain = 'https://beta.dev1.bimshow.io';

        this.bimserverConfig = {
            domain: "https://bimserver-gw.dev1.bimshow.io/",
            user: 'web-app-user@bimshow.io',
            password: 'user'

        };

        this.ifcToColladaConfig = {
            host: "https://beta.dev1.bimshow.io/ifc2collada"
        };

        this.firebaseAccessConfig = {
            host: "https://beta.dev1.bimshow.io/firebase-access-api"
        }

    }

}
