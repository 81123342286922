import { Component, OnInit, OnDestroy, ChangeDetectorRef, ɵConsole } from '@angular/core';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { AppActionsService } from '../services/app-actions.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToolboxEvent } from 'src/models/toolbox-event';

@Component({
  selector: 'app-labels-editor',
  templateUrl: './labels-editor.component.html',
  styleUrls: ['./labels-editor.component.scss']
})
export class LabelsEditorComponent implements OnInit, OnDestroy {
  labels = {}

  subscriptions: Subscription[] = [];
  // huebackground = null;
  openedLabels = {};
  sortOrder = [];

  constructor(private dataService: DataService, private appActionsService: AppActionsService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    console.log('init of labels editor ')



    // this.generateHueBackground();
    this.labels = this.dataService.labels;
    this.sortLabels();
    this.subscriptions.push(this.dataService.labelsUpdated.subscribe(() => {

      this.sortLabels();
      this.cdr.detectChanges();
    }))

    this.subscriptions.push(this.appActionsService.loadViewConfig.subscribe(viewConfig => {


      let config = {};

      if (viewConfig) {
        if (viewConfig.labelsConfig) {
          config = viewConfig.labelsConfig;

        }

      }

      this.loadLabelsConfigFromViewConfig(config)



    }))

    this.subscriptions.push(
      this.appActionsService.objectDataAction.subscribe(e => {
        if (e.action == 'labelClicked') {
          this.openedLabels = {};

          for (let key in this.labels) {
            if (this.labels[key].guid == e.guid) {
              this.openedLabels[key] = true;
              this.cdr.detectChanges();
              setTimeout(() => {
                document.getElementById('label_' + key).scrollIntoView(false)
              }, 20)

            }
          }
        }

        if (e.action == 'labelToggleVisible') {

          for (let key in this.labels) {
            if (this.labels[key].guid == e.guid) {
              this.toggleLabelVisibility(key)

            }
          }

        }
      })
    )
  }

  ngOnDestroy() {
    console.log('destroyed')
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  sortLabels() {


    if (this.dataService.viewConfig.labelsConfig) {
      if (this.dataService.viewConfig.labelsConfig['sortOrder']) {
        this.sortOrder = this.dataService.viewConfig.labelsConfig['sortOrder'];
      }

    }

    for (let key in this.labels) { //pushing keys that are not in the sort order!
      if (this.sortOrder.indexOf(key) == -1) {
        this.sortOrder.push(key);
      }

    }

    //removing keys that doesnt have labels:
    let keysToRemoveFromSort = [];
    for (let key of this.sortOrder) {
      if (this.labels[key] == null) {
        keysToRemoveFromSort.push(key)
      }
    }
    for (let key of keysToRemoveFromSort) {
      this.sortOrder.splice(this.sortOrder.indexOf(key), 1)
    }

    for (let i = 0; i < this.sortOrder.length; i++) { //setting index label according to new order
      this.labels[this.sortOrder[i]].index = i;

    }


  }




  // generateHueBackground() {
  //   this.huebackground = 'linear-gradient(to right'

  //   for (let i = 0; i < 360; i++) {
  //     this.huebackground = this.huebackground + ',hsl(' + i + ',50%,60%)'
  //   }

  //   this.huebackground = this.huebackground + ')'
  // }



  removeLabel(key) {
    this.dataService.removeLabel(key)
  }

  labelTextChanged(key, event) {
    let updates = null;

    if (event.target.value != '') {
      updates = {
        text: event.target.value
      }
    }


    if (updates) {
      this.dataService.updateLabel(key, updates)
    }

  }



  labelColorClosed(key, event) {

   
    const updates = {
      color: event
    }

    this.dataService.updateLabel(key, updates)

  }

  labelTitleChanged(key, event) {
    let updates = null;

    if (event.target.value != '') {
      updates = {
        title: event.target.value
      }
    }


    if (updates) {
      this.dataService.updateLabel(key, updates)
    }

  }

  // labelColorChanged(key, hue) {
  //   console.log('changed color')
  //   if (this.labels[key]) {
  //     this.labels[key].hue = hue;
  //   }

  //   this.cdr.detectChanges()
  //   this.updateViewConfig()
  // }



  uploadImage(key, fileInput) {

    let file = fileInput.files[0]
    fileInput.value = null;


    const element = file;
    if ((element.type == 'image/png') || (element.type == 'image/jpeg') || (element.type == 'image/jpg') || (element.type == 'image/gif')) {



      var reader = new FileReader();
      reader.onload = (event: any) => {
        var img = new Image();
        img.onload = () => {


          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');

          let height = Math.min(img.height, 200)
          canvas.width = height * img.width / img.height;
          canvas.height = height;



          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);


          this.dataService.updateLabel(key, {
            image: canvas.toDataURL()
          })




        }


        if (typeof event.target.result == 'string') {
          img.src = event.target.result;
        }



      }

      reader.readAsDataURL(element);
    }


  }

  removeImage(key) {
    this.dataService.updateLabel(key, { image: null }).then(
      deleted => {
        delete this.labels[key].image // needed here because the update that happens in dataService is actully on reacting to (child_changed) event in firebase, but the way i wrote it (in dataService) is that it only update the existing properties in firebase. therefor its not nulling the 'image' property. so we delete it here!
      }
    )
  }

  toggleLabelProperites(key) {
    if (this.openedLabels[key]) {
      delete this.openedLabels[key]
    } else {
      this.openedLabels[key] = true;
    }

  }


  toggleLabelVisibility(key) {
    if (this.labels[key]) {
      if (this.labels[key].visible) {
        this.labels[key].visible = false;
      } else {
        this.labels[key].visible = true;
      }
    }

    this.cdr.detectChanges()
    this.updateViewConfig()
    this.dataService.labelsUpdated.next();
  }

  toggleLabelAlwaysShowText(key) {
    // if (this.labels[key]) {
    //   if (this.labels[key].alwaysShowText) {
    //     this.labels[key].alwaysShowText = false;
    //   } else {
    //     this.labels[key].alwaysShowText = true;
    //   }
    // }

    // this.cdr.detectChanges()
    this.updateViewConfig()
  }

  hideAllLabels() {

    for (let key in this.labels) {
      this.labels[key].visible = false;
    }
    this.cdr.detectChanges();
  }

  loadLabelsConfigFromViewConfig(labelsConfig) {

    this.hideAllLabels();
    if (labelsConfig) {


      for (let key in labelsConfig) {
        if (this.labels[key]) {
          // if (labelsConfig[key].hue) {

          //   this.labels[key]['hue'] = labelsConfig[key].hue;
          // } else {
          //   delete this.labels[key]['hue'];
          // }

          if (labelsConfig[key].visible != null) {
            this.labels[key]['visible'] = labelsConfig[key].visible;
          } else {
            delete this.labels[key]['visible'];
          }

          if (labelsConfig[key].alwaysShowText != null) {
            this.labels[key]['alwaysShowText'] = labelsConfig[key].alwaysShowText;
          } else {
            delete this.labels[key]['alwaysShowText'];
          }
        }



      }
    }

    this.sortLabels();
    this.cdr.detectChanges()


  }

  updateViewConfig() {

    let labelsConfig = {
      sortOrder: this.sortOrder
    }

    for (let key in this.labels) {
      if (labelsConfig[key] == null) {
        labelsConfig[key] = {}
      }

      // if (this.labels[key].hue) {
      //   if (labelsConfig[key]) {
      //     labelsConfig[key]['hue'] = this.labels[key].hue;
      //   }

      // }
      if (this.labels[key].visible != null) {
        if (labelsConfig[key]) {
          labelsConfig[key]['visible'] = this.labels[key].visible;
        }
      }

      if (this.labels[key].alwaysShowText != null) {
        if (labelsConfig[key]) {
          labelsConfig[key]['alwaysShowText'] = this.labels[key].alwaysShowText;
        }
      }

    }
    this.dataService.viewConfig['labelsConfig'] = labelsConfig;



  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.sortOrder, event.previousIndex, event.currentIndex);
    this.sortLabels();
    this.updateViewConfig();
  }

  labelOffsetChanged(key, coordinate, value) {
    if (value == null) {
      value = 0;
    }
    if (this.labels[key]) {
      if (this.labels[key].offset == null) {
        this.labels[key]['offset'] = { x: 0, y: 0, z: 0 }
      }

      let offset = {
        x: this.labels[key].offset.x,
        y: this.labels[key].offset.y,
        z: this.labels[key].offset.z
      }

      offset[coordinate] = parseInt(value);

      let updates = {
        offset: offset
      }




      if (updates)
        this.dataService.updateLabel(key, updates)

    }

  }

  // this.cdr.detectChanges()
  // this.updateViewConfig()




}
