export class User {
    public id: string;
    public firstName:string;
    public lastName:string;
    public email:string;
    public avatarUrl:string;
    


    constructor(id,firstName,lastName,email,avatarUrl){
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.avatarUrl = avatarUrl;
    }



}

