export class ViewConfig {
    public pdfCompareMode: any;
    public labelsConfig:any;
    public layersConfig:any;
    public treeConfig:any;
    public zonesMode: any[];
    public selectedMode: any;
    public MaterialsMode: any;
    public cameraParameters : any;
    public mapboxMode: any;
    public clippingMode: any;
    public photospheresConfig:any;
    

    constructor(){
    this.pdfCompareMode = {};
    this.labelsConfig  = {};
    this.layersConfig  = [];
    this.treeConfig  = {};
    this.zonesMode  = [];
    this.selectedMode  = {};
    this.MaterialsMode = {};
    this.cameraParameters = {};
    this.mapboxMode = 'off';
    this.clippingMode = {}
    this.photospheresConfig = {};

       
    }


}

