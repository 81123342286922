import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { ToolboxEvent } from 'src/models/toolbox-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-clipping-planes-editor',
  templateUrl: './clipping-planes-editor.component.html',
  styleUrls: ['./clipping-planes-editor.component.scss']
})
export class ClippingPlanesEditorComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  clippingOn = false;

  constructor(private appActionsService: AppActionsService) { }

  ngOnInit() {

    this.subscriptions.push(this.appActionsService.loadViewConfig.subscribe(viewConfig => {


      if (viewConfig) {
        if (viewConfig.clippingMode) {

          this.clippingOn = viewConfig.clippingMode.clippingOn ? true : false;
         

        }
      }
    }))
  }


  ngOnDestroy() {



    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }


  update() {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('clippingPlanes', 'updates', this.constructor.name, { clippingOn: this.clippingOn }))
  }

  resetToProjectBox() {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('clippingPlanes', 'updates', this.constructor.name, { reset: true }))
  }

}
