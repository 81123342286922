import { Component, OnInit } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';

@Component({
  selector: 'app-cookiebar',
  templateUrl: './cookiebar.component.html',
  styleUrls: ['./cookiebar.component.scss']
})
export class CookiebarComponent implements OnInit {

  constructor(private appActions: AppActionsService) { }

  ngOnInit() {
  }

  okClicked() {
    this.appActions.cookieOk.next();
  }

}
