import { UUID } from "angular2-uuid";

export class NoteComment {

    public id: string;
    public date: string;
    public noteId: string;
    public text: string;
    public createdBy: string;

    constructor(text, uid, noteId) {
        this.id = null;
        this.date = new Date().toString();
        this.noteId = noteId;
        this.text = text;
        this.createdBy = uid;


    }
}