import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';

import { TreeNode } from '../../models/treenode.model';
import { NestedTreeControl, FlatTreeControl } from '@angular/cdk/tree';

import { MatTreeNestedDataSource, MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';

interface FlatNode {
  expandable: boolean;
  Name: string;
  level: number;
  id: number;
  parentId: number;
  children: any[],
  visible: number
}

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeViewComponent implements OnInit, OnDestroy {
  tree: TreeNode;
  TREE_DATA: TreeNode[] = [];
  loading = true;
  chosenObjectOid = null;
  dataSource: MatTreeFlatDataSource<TreeNode, FlatNode> = null;
  subscriptions: Subscription[] = [];
  hiddenObjects = [];
  categoryGuidsOids = {};
  nodes = null;
  filterString = '';

  @Input('modeActive') modeActive: Boolean;
  @ViewChild('treeContainer', { static: false }) domTreeContainer;


  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      Name: node.Name,
      level: level,
      id: node.id,
      parentId: node.parentId,
      type: node.type,
      children: node.children,
      visible: 1
 
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);




  constructor(private appActionsService: AppActionsService, private cdr: ChangeDetectorRef, public dataService: DataService) {


    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = this.TREE_DATA;
  }
  hasChild = (_: number, node: FlatNode) => node.expandable;


  ngOnInit() {

    this.subscriptions.push(this.appActionsService.ifcDataFetched.subscribe((ifcData) => {
      let tree:TreeNode = ifcData.tree;
      this.nodes = ifcData.nodes;
      if (tree == null) {
        return;
      }
      // this.tree = tree.children[0]; //start from site
      this.tree = tree.children[0].children[0]; //start from project
      this.tree = tree;
      this.TREE_DATA.push(this.tree) 
    
      this.dataSource.data = this.TREE_DATA;


      this.treeControl.expansionModel.select(this.getFlatNodeByOid(this.tree.id),this.getFlatNodeByOid(this.tree.id))

      this.loading = false;

      this.cdr.detectChanges();

    }))

    this.subscriptions.push(this.appActionsService.chosenObjectOid.subscribe((oid) => {

      this.chosenObjectOid = oid;


      if (oid != null) {

        this.selectNodeAndAncs(this.getFlatNodeByOid(oid));

      }




      this.cdr.detectChanges();

      if (oid != null) {
        let domNode = document.getElementById('treenode_' + oid)
        if (domNode) {
          domNode.scrollIntoView(false);
        }

      }



    }))

    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(viewConfig => {



        let config = null
        if (viewConfig) {
          if (viewConfig.treeConfig)
            config = viewConfig.treeConfig

        }
        if (config != null) {

          console.log('loading tree config')
          this.loadTreeConfig()
        } else {
          console.log('reseting tree config') //incase old photo didnt have this... viewconfig configuration
          this.resetTreeConfig();
        }

      }))
  }

  ngOnChanges(c) {
    // if (c.modeActive) {
    //   if (c.modeActive.currentValue) {
    //     console.log('scanning')
    //     // this.scanVisibilityFromHiddenObjects();
    //   }
    // }
  }
  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  scanTree() {
    let tic = performance.now();
    let updateNodeVisibilityInViewConfig = (node: FlatNode) => {

      let guid = this.dataService.getGuidOfObjectId(node.id)
      if (guid == null) {
      
        guid = this.getGuidOfCatOid(node.id)
      }


      if (node.visible == 0) {

        this.hiddenObjects.push({guid: guid, hidden:true})

      } else {


        if (node.visible == 0.5) {

          for (let child of node.children) {

            updateNodeVisibilityInViewConfig(this.getFlatNodeByOid(child.id))

          }
        }


      }
    }

    this.hiddenObjects = [];
    this.dataService.viewConfig.treeConfig['hiddenObjects'] = this.hiddenObjects;

    updateNodeVisibilityInViewConfig(this.getFlatNodeByOid(this.tree.id));
    console.log('treeScan took: '+(performance.now()-tic)+'ms')
    console.log(this.hiddenObjects)




  }


  getFlatNodeByOid(oid) {
    for (let node of this.treeControl.dataNodes) {
      if (node.id == oid) {

        return node;
      }
    }

  }

  setCatParentVisibility() {
    let catNodes = [];
    for (let node of this.treeControl.dataNodes) {
      if (node.Name == 'IfcSpaceCategory') {
        this.setParentsVisibility(node);
      }
    }

  }

  setParentsVisibility(n) {

    let parent = this.getFlatNodeByOid(n.parentId);
    if (parent == null) {
      return;
    }

    let visibleCount = 0;
    for (let child of parent.children) {
      let flatNode = this.getFlatNodeByOid(child.id)

      if (flatNode.visible > 0) {
        visibleCount = visibleCount + flatNode.visible
      }
    }

    if (visibleCount == parent.children.length) {
      parent.visible = 1;

    } else {
      if (visibleCount > 0) {
        parent.visible = 0.5;

      } else {
        parent.visible = 0;
      }
    }







    if (parent.parentId != null) {
      this.setParentsVisibility(parent)
    }

  }

  selectNodeAndAncs(node: FlatNode) {

    if (node) {
      this.treeControl.expansionModel.select(node)
      if (node.parentId != null) {
        this.selectNodeAndAncs(this.getFlatNodeByOid(node.parentId))
      }
    }

  }



  objectButtonClicked(oid) {
    if (this.chosenObjectOid == oid) {
      this.appActionsService.chosenObjectOid.next(null)
    }
    else {
      this.appActionsService.chosenObjectOid.next(oid)
    }
  }

  changeNodeAndParentsVisibility(node, visible) {


    let pushAllChildrenObjects = (n, oids) => { //this generating all generations !!! not on only 1st generation

      oids.push(n.id)


      if (n.children) {
        for (let childNode of n.children) {

          pushAllChildrenObjects(childNode, oids)
        }
      }
    }




    let oids = [];
    let newState = null;


    if (visible == true) {
      newState = 1;

    }

    if (visible == false) {
      newState = 0;


    }




    pushAllChildrenObjects(node, oids);
    for (let oid of oids) {
      this.getFlatNodeByOid(oid).visible = newState;
    }
    if (node.parentId != null) {
      this.setParentsVisibility(node)
    }

    //node and parents visibility updated !


    this.appActionsService.toggleVisibilityOfObjects.next({ oids: oids, state: newState == 1 ? true : false })

  }

  visibilityToggleClicked(node, event) {


    if (event) {
      event.stopPropagation()
    }

    this.changeNodeAndParentsVisibility(node, (node.visible > 0) ? false : true)
    this.scanTree();



  }

  ngOnChnges() {

  }

  getGuidOfCatOid(oid) {
    for (let guid in this.categoryGuidsOids) {

      if (this.categoryGuidsOids[guid] == oid) {

        return guid;
      }
    }

    return null;
  }

  getOidOfCatGuid(guid) {
    return this.categoryGuidsOids[guid]
  }


  resetTreeConfig() {

    this.changeNodeAndParentsVisibility(this.tree, true)
    this.hiddenObjects = [];
    this.cdr.detectChanges();
  }

  loadTreeConfig() {

    this.hiddenObjects = this.dataService.viewConfig.treeConfig.hiddenObjects;
   

    this.changeNodeAndParentsVisibility(this.tree, true)
    

    if (this.hiddenObjects) {
      for (let ho of this.hiddenObjects) {

        // let node = this.TREE_DATA[0].getNodeByOid(this.getOidByGuid(ho.guid));
        let node= this.nodes[this.getOidByGuid(ho.guid)]
        if (node) {
          this.changeNodeAndParentsVisibility(node, false)
  
        }
  
  
      }
    }
    


    this.scanTree();
    this.cdr.detectChanges();

  }

  // updateTreeConfig() {
  //   this.dataService.viewConfig.treeConfig = {
  //     hiddenObjects: this.hiddenObjects
  //   };


  // }


  getOidByGuid(guid) {
    let oid = this.dataService.getObjectIdOfGuid(guid)
    console.log(oid)
    if (oid == null) {
      if (guid.slice(0, 9) == '_catname_') {
        oid = this.getOidOfCatGuid(guid)
      }
    }

    return oid;


  }






}
