import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppActionsService } from '../services/app-actions.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  userDataForm: FormGroup;
  dragging = false;
  constructor(private dataService: DataService, private appActionsService:AppActionsService) { }

  ngOnInit() {
    this.initUserDataForm();
  }

  initUserDataForm() {
    this.userDataForm = new FormGroup({
      "firstName": new FormControl(this.dataService.user.firstName,Validators.required),
      "lastName": new FormControl(this.dataService.user.lastName, Validators.required),
      'email': new FormControl({ value: this.dataService.user.email, disabled: true }, [Validators.required, Validators.email]),
      'avatarUrl': new FormControl(this.dataService.user.avatarUrl)
    })
    
  }

  onSubmit() {
    console.log('onSubmit',this.userDataForm)
    if (!this.userDataForm.valid) {
      
      return;
    }

    this.dataService.updateUserData(this.userDataForm.value).then( done => {
      console.log('user data updated!')
      this.initUserDataForm();
    }).catch(err=>{
      console.warn(err);
    })
    
  }

  close() {
    this.appActionsService.openCloseUserSettings.next('close');
  }

}
