import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';

@Component({
  selector: 'app-view-gallery',
  templateUrl: './view-gallery.component.html',
  styleUrls: ['./view-gallery.component.scss']
})
export class ViewGalleryComponent implements OnInit, AfterViewInit {

  @Input('gallery') gallery: any[];
  @Input('viewId') viewId;

  selectedImage = null;
  selectedImageUrl = null;

  constructor(private dataService: DataService, private appActionsService: AppActionsService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.gallery) {
      this.selectImage(this.gallery[0]);
    }

    this.cdr.detectChanges();
  }

  selectImage(image) {
    this.selectedImage = image;
    this.scrollToImage(image)

    this.dataService.getViewImageUrl(image, this.viewId).then(
      url => {

        this.selectedImageUrl = url;

      }
    )
  }

  changeSelectedImage(n) {
    if (this.gallery == null) {
      return
    }
    for (let i = 0; i < this.gallery.length; i++) {
      if (this.gallery[i] == this.selectedImage) {

        let j = i + n;

        switch (j) {
          case this.gallery.length:
            j = 0;
            break;
          case -1:
            j = this.gallery.length - 1;
            break;
        }



        this.selectImage(this.gallery[j]);
        return;
      }
    }
  }

  exitGallery() {
    this.appActionsService.viewGalleryOn = false;
  }

  scrollToImage(image) {
    document.getElementById('thumb_' + image.id).scrollIntoView({ behavior: "smooth", inline: "start" });
    document.getElementById('thumb_' + image.id).scrollIntoView({ behavior: "smooth", inline: "end" });



  }
}
