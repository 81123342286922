export class Layer {
    public id: string;
    public name:string;
    public objectsOids:string[];
    public visibility:number;



    constructor(id,name,objectsOids,visibility){
        this.id = id;
        this.name = name;
        this.objectsOids = objectsOids;
        this.visibility=visibility;

       
    }

  
}

