import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-texture-folder',
  templateUrl: './texture-folder.component.html',
  styleUrls: ['./texture-folder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextureFolderComponent implements OnInit {

  textures = null;
  categories = {};


  constructor(public dataService: DataService, private appActions: AppActionsService, private cdr: ChangeDetectorRef) {


  }

  ngOnInit() {
    this.textures = this.dataService.textures;
    this.initCategories();


  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  
  close() {
   
    this.appActions.textureFolderClosed.next();
    this.appActions.textureFolderOpen = false;
  }

  initCategories() {
    for (let key in this.textures) {

      for (let cat in this.textures[key].categories) {
        if (this.categories[cat] == null) {
          this.categories[cat] = false;
        }
      }

    }
  }

  categoryClicked(key) {
    this.categories[key] = !this.categories[key];
    this.cdr.detectChanges();
  }

  textureIsFilteredIn(texture) {

    for (let cat in texture.categories) {
      if (this.categories[cat]) {
        return true
      }
    }

    return false
  }

  textureClicked(texture) {
    this.appActions.textureClicked.next(texture)
  }

}
