import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AppActionsService } from '../../services/app-actions.service';

@Component({
  selector: 'app-related-photos',
  templateUrl: './related-photos.component.html',
  styleUrls: ['./related-photos.component.scss']
})
export class RelatedPhotosComponent implements OnInit, OnDestroy {

  @Input ('photos') photos;
  @Input ('note') note;
  subscriptions = [];
  arrangedPhotos = [];

  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {

    
    this.arrangePhotos();
    this.subscriptions.push( this.dataService.photosDataFetched.subscribe( () => {
      this.arrangePhotos();
    }) )



  }

  ngOnChanges() {
    console.log('changed related photos')
  
     this.arrangePhotos()
  }

  ngOnDestroy() {
   
    this.subscriptions.forEach( sub =>{sub.unsubscribe();})
  }

  arrangePhotos() {
   
    this.arrangedPhotos = [];
     for(let key in this.photos) {      
      if ( this.dataService.photos[key]) {
        this.arrangedPhotos.push(this.dataService.photos[key]);
      }
       
     }
     
     console.log(this.arrangedPhotos)
   
   }




  removeRelatedPhoto (photo) {
    this.dataService.removePhotoFromNote(this.note.id,photo.id)
  }

  photoClicked(photo) {
    this.appActionsService.openPhoto.next(photo);
  }

}
