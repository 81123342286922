import { Component } from '@angular/core';
import { DataService } from '../services/data.service';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AppActionsService } from '../services/app-actions.service';


@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.scss']
})
export class ProfileEditorComponent {

  private emailValue = this.dataService.user.email;
  profileForm: FormGroup;

  initUserDataForm (){
    this.profileForm = this.fb.group({
      firstName: [this.dataService.user.firstName, Validators.required],
      lastName: [this.dataService.user.lastName, Validators.required],
      birthDate: [this.dataService.user.birthDate],
      email:[this.dataService.user.email, [Validators.required, Validators.email]],
      companyName: [this.dataService.user.companyName],
      avatarUrl: [this.dataService.user.avatarUrl],
      address: this.fb.group({
        street: [this.dataService.user.address ? this.dataService.user.address.street : null],
        street2: [this.dataService.user.address ? this.dataService.user.address.street2 : null],
        city: [this.dataService.user.address ? this.dataService.user.address.city : null],
        state: [this.dataService.user.address ? this.dataService.user.address.state : null],
        zip: [this.dataService.user.address ? this.dataService.user.address.zip : null]
      })
    })
  }
  

  constructor(
    private fb: FormBuilder, 
    private dataService: DataService,
    private appActionsService: AppActionsService
  ) { }

  ngOnInit() {
    this.initUserDataForm();
  }

  onSubmit() {
    console.log('onSubmit',this.profileForm)
    if (!this.profileForm.valid) {
      
      return;
    }

    this.dataService.updateUserData(this.profileForm.value).then( done => {
      console.log('user data updated!')
      this.initUserDataForm();
    }).catch(err=>{
      console.warn(err);
    })
    
  }

  close() {
    this.appActionsService.openCloseUserSettings.next('close');
  }

 
}