import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';

import { MatSelect } from '@angular/material/select';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router'


@Component({
  selector: 'app-projects-folder',
  templateUrl: './projects-folder.component.html',
  styleUrls: ['./projects-folder.component.scss']
})
export class ProjectsFolderComponent implements OnInit, OnDestroy, AfterViewInit {

  createProjectForm: FormGroup;
  sharedProjects = [];
  ownedProjects = [];
  publicProjects = [];
  selectedProject = null;
  @ViewChild('uploadIfcFile', { static: false }) uploadIfcFile: ElementRef;
  @ViewChild('newProjectName', { static: false }) newProjectName: ElementRef;
  ifcschema = 'ifc2x3tc1';
  fileName = null;
  createNewProjectMode = false;
  creatingProjectInProcess = null;
  createProjectError = null;
  subscriptions: Subscription[] = [];

  constructor(private dataService: DataService, private appActionsService: AppActionsService, private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log('inti')
    this.initCreateProjectForm();
    this.initProjectsData();
    this.subscriptions.push(this.appActionsService.projectsDataChanged.subscribe(() => {
      this.initProjectsData();
    }))





  }

  ngAfterViewInit() {
    this.loadProjectFromUrl()
  }


  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  loadProjectFromUrl() {

    let projectId = this.route.snapshot.queryParams.p;
    if (!projectId) {
      console.log('no project url')
      return;
    }
    if (this.dataService.project == null) {
      let projectWasListed = false;
      this.dataService.user.projects.forEach(project => {
        if (project.id == projectId) {
          console.log('%c' + 'loading project: ' + projectId, ' background: #222; color: #bada55')
          this.appActionsService.openProject.next(project);
          this.appActionsService.openCloseProjectsFolder.next('close');
          projectWasListed = true;

        }
      })

      if (!projectWasListed) {
        this.dataService.fetchUnlistedProject(projectId).then(
          (project) => {
            console.log('%c' + 'loading project: ' + projectId, ' background: #222; color: #bada55')
            this.appActionsService.openProject.next(project);
            this.appActionsService.openCloseProjectsFolder.next('close');
          },
          rejected => {
            console.log('rejected?')
          })
      }

    } else {
      console.log('project is already loaded')
    }




  }

  initCreateProjectForm() {
    this.createProjectForm = new FormGroup({
      'projectName': new FormControl(null, [Validators.required, this.forbiddenProjectNames.bind(this)]),
      'ifcSchema': new FormControl(this.ifcschema, Validators.required),
      "ifcFile": new FormControl(null, Validators.required)
    })

    this.fileName = null;
  }

  initProjectsData() {

    this.sharedProjects = [];
    this.publicProjects = [];
    this.ownedProjects = [];
    let selectedPidBeforeInit = null;
    if (this.selectedProject) {
      selectedPidBeforeInit = this.selectedProject['id'];
    }

    this.selectedProject = null;


    for (let i = 0; i < this.dataService.user.projects.length; i++) {
      let project = this.dataService.user.projects[i];
      if (project.roles[this.dataService.user.id] == 'owner') {
        this.ownedProjects.push(project);
      }
      else {
        if (project.privacy == 'shared') {
          this.sharedProjects.push(project)
        }

        if (project.privacy == 'public') {
          this.publicProjects.push(project)
        }

      }

      if (selectedPidBeforeInit == project.id) {
        this.selectedProject = project;

      }
    }



  }



  selectProject(project) {

    if (this.selectedProject == project) {
      this.selectedProject = null;
    } else {
      if (project.daeFileName == null || project.ifcDateReady) {
        return;
      }
      this.createNewProjectMode = false;
      this.selectedProject = project;
    }


  }

  close() {
    this.appActionsService.openCloseProjectsFolder.next('close');
  }

  createNewProjectClicked() {

    this.createNewProjectMode = true;
    if (this.creatingProjectInProcess == null) {
      this.initCreateProjectForm()
    }

    this.selectedProject = null;
  }

  closeCreateNewProjectClicked() {
    this.createNewProjectMode = false;
    this.selectedProject = null;
  }



  onFileSelected() {
    let file = this.uploadIfcFile.nativeElement.files[0];
    if (this.uploadIfcFile.nativeElement.files.length > 0) {
      this.fileName = file.name;
    }

    else {
      this.fileName = null;
    }

    this.createProjectError = null;


    this.readFile(file)
  }

  readFile(file) {
    var fileReader = new FileReader();
    fileReader.onload = (event: any) => {
      var text = event.target.result.replace(/\s/g, '');;


      if (text.indexOf("FILE_SCHEMA(('IFC2X3'))") != -1) {
        console.log('Ifc versioin detected: ' + 'IFC2X3')
      }

      if (text.indexOf("FILE_SCHEMA(('IFC4'))") != -1) {
        console.log('Ifc versioin detected: ' + 'IFC4')
      }
    };
    fileReader.readAsText(file);
  }



  createProjectClicked() {

    if (this.fileName.slice(this.fileName.length - 4).toLowerCase() !== '.ifc') {
      this.createProjectError = { message: 'file is not an IFC file' }
      this.creatingProjectInProcess = null;
      this.createProjectForm.controls.ifcFile.setValue(null);
  

      this.fileName = null;

      return
    }
    this.createProjectError = null;
    this.creatingProjectInProcess = { message: 'Initiating project' };
    let name = this.newProjectName.nativeElement.value;
    let schema = this.ifcschema


    this.dataService.startProjectCreation(name, schema, this.uploadIfcFile.nativeElement.files[0], this.creatingProjectInProcess).then(
      projectId => {
        this.closeCreateNewProjectClicked()
        this.creatingProjectInProcess = null;
        this.dataService.reloadUserData();
        this.dataService.onProjectDetailsUpdated(projectId)
      },
      err => {
        this.createProjectError = { message: 'error occured' }
        this.creatingProjectInProcess = null;
      }
    )

  }

  loadingProjectClicked(project) {
    console.log(project)

  }


  forbiddenProjectNames(control: FormControl): { [s: string]: boolean } {
    for (let project of this.ownedProjects) {
     
      if (project.name == control.value) {
        return { 'nameAlreadyExist': true } // if forbined
      }


    }

    return null
  }

  onSelectedTabChanged() {

    this.selectedProject = null;
  }



}
