import { Component, OnInit } from '@angular/core';
import { AppActionsService } from '../../services/app-actions.service';
import { ToolboxEvent } from '../../../models/toolbox-event';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-gallery-mode-tool',
  templateUrl: './gallery-mode-tool.component.html',
  styleUrls: ['./gallery-mode-tool.component.scss']
})
export class GalleryModeToolComponent implements OnInit {

  galleryOpen = false;

  constructor(private appActionsService:AppActionsService, private dataService:DataService) { }

  ngOnInit() {
  }

  takePhotoClicked() {
    this.appActionsService.toolboxEvents.next( new ToolboxEvent('gallery','updates',this.constructor.name,{takePhoto:true}))
  }

  galleryButtonClicked() {
    this.galleryOpen = !this.galleryOpen;
  }
}
