import { Injectable, ɵConsole } from '@angular/core';
import { Note } from '../../models/note.model';
import { NoteComment } from '../../models/note-comment.model';
import { User } from '../../models/user.model';
import { UUID } from 'angular2-uuid';
import { AppActionsService } from './app-actions.service';
import { TreeNode } from '../../models/treenode.model';

import * as firebase from 'firebase';
import { ReplaySubject, Subject } from 'rxjs';
import { ViewButton } from 'src/models/viewButton.model';
import { reject } from 'q';
import { ZipService } from './zip.service';
import { resolve } from 'url';
import { retryWhen } from 'rxjs/operators';
import { ViewConfig } from 'src/models/viewConfig.model';
import { AppConfig } from '../../models/appConfig.model';
import { HttpClient } from '@angular/common/http';
import { config } from 'process';
import { Zone } from 'src/models/zone.model';
import { Layer } from 'src/models/layer.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  materials = [];

  textures = null

  objectsData = {};


  user = null;
  project = null;
  siteData = {
    longitude: null,
    latitude: null,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    rotate: 0
  };

  project3dObject = null;

  USERS = [

  ]

  notes: Note[] = [

  ];

  views = null;
  viewsToDelete = [];
  loadedView = null;
  photos = {}; //object based data. used for easy querying a photo from the note componenet. ("dataService.photos[photoId]")
  compareImages = [];

  labels = {};
  photospheres = {};;
  materialsConfigs = {};
  ifcData: any = {};
  viewConfig = new ViewConfig();


  layersConfig = null;
  treeConfig = null;
  notesObjectsRelations = []

  photosDataFetched: ReplaySubject<any> = new ReplaySubject(1);


  compareImageAdded: ReplaySubject<any> = new ReplaySubject();
  compareImageChanged: Subject<any> = new Subject();
  compareImageRemoved: ReplaySubject<any> = new ReplaySubject();

  notesDataFetched: ReplaySubject<any> = new ReplaySubject(1);
  notesObjectsRelationsDataFetched: ReplaySubject<any> = new ReplaySubject(1);
  siteDataFetched: Subject<any> = new Subject();
  labelsUpdated: ReplaySubject<any> = new ReplaySubject(1);
  photospheresUpdated: ReplaySubject<any> = new ReplaySubject(1);
  materialsConfigsUpdated: ReplaySubject<any> = new ReplaySubject(1);


  ifcDataFetched: ReplaySubject<any> = new ReplaySubject(1);

  doorsAndWindowsIds = [];
  loggedIn = false;


  objectsVisibility = {};

  firebaseAccessHost = new AppConfig().firebaseAccessConfig.host;

  firstViewLoad = true;
  projectsThumbnails = {};

  constructor(private appActionsService: AppActionsService, private zipService: ZipService, private http: HttpClient) {

    console.log('dataService constructor')
    // Initialize Firebase


    var config = new AppConfig().firebaseConfig;

    firebase.initializeApp(config);


    this.appActionsService.openProject.subscribe(project => {
      console.log('loading project id:' + project.id)
      this.appActionsService.projectDataReady.next(false);
      this.project = project;

      this.updateProjectVisit();

      this.initProjectData().then((
        done => {
          this.initTexturesData();
          ///finish all initing? so tell everyone.
          console.log('sub')
          this.appActionsService.projectDataReady.next(true);
        }
      ));

    })









  }

  claimProjectOwner(pid) {
    return new Promise((resolve, reject) => {

      this.http.get(this.firebaseAccessHost + '/askProjectOwner' + '?pid=' + pid + '&uid=' + this.user.uid, { responseType: 'json' }).subscribe((data: any) => {



        if (data.success) {
          this.getToken().then(
            ok => {
              console.log('owner for ' + pid + ' claimed')
              resolve('ok')
            }
          );

        } else {
          console.log('nope')
          reject('not owner')
        }

      })
    })

  }

  claimProjectAccess(pid) {

    return new Promise((resolve, reject) => {

      this.http.get(this.firebaseAccessHost + '/askProjectAccess' + '?pid=' + pid + '&uid=' + this.user.uid, { responseType: 'json' }).subscribe((data: any) => {


        if (data.success) {
          this.getToken().then(
            token => {
              console.log('access for ' + pid + ' claimed')

              resolve('ok')
            }
          );

        } else {
          console.log('nope')
          reject('no access')
        }

      })
    })
  }

  async initProjectData() {

    try {
      await this.claimProjectAccess(this.project.id);
      await firebase.auth().currentUser.getIdTokenResult(true).then(
        res => {
          console.log(res)
        }
      )

    } catch (err) {
      return err;
    }

    this.logUserAction('project_init')
    //reseting parameters:
    this.siteData = {
      longitude: null,
      latitude: null,
      offsetX: 0,
      offsetY: 0,
      offsetZ: 0,
      rotate: 0
    };
    this.viewConfig = new ViewConfig();
    this.ifcData = {};
    this.textures = {};
    this.loadedView = null;
    this.objectsData = {}
    this.notes = [];
    this.views = {};
    this.photos = {};
    this.compareImages = [];
    this.labels = {}
    this.photospheres = {};
    this.materialsConfigs = {}
    this.appActionsService.viewLoaded.next(null)
    this.appActionsService.openNote.next(null)
    this.doorsAndWindowsIds = [];
    this.firstViewLoad = true;
    await this.loadIfcData();


    this.layersConfig = null;
    this.treeConfig = null;
    this.notesObjectsRelations = []

    //reseting the relpaySubjects
    this.appActionsService.loadViewConfig.next(null);

    this.photosDataFetched = new ReplaySubject(1);
    this.appActionsService.objectDataAction = new ReplaySubject(1)
    this.appActionsService.toolboxEvents = new ReplaySubject(1)
    this.appActionsService.materialsGenerated = new ReplaySubject(1)
    this.appActionsService.materialsUpdated = new ReplaySubject(1)
    this.appActionsService.startRotating = new ReplaySubject(1)

    this.compareImageAdded = new ReplaySubject();
    this.compareImageRemoved = new ReplaySubject();
    this.notesDataFetched = new ReplaySubject(1);
    this.labelsUpdated = new ReplaySubject(1);
    this.photospheresUpdated = new ReplaySubject(1);
    this.materialsConfigsUpdated = new ReplaySubject(1);

    this.ifcDataFetched = new ReplaySubject(1);
    this.appActionsService.project3dObjectGenerated = new ReplaySubject(1);
    this.notesObjectsRelationsDataFetched = new ReplaySubject(1);






    let addCompletedImage = (image) => {
      return firebase.storage().ref('projects').child(this.project.id).child('compareTool').child(image.id + '.' + image.type).getDownloadURL().then(url => {


        image['url'] = url;

        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {

          image['imageData'] = xhr.response;


        };
        xhr.open('GET', url);
        xhr.send();

        console.log('adding completedImagge:' + image.name)


        let userOpacity, userVisible;
        if (this.viewConfig) {
          if (this.viewConfig.pdfCompareMode) {
            if (this.viewConfig.pdfCompareMode[image.id]) {
              userOpacity = this.viewConfig.pdfCompareMode[image.id].opacity;
              userVisible = this.viewConfig.pdfCompareMode[image.id].visible;
            }
          }

        }


        image['viewConfig'] = {
          opacity: userOpacity ? userOpacity : 1,
          visible: (userVisible != null) ? userVisible : false,
          open: false
        }

        if (image.lockAspectRatio == null) {
          image['lockAspectRatio'] = true;

        }

        if (image.height == null) {
          image.height = 100;
        }

        if (image.width == null) {
          image.width = (image.height * image.aspectRatio).toFixed(0);
        }





        this.compareImages.push(image);
        this.compareImageAdded.next(image);





      }).catch((error) => {
        // Handle any errors
        console.warn(error)
      })
    }

    firebase.database().ref('projects/' + this.project.id + '/data/compareImages').on('child_changed', snapshot => {
      console.log("compareImages, on('child_changed)'", snapshot.val(), snapshot)
      let image = snapshot.val();
      image['id'] = snapshot.key;

      let imageExist = null;

      for (let existingImage of this.compareImages) { //check if image exist already
        if (existingImage.id == image.id) {
          imageExist = existingImage; //assign the exisiting image
        }
      }

      if (imageExist) {
        //copy the fetched image properties to our image, without deleting extra image we inserted on client!

        for (let property in image) {

          imageExist[property] = image[property];

        }
        this.compareImageChanged.next(imageExist);

      } else { //item doesnt exist yet.. so we need to add
        if (image.uploadCompleted) {
          addCompletedImage(image);
        }
      }
    })

    firebase.database().ref('projects/' + this.project.id + '/data/compareImages').on('child_removed', snapshot => {
      console.log("compareImages, on('child_removed)'", snapshot.val())
      for (let i = 0; i < this.compareImages.length; i++) {
        if (this.compareImages[i].id == snapshot.key) {
          this.compareImages.splice(i, 1);
          this.compareImageRemoved.next(snapshot.key)
          i = this.compareImages.length;
        }
      }
    })

    firebase.database().ref('projects/' + this.project.id + '/data/compareImages').on('child_added', snapshot => {
      console.log("compareImages, on('child_added)'", snapshot.val())

      let image = snapshot.val();
      image['id'] = snapshot.key;

      if (image['uploadCompleted']) {


        addCompletedImage(image);
      }

    })

    firebase.database().ref('projects/' + this.project.id + '/data/labels').on('child_changed', snapshot => {
      let updatedLabel = snapshot.val();
      for (let property in updatedLabel) {
        this.labels[snapshot.key][property] = updatedLabel[property]
      }
      console.log('boom')
      this.labelsUpdated.next();
    })

    firebase.database().ref('projects/' + this.project.id + '/data/labels').on('child_removed', snapshot => {
      delete this.labels[snapshot.key];
      this.labelsUpdated.next();

    })

    firebase.database().ref('projects/' + this.project.id + '/data/labels').on('child_added', snapshot => {
      this.labels[snapshot.key] = snapshot.val();
      this.labelsUpdated.next();


    })

    firebase.database().ref('projects/' + this.project.id + '/data/photospheres').on('child_changed', snapshot => {
      let updatedPhotosphere = snapshot.val();
      for (let property in updatedPhotosphere) {
        this.photospheres[snapshot.key][property] = updatedPhotosphere[property]
      }

      this.photospheresUpdated.next();
    })

    firebase.database().ref('projects/' + this.project.id + '/data/photospheres').on('child_removed', snapshot => {
      delete this.photospheres[snapshot.key];
      this.photospheresUpdated.next();

    })

    firebase.database().ref('projects/' + this.project.id + '/data/photospheres').on('child_added', snapshot => {
      this.photospheres[snapshot.key] = snapshot.val();
      this.photospheresUpdated.next();


    })


    firebase.database().ref('projects/' + this.project.id + '/data/materialsConfigs').on('child_changed', snapshot => {
      this.materialsConfigs[snapshot.key] = snapshot.val();

      this.materialsConfigsUpdated.next({ type: 'child_changed', key: snapshot.key });
    })

    firebase.database().ref('projects/' + this.project.id + '/data/materialsConfigs').on('child_removed', snapshot => {
      delete this.materialsConfigs[snapshot.key];
      this.materialsConfigsUpdated.next({ type: 'child_removed', key: snapshot.key });
    })

    firebase.database().ref('projects/' + this.project.id + '/data/materialsConfigs').on('child_added', snapshot => {
      this.materialsConfigs[snapshot.key] = snapshot.val();
      this.materialsConfigsUpdated.next({ type: 'child_added', key: snapshot.key });

    })



    firebase.database().ref('projects/' + this.project.id + '/data/views').once('value').then(snap => {

      if (snap.val()) {
        this.views = snap.val();
      }

      this.loadDefaultView();
      this.appActionsService.viewsDataLoaded.next();


    })





    firebase.database().ref('projects/' + this.project.id + '/data/photos').on('value', snapshot => {
      console.log('just fetched photos')

      let fetchedPhotos = snapshot.val();
      let photos = [];
      for (let key in fetchedPhotos) {
        fetchedPhotos[key]['id'] = key;
        photos.push(fetchedPhotos[key]);
      }


      this.photos = fetchedPhotos ? fetchedPhotos : {};
      this.photosDataFetched.next(photos);
    })

    firebase.database().ref('projects/' + this.project.id + '/data/notes').on('value', snapshot => {
      console.log('just fetched notes')
      let fetchedNotes = snapshot.val();
      let notes = [];
      for (let key in fetchedNotes) {
        fetchedNotes[key]['id'] = key;
        notes.push(fetchedNotes[key]);
      }

      this.notes = notes;
      this.notesDataFetched.next(notes);
    })



    firebase.database().ref('projects/' + this.project.id + '/data/notesObjectsRelations').on('value', snapshot => {
      console.log('just fetched notes-objects-rels')
      let fetchedRels = snapshot.val();
      let notesObjectsRelations = [];

      for (let key in fetchedRels) {
        fetchedRels[key]['id'] = key;

        notesObjectsRelations.push(fetchedRels[key]);
      }


      this.notesObjectsRelations = notesObjectsRelations;
      this.notesObjectsRelationsDataFetched.next(notesObjectsRelations);
    })

    firebase.database().ref('projects/' + this.project.id + '/data/siteData').on('value', snapshot => {
      console.log('just fetched siteData')
      let newSiteData = snapshot.val();

      if (newSiteData != null) {



        if ((newSiteData.longitude != null) && (newSiteData.latitude != null)) {
          this.siteData = newSiteData;

        } else {

          this.siteData.offsetX = newSiteData.offsetX;
          this.siteData.offsetY = newSiteData.offsetY;
          this.siteData.offsetZ = newSiteData.offsetZ;
          this.siteData.rotate = newSiteData.rotate;
        }
      }

      this.siteDataFetched.next();
    })





  }

  getAllNotes(): Note[] {

    return this.notes;

  }

  getPinnedNotes(): Note[] {
    let pinnedNotes: Note[] = [];

    for (let i = 0; i < this.notes.length; i++) {
      if (this.notes[i].position) {
        pinnedNotes.push(this.notes[i])
      }
    }

    return pinnedNotes;
  }

  createNote(title, description, type, options?) {

    return new Promise<Note>((resolve, reject) => {
      let newNote = new Note(title, description, this.user.id, type, options)
      console.log(newNote)
      firebase.database().ref('projects/' + this.project.id + '/data/notes').push(newNote).then(snap => {

        snap.once('value').then(snapshot => {
          let fetchedNote = snapshot.val();
          fetchedNote['id'] = snap.key;

          resolve(fetchedNote);

        })




      })
    })

  }

  deleteNote(noteId) {

    return firebase.database().ref('projects/' + this.project.id + '/data/notes/' + noteId).remove();

    //old version.. need to implement the part of the deleting object-note relation inside the new version up ^^^

    // return new Promise<any>((resolve, reject) => {
    //   for (let i = 0; i < this.notes.length; i++) {

    //     if (this.notes[i].id == noteId) {
    //       this.notes.splice(i, 1)

    //       let objects = this.getRelatedObjectsOfNote(noteId);
    //       objects.forEach(object => {
    //         console.log(object)
    //         this.removeRelatedObjectToNote(object.noteId, object.objectGuid)
    //       })

    //       this.appActionsService.notesDataChange.next();
    //       resolve('note ' + noteId + ' was deleted');



    //     }
    //   }

    //   reject('no note found with id ' + noteId);
    // })

  }



  // getCommentsOfNote(noteId: string) {  //deleted because now the comments are inside the note.
  //   return new Promise<any[]>((resolve, reject) => {

  //     firebase.database().ref('projects/' + this.project.id + '/data/noteComments').orderByChild("noteId").equalTo(noteId).once("value", (snapshot) => {
  //       let comments =[];
  //       let fetchComments = snapshot.val();

  //       for (let key in fetchComments) {
  //         fetchComments[key]['id'] = key;
  //         comments.push(fetchComments[key]);
  //       }
  //       console.log(comments)
  //       resolve(comments);
  //     });
  //   })


  // }

  addNewNoteComment(text: string, noteId: string) {
    return firebase.database().ref('projects/' + this.project.id + '/data/notes/' + noteId + '/comments').push(new NoteComment(text, this.user.id, noteId));

  }

  deleteComment(noteId: string, commentId: string) {
    return firebase.database().ref('projects/' + this.project.id + '/data/notes/' + noteId + '/comments/' + commentId).remove();

  }

  relatePhotoToNote(noteId, photoId) {
    return firebase.database().ref('projects/' + this.project.id + '/data/notes/' + noteId + '/relatedPhotos').child(photoId).set({ relatedBy: this.user.id }).catch(err => { console.warn(err) });
  }

  removePhotoFromNote(noteId, photoId) {
    return firebase.database().ref('projects/' + this.project.id + '/data/notes/' + noteId + '/relatedPhotos/' + photoId).remove();

  }


  getNoteById(noteId) {
    let note = null;
    for (let i = 0; i < this.notes.length; i++) {
      if (this.notes[i].id == noteId) {
        note = this.notes[i];
      }
    }

    return note;
  }




  getRelatedNoteOfObject(objectGuid) {
    let relatedNotesIds = [];
    let relatedNotes = [];

    for (let i = 0; i < this.notesObjectsRelations.length; i++) {
      if (this.notesObjectsRelations[i].objectGuid == objectGuid) {
        relatedNotesIds.push(this.notesObjectsRelations[i].noteId);
      }
    }

    relatedNotesIds.forEach((noteId) => {
      for (let i = 0; i < this.notes.length; i++) {
        if (this.notes[i].id == noteId) {
          relatedNotes.push(this.notes[i])
        }
      }
    })

    return relatedNotes;
  }

  getRelatedObjectsOfNote(noteId: string): { noteId: string, objectGuid: string }[] {

    let relatedObjects = [];

    for (let i = 0; i < this.notesObjectsRelations.length; i++) {
      if (this.notesObjectsRelations[i].noteId == noteId) {
        relatedObjects.push(this.notesObjectsRelations[i]);
      }
    }

    return relatedObjects;
  }

  removeRelatedObjectToNote(relId) {
    console.log('a')
    return new Promise<any>((resolve, reject) => {

      firebase.database().ref('projects/' + this.project.id + '/data/notesObjectsRelations/' + relId).remove().then(resolved => {


        resolve('relation was deleted')
      },
        rejected => {

          reject(rejected)
        })

    })

  }



  addRelatedObjectToNote(noteId: string, objectGuid: string): Promise<any> {


    return new Promise<any>((resolve, reject) => {





      let o = this.getObjectIdOfGuid(objectGuid);
      if (o) {

        for (let i = 0; i < this.notesObjectsRelations.length; i++) {
          if ((this.notesObjectsRelations[i].noteId == noteId) && (this.notesObjectsRelations[i].objectGuid == objectGuid)) {
            console.log('rejecting')
            reject({ code: '1', message: 'Object already related to this note' })
            return;
          }
        }


        firebase.database().ref('projects/' + this.project.id + '/data/notesObjectsRelations').push({ noteId: noteId, objectGuid: objectGuid, createdBy: this.user.id }).then(() => {
          console.log('resolve')
          resolve('Object added')
          return;
        }).catch(err => {
          console.log('rejecting')
          reject({ code: '2', message: 'Permission error' })
          return;
        })


      } else {

        reject({ code: '0', message: 'GUID does not exist' })
        return;
      }

      // })




    })
  }


  //implementing firebase:

  signup(userDetails) {

    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(userDetails.email, userDetails.password).then((snap) => {

        return firebase.database().ref('users').child(snap.user.uid).set({ firstName: userDetails.firstName, lastName: userDetails.lastName, email: userDetails.email, avatarUrl: "", createdOn: new Date().toUTCString() });
      }).then(() => {
        resolve('user added');
      })
        .catch(function (error) {
          console.log('error here')
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;

          reject(error);
        });
    })

  }

  logout() {
    return firebase.auth().signOut();
  }

  getToken() {
    return new Promise((resolve, reject) => {
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        // Send token to your backend via HTTPS
        // ...
        resolve(idToken)

      }).catch(function (error) {
        // Handle error
        reject()
      });
    })

  }

  login(email, password) {


    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then((res) => {
        console.log('a')
        this.user = res.user;

        console.log('%c Logged in to firebase! ', 'background: green; color: white', res.user.email);
        firebase.database().ref('users').child(res.user.uid).update({ lastLogin: new Date().toUTCString() })

        this.getToken().then(
          token => {
            resolve('loggedIn')
          },
          bad => {
            reject(bad)
          }
        );







      }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;

        console.log('%c Login error ', 'background: red; color: white', errorCode + ': ' + errorMessage)
        reject(error)
        // ...
      });

    })
  }

  checkIfUserLoggedAlready() {

    return new Promise<any>((resolve, reject) => {
      // Create a callback which logs the current auth state
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.user = user;

          firebase.database().ref('users').child(user.uid).update({ lastLogin: new Date().toUTCString() }).catch(err => {
            //do nothing if error..
          })
          this.getToken().then(
            token => {

              resolve(user)

            },
            bad => {
              reject(bad)
            }
          );
          // ...
        } else {
          // User is signed out.
          // ...
          console.log('signed out')
          reject()
        }
      });
    })

  }

  getUserData() {



    return new Promise<any>((resolve, reject) => {
      let userData = null;
      let publicProjects = [];
      firebase.database().ref('users/' + this.user.uid).once('value').then((snapshot) => {

        userData = snapshot.val();
        userData['id'] = this.user.uid;
        userData['uid'] = this.user.uid;

        //public projects:
        firebase.database().ref('publicProjectsIds').once('value').then(snap => {

          for (let pid in snap.val()) {
            if (userData.ownedProjects) { //filtering out the owner's projects to be pushed to public projects
              if (!userData.ownedProjects[pid]) {
                publicProjects.push(pid);
              }
            } else {
              publicProjects.push(pid);
            }


          }

        }).then((() => {
          userData['projects'] = [];


          let projectDataFecthingPromises = [];
          let pidToFetch = [];
          let ownedProjects = [];
          let sharedProjects = publicProjects;

          for (let key in userData.ownedProjects) {
            ownedProjects.push(key);
          }

          for (let key in userData.sharedProjects) {
            sharedProjects.push(key);
          }

          if (ownedProjects) {
            if (ownedProjects.length > 0) {
              pidToFetch = pidToFetch.concat(ownedProjects);
            }
          }

          if (sharedProjects) {
            if (sharedProjects.length > 0) {

              pidToFetch = pidToFetch.concat(sharedProjects)
            }
          }





          if (pidToFetch.length == 0) {
            console.log('No projects for user');
            resolve(userData)
          }


          pidToFetch.forEach(pid => {

            projectDataFecthingPromises.push(firebase.database().ref("projects/" + pid + '/details').once('value').then(snapshot => {

              let projectToAdd = snapshot.val();

              projectToAdd['id'] = pid;

              userData.projects.push(projectToAdd) //keep in mind, now its actually project.details that are stored there..

            }).catch(err => {

              console.warn(err)
            }))
          });



          Promise.all(projectDataFecthingPromises.map(p => p.catch((err) => err)))
            .then(data => {
              console.log('all project fetching promises done');

              resolve(userData);

            });




        }))


      }).catch(err => {
        reject(err);
      })
    }).then(
      resolved => {
        this.user = resolved;
        console.log('loaded user data', this.user); // user data saved now, we can brows projects / user settings
        this.getProjectsThumbnails();
        this.loggedIn = true;
        this.appActionsService.loggedIn.next(true);
        this.getAllUsersDetails()
      })

  }

  reloadUserData() {
    return this.getUserData().then(
      resolved => {

        this.appActionsService.projectsDataChanged.next();
      },
      rejected => {
        console.warn(rejected)
      }
    )
  }



  async getProjectModelUrl(daeFileName) {

    try {
      await this.claimProjectAccess(this.project.id); //just incase project was no claimed for some reason. (beacause actually threejs is born in the same time due to definition in app component, and its triggering this function, which can be before claim was generated init project..)
      return firebase.storage().ref('projects').child(this.project.id).child('generatedModels').child(daeFileName).getDownloadURL();
    } catch (err) {
      return err;
    }





  }

  getProjectIFCUrl(daeFileName) {

    return firebase.storage().ref('projects').child(this.project.id).child('generatedModels').child(daeFileName.slice(0, daeFileName.length - 7) + 'ifc.zip').getDownloadURL();



  }

  fetchUnlistedProject(pid) {
    return new Promise((resolve, reject) => {

      if (this.user.projects.filter(p => { p.id == pid }).length == 0) {
        console.log('pid=', pid)
        firebase.database().ref("projects/" + pid + '/details').once('value').then(snapshot => {

          let projectToAdd = snapshot.val();
          console.log(projectToAdd)
          projectToAdd['id'] = pid;

          this.user.projects.push(projectToAdd)
          resolve(projectToAdd);

        }).catch(err => reject(err))
      }

      else {
        reject();
      }

    })


  }




  uploadIfcFileToFirebase(file, projectId, name) {


    return new Promise<any>((resolve, reject) => {
      this.claimProjectOwner(projectId).then(
        ok => {
          this.zipService.zip(file).then(
            blob => {
              console.log(file, name)
              firebase.storage().ref('projects').child(projectId).child('generatedModels').child(name + '_ifc.zip').put(blob).on(
                'state_changed', function (snapshot) {
                  // Observe state change events such as progress, pause, and resume
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  console.log('Upload is ' + progress.toFixed(1) + '% done');
                }, error => {
                  console.warn(error)
                  reject(error);
                  return;
                }, () => {
                  console.log('upload to firebase completed')
                  resolve();
                  return;
                }

              )
            },
            rejected => {
              reject(rejected)
            }
          )
        },
        bad => {
          reject('not owner')
        }
      )



    })

  }

  uploadImageToFirebase(file, name, aspectRatio) {
    return new Promise<any>((resolve, reject) => {
      let type = null;
      switch (file.type) {
        // case 'application/pdf':
        //   type = 'pdf'
        //   break;
        case 'image/png':
          type = 'png'
          break;
        case 'image/jpeg':
          type = 'jpeg'
          break;
        case 'image/gif':
          type = 'gif'
          break;

      }

      if (type == null) {
        reject('not supported file type')
        return;
      }

      firebase.database().ref('projects/' + this.project.id + '/data/compareImages').push({ name: name, createdBy: this.user.id, type: type, aspectRatio: aspectRatio, uploadCompleted: false }).then(snap => {
        firebase.storage().ref('projects').child(this.project.id).child('compareTool').child(snap.key + '.' + type).put(file).on(
          'state_changed', function (snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress.toFixed(1) + '% done');
          }, error => {
            console.warn(error)
            reject(error);
            return;
          }, () => {
            console.log('upload to firebase completed')
            firebase.database().ref('projects/' + this.project.id + '/data/compareImages').child(snap.key).update({ uploadCompleted: true }).then(
              resolved => {
                resolve();
                return;
              },
              rejected => {
                reject('error finalizing image data')
              })

          }

        )
      }).catch(err => { reject(err) })

    })
  }

  updateImageDataInFirebase(image) {
    let newImage = JSON.parse(JSON.stringify(image));
    delete newImage.viewConfig;
    delete newImage.id;
    delete newImage.imageData;
    delete newImage.modelReady;

    return firebase.database().ref('projects/' + this.project.id + '/data/compareImages/' + image.id).set(newImage).catch(
      err => {
        console.warn(err)
      }
    )
  }

  removeCompareImage(image) {
    return new Promise<any>((resolve, reject) => {

      firebase.database().ref('projects/' + this.project.id + '/data/compareImages/' + image.id).remove().then(
        ok => {
          firebase.storage().ref('projects').child(this.project.id).child('compareImages').child(image.id).delete().catch(err => { console.warn(err) })
          resolve('image deleted')

        },
        rejected => {
          reject('error deleting image')
        });
    }).catch(err => { console.warn(err) })
  }



  getAllUsersDetails() {
    firebase.database().ref("users").once('value').then(snapshot => {

      let usersList = snapshot.val();
      this.USERS = [];
      for (let uid in usersList) {
        this.USERS.push(new User(uid, usersList[uid].firstName, usersList[uid].lastName, usersList[uid].email, usersList[uid].avatarUrl))
      }


    }).catch(err => {

      console.warn(err.message)
    })
  }






  getUserById(id) {


    return new Promise<User>((resolve, reject) => {
      //offline getUser..  works nice and faster with animation and so on... because fetching each user from firebase is quite long.. for example, on comments.. it create reload rendering bad expereince
      //in this case, if user change his name/avatar, the client only see it after he reload the app.. which.. is not that bad thing... 
      for (let user of this.USERS) {
        if (user.id == id) {
          resolve(user);
        }
      }
      //if it doesnt find, it will get  it from firebase 
      firebase.database().ref('users/' + id).once('value').then(snapshot => {

        let user = snapshot.val();
        user['id'] = id;
        resolve(user)
      }).catch(
        err => { console.warn(err); reject(err) }
      )



    })

  }

  reloadProject(project) {

    firebase.database().ref('projects/' + project.id + '/details').once('value').then(snapshot => {
      if (snapshot.val() == null) {
        console.log('project been deleted. no need to reload');
        return;
      }
      for (let i = 0; i < this.user.projects.length; i++) {
        if (this.user.projects[i].id == project.id) {
          this.user.projects[i] = snapshot.val();
          this.user.projects[i]['id'] = project.id; // important as projects has no 'id' on 'details' on firebase.. its just in our app that we add.

        }


      }
      this.appActionsService.projectsDataChanged.next();

    })
  }

  changeProjectName(project, name) {


    firebase.database().ref('projects/' + project.id + '/details').child('name').set(name).then(
      resolved => {

        this.reloadProject(project)
      },
      rejected => {
        this.reloadProject(project)
        console.warn(rejected)
      }
    )
  }

  updateUserData(userDataToUpdate) {

    return firebase.database().ref('users/' + this.user.id).update(userDataToUpdate).then(() => {
      this.user.firstName = userDataToUpdate.firstName;
      this.user.lastName = userDataToUpdate.lastName;
      this.user.avatarUrl = userDataToUpdate.avatarUrl;
      this.user.birthDate = userDataToUpdate.birthDate;
      this.user.address = userDataToUpdate.address;

      this.getAllUsersDetails();
      this.appActionsService.usersDataChanged.next();
    }

    )
  }

  createNewProject(name) { //implemented now inside the collada server..

    let roles = {};
    roles[this.user.id] = 'owner';

    let newProject = {
      details: {

        name: name,

        privacy: 'private',
        roles: roles
      }
    }
    return new Promise<any>((resolve, reject) => {
      firebase.database().ref('projects').push(newProject).then(
        snap => {
          firebase.database().ref('users/' + this.user.id + '/ownedProjects').child(snap.key).set(snap.key).then(
            ok => {
              console.log(snap.key)
              resolve(snap.key)
            },
            bad => {
              reject()
            }
          )
        },
        err => {
          console.warn(err)
        }
      )
    })
  }

  deleteProject(project) {

    return new Promise<any>((resolve, reject) => {
      this.claimProjectOwner(project.id).then(
        isOwner => {

          let deletePromises = [
            firebase.storage().ref('projects').child(project.id).child('generatedModels').child(project.name + '_ifc.zip').delete(),
            firebase.storage().ref('projects').child(project.id).child('generatedModels').child(project.name + '_dae.zip').delete()
          ];

          Promise.all(deletePromises).finally(
            () => {
              firebase.database().ref('projects').child(project.id).remove().then(
                resolved => {
                  firebase.database().ref('users').child(this.user.id).child('ownedProjects').child(project.id).remove().then(
                    resolved => {

                      resolve('deleted')

                      if (this.project) {
                        if (project.id == this.project.id) {

                          window.location.replace('/')
                        } else {
                          this.claimProjectAccess(this.project.id)
                        }
                      }
                      return;

                    },
                    rejected => {
                      reject(rejected)
                      return;
                    }
                  )
                },
                rejected => {
                  reject(rejected)
                  return;
                }

              )
            })


        },
        rejected => {
          reject(rejected)
          return;
        }
      );




    })


  }

  updateProject(pid, updates) {
    return firebase.database().ref('projects/' + pid + '/details').update(updates).then(() => {
      firebase.database().ref('users/')
    });
  }

  shareProjectWithUser(projectId, userId) {
    return firebase.database().ref('projects/' + projectId + '/details/roles').child(userId).set('viewer').then(() => {
      firebase.database().ref('users/' + userId + '/sharedProjects').child(projectId).set(projectId);
    })
  }

  removeUserFromProject(projectId, userId) {
    return firebase.database().ref('projects/' + projectId + '/details/roles').child(userId).remove().then(() => {
      firebase.database().ref('users/' + userId + '/sharedProjects').child(projectId).remove();
      this.askToRemoveClaims(projectId, userId)
    })
  }


  pushToPublicProjectsIds(projectId) {
    return firebase.database().ref('publicProjectsIds/').child(projectId).set(projectId);
  }

  removeFromPublicProjectsIds(projectId) {
    this.askToRemoveClaims(projectId, 'ALL')
    return firebase.database().ref('publicProjectsIds/').child(projectId).remove();
  }

  askToRemoveClaims(projectId, userId) {
    this.getToken().then(token => {
      this.http.get(this.firebaseAccessHost + '/removeProjectAccess' + '?uid=' + userId + '&pid=' + projectId + '&token=' + token, { responseType: 'json' }).subscribe((data: any) => {

      })
    }).catch(err => { console.warn(err) });
  }

  addPhoto(photo) {
    let data = photo.data;
    photo['data'] = null; //because we dont want to save the photo data on the photos .. its too big to fetch it all together... so we seperate

    //remove nulled viewconfig:
    for (let config in photo.viewConfig) {
      if (photo.viewConfig[config] == null) {
        console.log('removed ' + config)
        delete photo.viewConfig[config];
      }
    }
    return firebase.database().ref('projects/' + this.project.id + '/data/photos').push(photo).then(snap => {

      snap.once('value').then(snapshot => {
        let fetchedPhoto = snapshot.val();
        fetchedPhoto['id'] = snap.key;
        this.appActionsService.openPhoto.next(fetchedPhoto);
        firebase.database().ref('projects/' + this.project.id + '/data/photosData').child(snap.key).set(data).then(() => {
          fetchedPhoto['data'] = data;

        })

      })


    })
  }

  refreshPhoto(photoId, photo) {

    let data = photo.data;

    photo['data'] = null; //because we dont want to save the photo data on the photos .. its too big to fetch it all together... so we seperate
    delete photo['title']; //beacuse we want the photo will keep the same name.
    return firebase.database().ref('projects/' + this.project.id + '/data/photos/' + photoId).update(photo).then(() => {
      firebase.database().ref('projects/' + this.project.id + '/data/photosData').child(photoId).set(data).catch(err => { throw (err) })

    })

  }

  getPhotoData(photoId) {
    return new Promise<any>((resolve, reject) => {
      firebase.database().ref('projects/' + this.project.id + '/data/photosData/' + photoId).once('value').then(snap => {
        console.log('fetched dataphoto of ' + photoId)
        resolve(snap.val());
      })
    }).catch(err => (console.warn(err)));

  }

  deletePhoto(photo) {
    return new Promise<any>((resolve, reject) => {

      firebase.database().ref('projects/' + this.project.id + '/data/photosData/' + photo.id).remove().then(() => {
        firebase.database().ref('projects/' + this.project.id + '/data/photos/' + photo.id).remove().then(() => {
          this.notesDataFetched.next(this.notes); // to initiatie update in note componenet , so if a deleted photo was related, it will refersh
          resolve('photo deleted')
        })
      });
    }).catch(err => { console.warn(err) })
  }

  updatePhotoDetails(photoId, updates) {
    return firebase.database().ref('projects/' + this.project.id + '/data/photos/' + photoId).update(updates)

  }

  updateNoteDetails(noteId, updates) {
    return firebase.database().ref('projects/' + this.project.id + '/data/notes/' + noteId).update(updates)

  }


  onProjectDetailsUpdated(projectId) {

    return firebase.database().ref('projects/' + projectId + '/details').on('value', snap => {

      for (let i = 0; i < this.user.projects.length; i++) {
        if (this.user.projects[i].id == projectId) {
          if (snap.val().daeFileName) {
            console.log('dae', snap.val().daeFileName)
            this.user.projects[i].daeFileName = snap.val().daeFileName;

          }

          if (snap.val().ifcDataReady) {
            console.log('ifc', snap.val().ifcDataReady)
            this.user.projects[i].ifcDataReady = snap.val().ifcDataReady;

          }

          if (this.user.projects[i].ifcDataReady && this.user.projects[i].daeFileName) {
            firebase.database().ref('projects/' + projectId + '/details/daeFileName').off();
            console.log('turned off')
          }



        }

      }
    })
  }

  saveSiteData() {
    return firebase.database().ref('projects/' + this.project.id + '/data/siteData').set(this.siteData).catch(
      err => {
        console.warn(err)
      }
    )
  }

  restoreSiteData() {
    return firebase.database().ref('projects/' + this.project.id + '/data/siteData').once('value').then(
      snap => {
        console.log('0')
        let siteData = snap.val();
        if (siteData != null) {
          this.siteData = siteData;
          if ((this.siteData.longitude == null) || (this.siteData.latitude == null)) {
            this.siteData.longitude = this.getIfcSiteLongLat().longitude;
            this.siteData.latitude = this.getIfcSiteLongLat().latitude;

          }

        } else {
          this.siteData.longitude = this.getIfcSiteLongLat().longitude;
          this.siteData.latitude = this.getIfcSiteLongLat().latitude;
          this.siteData.offsetX = 0;
          this.siteData.offsetY = 0;
          this.siteData.offsetZ = 0;
          this.siteData.rotate = 0;
        }






      },
      err => {
        console.warn(err)
      }
    )

  }




  removeButtonFromView(viewId, buttonId) {

    let button = null;
    let index = null;

    for (let i = 0; i < this.views[viewId].menu.length; i++) {
      if (this.views[viewId].menu[i].id == buttonId) {

        this.views[viewId].menu.splice(i, 1)
        return;
      }
      if (this.views[viewId].menu[i].children) {
        for (let j = 0; j < this.views[viewId].menu[i].children.length; j++) {
          if (this.views[viewId].menu[i].children[j].id == buttonId) {
            this.views[viewId].menu[i].children.splice(j, 1)
            if (this.views[viewId].menu[i].children.length == 0) {
              this.views[viewId].menu[i].children = null;
            }
            return;
          }
        }
      }
    }


  }

  createNewMainButton(viewId) {
    if (this.views[viewId]['menu'] == null) {
      this.views[viewId]['menu'] = [];
    }
    this.views[viewId].menu.push(new ViewButton('New Main Button', null, null, null, 'main', { action: 'loadPhoto', value: null }))
  }

  createNewView() {

    let newView = {
      id: UUID.UUID(),
      name: 'Untitled View',
      createdBy: this.user.id,
      style: 'default'
    }


    this.views[newView.id] = newView;

    let userViewCount = 0;
    for (let id in this.views) {
      if (this.views[id]['createdBy'] == this.user.id) {
        userViewCount++;
      }
    }

    console.log(userViewCount)
    if (userViewCount == 1) {
      this.setViewAsDefult(newView.id)
    }

    this.appActionsService.viewsDataUpdated.next();


    return newView;
  }

  removeViewTemp(viewId) {
    this.viewsToDelete.push(viewId)
    delete this.views[viewId];
    this.loadView(null)
    this.appActionsService.viewsDataUpdated.next();

  }

  deleteView(viewId) {
    return new Promise<any>((resolve, reject) => {

      firebase.database().ref('projects/' + this.project.id + '/data/views/' + viewId).remove().then(() => {

        this.deleteFolderContents('projects/' + this.project.id + '/viewGalleries/' + viewId)
        resolve('view deleted')

      });
    }).catch(err => { console.warn(err); reject(err) })

  }

  setViewAsDefult(viewId) {
    return firebase.database().ref('projects/' + this.project.id + '/details/defaultView').set(viewId).then(
      ok => {
        this.project.defaultView = viewId;
      },
      rejected => {
        console.warn('error trying to changing default view')
      }
    )
  }


  loadDefaultView() {
    if (this.project.defaultView) {
      this.loadView(this.project.defaultView)


    }
  }

  loadView(viewId) {
    if (viewId) {
      this.loadedView = this.views[viewId];

      if (this.loadedView == null) {
        return;
      }

      let photoId = this.loadedView.initialPhoto
      if (photoId) {


        firebase.database().ref('projects/' + this.project.id + '/data/photos/' + photoId).once('value').then(
          snap => {
            let photo = snap.val();
            if (photo) {

              photo['id'] = snap.key;

              this.loadPhotoView(photo)


            }

          }
        )

      }




    } else {
      this.loadedView = null;
    }


    this.appActionsService.viewLoaded.next(this.loadedView);

    if (this.firstViewLoad) {
      this.firstViewLoad = false;
      if (this.loadedView.rotate) {
        this.appActionsService.startRotating.next(true);
      }



    }




  }

  unloadView() {
    this.loadedView = null;
  }

  deleteTempDeletedViews() {
    let deletingPromises = [];

    for (let i of this.viewsToDelete) {
      deletingPromises.push(this.deleteView(i))
    }

    return new Promise<any>((resolve, reject) => {

      Promise.all(deletingPromises.map(p => p.catch((err) => err)))
        .then(
          ok => {

            resolve()

          },
          notok => {
            reject()
          }
        );
    })



  }

  updateUserViews() {
    let updates = {};

    for (let viewId in this.views) {
      if (this.views[viewId].createdBy == this.user.id) {
        updates[viewId] = this.views[viewId];
      }
    }
    console.log('saving updates', updates)
    return new Promise<any>((resolve, reject) => {



      firebase.database().ref('projects/' + this.project.id + '/data/views/').update(updates).then( //updating all the changes.
        ok => {




          this.appActionsService.viewsDataUpdated.next();
          resolve('views updated')

        },
        rejected => {
          reject(rejected)

        }
      );



    })


  }







  uploadViewImageToStorage(viewId, storageName, file) {

    return new Promise<any>((resolve, reject) => {
      firebase.storage().ref('projects').child(this.project.id).child('viewGalleries').child(viewId).child(storageName).put(file).on(
        'state_changed', function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress.toFixed(1) + '% done');
        }, error => {
          reject(error)


        }, () => {
          resolve('image upload to firebase completed')

        })
    })



  }




  deleteRemovedViewImagesFromStorage(view) {

    let allFilesOfViewGallery = [];
    firebase.storage().ref('projects').child(this.project.id).child('viewGalleries').child(view.id).listAll().then(
      list => {
        for (let file of list.items) {
          let existInGallery = false;
          if (view.gallery) {
            for (let image of view.gallery) {
              if (image.storageName == file.name) {
                existInGallery = true;
              }
            }
          }


          if (!existInGallery) {
            firebase.storage().ref(file.fullPath).delete().then(
              deleted => { console.log('removed ' + file.name) },
              rejected => console.warn(rejected)
            ).catch(err => console.warn(err))
          }
        }
      },
      rejected => console.warn(rejected)
    )

  }

  getViewImageUrl(image, viewId) {

    return firebase.storage().ref('projects').child(this.project.id).child('viewGalleries').child(viewId).child(image.storageName).getDownloadURL();

  }


  addLabel(guid, title, text, visible) {
    let newLabel = {
      title: title,
      text: text,
      createdBy: this.user.id,
      guid: guid
    }
    return firebase.database().ref('projects/' + this.project.id + '/data/labels').push(newLabel).catch(rejected => {
      console.warn(rejected)
    });
  }

  updateLabel(key, updates) {

    return firebase.database().ref('projects/' + this.project.id + '/data/labels').child(key).update(updates)
  }

  removeLabel(key) {
    return firebase.database().ref('projects/' + this.project.id + '/data/labels').child(key).remove();
  }



  loadPhotoView(photo) {

    if (photo == null) {
      return;
    }

    this.viewConfig = new ViewConfig();

    if (photo.viewConfig) { //copying all viewconfig parameters. we couldnt just copy the viewconfig, as empty objects are getting deleted in firebase...
      for (let config in photo.viewConfig) {

        this.viewConfig[config] = JSON.parse(JSON.stringify(photo.viewConfig[config]));

      }
    }


    this.appActionsService.loadViewConfig.next(this.viewConfig)



  }


  addNewMaterialConfig(config) {

    return firebase.database().ref('projects/' + this.project.id + '/data/materialsConfigs').push(config)
  }

  updateMaterialInConfig(key, updates) {

    return firebase.database().ref('projects/' + this.project.id + '/data/materialsConfigs').child(key).child('materials').update(updates)
  }

  updateMaterialName(key, updates) {

    return firebase.database().ref('projects/' + this.project.id + '/data/materialsConfigs').child(key).child('materials').update(updates)
  }

  removeMaterialConfig(key) {
    return firebase.database().ref('projects/' + this.project.id + '/data/materialsConfigs').child(key).remove();
  }

  updateMaterialConfigName(key, name) {
    return firebase.database().ref('projects/' + this.project.id + '/data/materialsConfigs').child(key).update({ name: name });
  }


  logUserAction(actionName, extra?) {

    if (this.user.level == 'admin') {
      return
    }

    let newAction = {
      name: actionName,
      date: new Date().toUTCString(),
      userId: this.user.id,
      projectId: this.project.id,

    }

    if (extra) {
      newAction['extra'] = extra;
    }



    firebase.database().ref('usersActions').push(newAction).catch(
      err => { console.warn(err) }
    )
  }

  getLabelOfObject(guid) {
    for (let key in this.labels) {
      if (this.labels[key].guid == guid) {
        return this.labels[key]
      }
    }

    return null;
  }


  updateProjectVisit() {

    let visit = {
      user: {
        id: this.user.id,
        name: this.user.firstName + ' ' + this.user.lastName,
        email: this.user.email
      },
      project: {
        id: this.project.id,
        name: this.project.name
      },
      date: new Date().toUTCString()

    }


    firebase.database().ref('visits').push(visit).catch(
      err => { console.warn(err) }
    )
  }

  duplicateView() {

    let newView = JSON.parse(JSON.stringify(this.loadedView));

    newView.id = UUID.UUID();
    newView.name = 'duplicated view';

    this.views[newView.id] = newView;

    this.appActionsService.viewsDataUpdated.next();
  }


  loadIfcData() {
    return new Promise((resolve, reject) => {
      firebase.database().ref('projects').child(this.project.id).child('data').child('ifcData').once('value', snap => {
        this.ifcData = snap.val()
        this.ifcData.guidToId = {};

        for (let id in this.ifcData.nodes) {
          this.ifcData.guidToId[this.ifcData.nodes[id].guid] = id;
        }

        console.log('ifcData:', this.ifcData)
        this.getIfcSiteLongLat()
        if ((this.siteData.longitude == null) || (this.siteData.latitude == null)) {
          let longLat = this.getIfcSiteLongLat();
          console.log('setting ifc coordinatesa')

          this.siteData.longitude = longLat.longitude;
          this.siteData.latitude = longLat.latitude;
        } else {
          console.log('siteData LongLat exist, skipping setting ifc coordinates')
        }
        this.appActionsService.ifcDataFetched.next(this.ifcData)
        resolve(null);
      }).catch(err => { reject(err) })
    })


  }

  async startProjectIfcUpdate(projectId, name, schema, file, progress) {
    try {
      await firebase.database().ref('projects').child(projectId).child('details').update({ ifcDataReady: null })

      for (let project of this.user.projects) {
        if (project.id == projectId) {
          console.log(project)
          this.reloadProject(project) //reload the project so it get loading status and cant be loaded
        }
      }

      progress.message = 'uploading Ifc File';

      await this.uploadIfcFileToFirebase(file, projectId, name)
      progress.message = 'generating project';

      var url_to_ifcConvert = new AppConfig().ifcToColladaConfig.host + '/updateIfcFile?name=' + name + "&projectId=" + projectId;


      let res = await this.http.get(url_to_ifcConvert, { responseType: 'text' }).toPromise();


      if (res != 'error') {

        //convert worked
        console.log('should work')
        return projectId;

      }

      else {
        console.warn(' error creating project', res)
        throw { message: 'error updating ifc' }
      }
    }
    catch (err) { throw err };
  }

  async startProjectCreation(name, schema, file, progress) {
    try {

      const projectId = await this.createNewProject(name);
      progress.message = 'uploading Ifc File';
      await this.uploadIfcFileToFirebase(file, projectId, name)
      progress.message = 'generating project';

      var url_to_ifcConvert = new AppConfig().ifcToColladaConfig.host + '/generateColladaFileForProject?name=' + name + "&projectId=" + projectId;


      let res = await this.http.get(url_to_ifcConvert, { responseType: 'text' }).toPromise();


      if (res != 'error') {

        //convert worked
        console.log('should work')
        return projectId;

      }

      else {
        console.warn(' error creating project', res)
        throw { message: 'error creating project' }
      }
    }
    catch (err) { throw err };
  }

  getIfcSiteLongLat() {


    let site = this.ifcData.tree.children[0]

    return {
      longitude: site.RefLongitude,
      latitude: site.RefLatitude
    };

  }

  getObjectIdOfGuid(guid) {

    return this.ifcData.guidToId[guid]
  }

  getGuidOfObjectId(objectId) {
    return this.ifcData.nodes[objectId].guid
  }

  getLayers() {

    let layers: Layer[] = [];
    for (let i = 0; i < this.ifcData.layers.length; i++) {
      let layer = new Layer(i, this.ifcData.layers[i].name, this.ifcData.layers[i].objects ? this.ifcData.layers[i].objects : [], 1)
      layers.push(layer)
    }

    return layers;
  }




  getZones() {
    let zones: Zone[] = [];

    for (let id in this.ifcData.nodes) {
      let node = this.ifcData.nodes[id];

      if (node.type == 'IfcSpace') {
        zones.push(new Zone(id, node.guid, node.Name, node.LongName));

      }
    }

    return zones;

  }

  getObjectDataByObjectId(objectId) {

    if (this.ifcData.nodes[objectId]) {

      return [
        { fieldName: 'Name', value: this.ifcData.nodes[objectId].Name },
        { fieldName: 'Type', value: this.ifcData.nodes[objectId].type },
        { fieldName: 'GUID', value: this.ifcData.nodes[objectId].guid }
      ]
    } else {
      return [];
    }

  }

  getAllDoorsAndWindows() {

    if (this.doorsAndWindowsIds.length == 0) {

      for (let id in this.ifcData.nodes) {
        let node = this.ifcData.nodes[id];

        if (node.type == 'IfcWindow' || node.type == 'IfcDoor') {
          this.doorsAndWindowsIds.push(node.guid);

        }
      }
    }


    return this.doorsAndWindowsIds;
  }


  deleteFolderContents(path) {
    const ref = firebase.storage().ref(path);
    ref.listAll()
      .then(dir => {
        dir.items.forEach(fileRef => {
          this.deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach(folderRef => {
          this.deleteFolderContents(folderRef.fullPath);
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  deleteFile(pathToFile, fileName) {
    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete()
  }

  getProjectsThumbnails() {

    for (let project of this.user.projects) {

      if (project.defaultView) {

        firebase.database().ref('projects').child(project.id).child('data').child('views').child(project.defaultView).child('initialPhoto').once('value', snap => {

          const initialPhotoId = snap.val();
          if (initialPhotoId) {
            firebase.database().ref('projects').child(project.id).child('data').child('photos').child(initialPhotoId).child('thumbData').once('value', snap => {
              const imgData = snap.val();
              if (imgData) {
                this.projectsThumbnails[project.id] = imgData;
              }

            });
          }
        })
      }
    }

  }

  addNewPhotoSphere(file) {
    console.log(file)
    const projectId = this.project.id

    const extension = file.name.split(".").pop();
    console.log(extension)
    const id = UUID.UUID();
    return new Promise<any>((resolve, reject) => {

      //first lets add to storage
      firebase.storage().ref('projects').child(projectId).child('photospheres').child(id + '.' + extension).put(file).on(
        'state_changed', function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress.toFixed(1) + '% done');
        }, error => {
          console.warn(error)
          reject(error);
          return;
        }, () => {
          console.log('upload to firebase completed')
          // now lets create in realtime database:
          firebase.database().ref('projects/' + this.project.id + '/data/photospheres').child(id).set({
            id: id,
            storageName: id + '.' + extension,
            name: file.name,
            position: {
              x: 0,
              y: 0,
              z: 0
            },
            radius: 10,
            opacity: 1,
            yaw: 0,
            pitch: 0

          }).then(
            ok => {
              resolve('ok');
              return;
            },
            bad => {
              reject();
              return;
            }
          )

        })
    })




  }

  updatePhotosphere(id, updates) {

    return firebase.database().ref('projects/' + this.project.id + '/data/photospheres').child(id).update(updates)
  }

  deletePhotosphere(id) {
    const storageName = this.photospheres[id].storageName;

    return new Promise((resolve, reject) => {
      firebase.database().ref('projects/' + this.project.id + '/data/photospheres').child(id).remove().then(
        removed => {
          firebase.storage().ref('projects').child(this.project.id).child('photospheres').child(storageName).delete().then(
            ok => {
              console.log('photosphere  removed from DB and Storage')
              resolve(null)
            },
            notDeletedFromStorage => {
              console.warn('photosphere  removed from DB but not removed from storage')
            }
          )
        }
      ).catch(err => { console.warn(err) })
    })

  }

  getPhotosphereImageUrl(storageName) {
    return firebase.storage().ref('projects').child(this.project.id).child('photospheres').child(storageName).getDownloadURL();
  }

  async initTexturesData() {
    try {
      let req: any = await this.http.get('https://beta.dev1.bimshow.io/textures').toPromise();
      if (req.result = "success") {
        this.textures = req.content;
      }

    } catch (err) {
      console.warn('error init textures')
    }



  }


}
