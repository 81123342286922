import { Component, OnInit, OnDestroy } from '@angular/core';
import { Layer } from '../../models/layer.model';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-layer-view',
  templateUrl: './layer-view.component.html',
  styleUrls: ['./layer-view.component.scss']
})
export class LayerViewComponent implements OnInit, OnDestroy {

  layers: Layer[] = [];
  loading = true;
  subscriptions: Subscription[] = [];


  constructor( private appActionsService: AppActionsService, private dataService: DataService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.appActionsService.ifcDataFetched.subscribe((ifcData) => {
        if (ifcData) {
        
          this.loadLayers();
         
          this.loading = false;
        }
      })
    )
    this.subscriptions.push(
      this.appActionsService.refreshLayersVisability.subscribe(() => {
        this.refreshLayers();
      })
    )

    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(viewConfig => {
       
        let config = [];

        if (viewConfig) {
          if (viewConfig.layersConfig) {
           config = viewConfig.layersConfig;
  
          }
  
        }
        
        try {
          this.loadLayerConfig(config);
        } catch (err ) {console.warn(err) }
        
      }))



  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  loadLayers() {
    this.layers = this.dataService.getLayers();
   
    
    this.layers.forEach(layer => {
     
      if (this.checkIfLayerContainsZonesOnly(layer)) {
        console.log(layer.name + ' contains only zones, hiding layer')
        layer.visibility = 0;
        this.appActionsService.changeLayerVisibility.next(layer)
      }
      
      for(let oid of layer['objectsOids']) {
        
        if (this.dataService.objectsData[oid] == null) {
          this.dataService.objectsData[oid] = {}
        } 
        this.dataService.objectsData[oid].layerName = layer.name;
      }

     
    })
    
    
    if (this.dataService.viewConfig.layersConfig==null) {
      
      //this.updateLayerConfig();
    }
    



  }

  updateLayerConfig() {
  
    let newLayersConfig = [];

    this.layers.forEach(layer => {
      newLayersConfig.push(
        {
          name: layer.name,
          visibility: layer.visibility
        }
      )
     
     
      
      
    })

    this.dataService.viewConfig.layersConfig = newLayersConfig;

  }


  loadLayerConfig(config) {
   
    this.layers.forEach(layer => {

    

      for(let layerConfig of config) {
        if (layerConfig.name == layer.name) {
          let visibility = layerConfig.visibility;
     
          if (visibility != null) {
            layer.visibility = visibility;
    
          }
        }
      
      }
     
    })
  
    this.updateLayerConfig();
    this.refreshLayers();
  }


  refreshLayers() {
    this.layers.forEach(layer => {
     this.appActionsService.changeLayerVisibility.next(layer)
    })

  }

  checkIfLayerContainsZonesOnly(layer) {

    let containZone = true;
    let oids = layer.objectsOids;

    if (!oids) {
      //if no objects...
      return false;
    }

    for (let i = 0; i < oids.length; i++) {
      if (this.dataService.ifcData.nodes[oids[i]]) {


        containZone = containZone && (this.dataService.ifcData.nodes[oids[i]].type == 'IfcSpace');
      }


      if (!containZone) {
        return false;
      }
    }




    return true;
  }

  visibilityButtonClicked(layer: Layer) {


    if (layer.visibility == 1) {
      layer.visibility = 0;
    } else {
      layer.visibility = 1;
    }

    this.appActionsService.changeLayerVisibility.next(layer)
    this.updateLayerConfig();
  }


}




