import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bimlogo-spinner',
  templateUrl: './bimlogo-spinner.component.html',
  styleUrls: ['./bimlogo-spinner.component.scss']
})
export class BimlogoSpinnerComponent implements OnInit {

  @Input('color') color:string;

  constructor() { }

  ngOnInit() {
   
  }

}
