import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { AppActionsService } from 'src/app/services/app-actions.service';

@Component({
  selector: 'app-view-editor',
  templateUrl: './view-editor.component.html',
  styleUrls: ['./view-editor.component.scss']
})
export class ViewEditorComponent implements OnInit, OnDestroy {

  dropListsIds = [];
  views = null;
  subButtonIsDragged = false;
  lastDragged = null;
  currentLoadedProject = null;
  saveError = null;

  currentView = null;
  selectedButton = null;
  subscriptions: Subscription[] = [];

  constructor(private appActionsService: AppActionsService, private dataService: DataService, private domRenderer: Renderer2) { }

  ngOnInit() {

 

    this.subscriptions.push(
      this.appActionsService.viewsDataLoaded.subscribe(() => {
        this.views = this.dataService.views;


      })
    )
    this.subscriptions.push(
      this.appActionsService.closeButtonEditor.subscribe(() => {

        this.selectedButton = null;
      })
    )

    this.subscriptions.push(
      this.appActionsService.viewLoaded.subscribe((view) => {
        if (view) {
          if (view.createdBy == this.dataService.user.id) {

            this.currentView = this.views[view.id];
            if (this.currentView.style == null) {//because some views didnt have this 
              this.currentView['style'] = 'default' 
            }

          } else {
            this.currentView = null;
          }
        } else {
          this.currentView = null;
        }


      })
    )

    this.subscriptions.push(this.appActionsService.photoDroppedOnView.subscribe(photoId => {


      this.linkPhotoToView(photoId);



    }))

  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }



  selectButton(button) {
    this.selectedButton = button;
  }

  dropMainButtons(event: CdkDragDrop<string[]>) {
    this.dataChanged()
    moveItemInArray(this.currentView.menu, event.previousIndex, event.currentIndex);
  }

  dropSubButtons(event: CdkDragDrop<string[]>) {

    this.dataChanged()
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }


  }



  generateDropListsIds() {
    this.dropListsIds = [];
    if (this.currentView.menu) {
      this.currentView.menu.forEach(b => {
        this.dropListsIds.push('subButtonList_' + b.id)
      })
    }

  }

  viewSelected(event) {

    this.dataService.loadView(this.currentView.id)
    this.generateDropListsIds();

  }


  addButtonClicked() {
    this.dataService.createNewMainButton(this.currentView.id);
    this.generateDropListsIds();
    this.dataChanged()
  }


  createNewViewClicked() {

    this.currentView = this.dataService.createNewView();
    this.dataService.loadView(this.currentView.id)
    this.generateDropListsIds();
    this.dataChanged()

  }



  dragReleaseOnEmptySubMenu(newViewButton) {



    newViewButton['children'] = [this.lastDragged.sub]
    setTimeout(() => {
      this.lastDragged.main.children.splice(this.lastDragged.main.children.indexOf(this.lastDragged.sub), 1);
      if (this.lastDragged.main.children.length == 0) {
        this.lastDragged.main.children = null;
      }
    }, 10);

    this.dataChanged()





  }

  saveChanges() {
    this.saveError = null;
    this.dataService.updateUserViews().then(
      ok => {
        this.appActionsService.changesSavedOnViewEditor = true;
      },
      rejected => {
        this.saveError = 'error saving views. try again later'
      }
    );

    this.dataService.deleteTempDeletedViews().then(
      ok => {
        this.appActionsService.changesSavedOnViewEditor = true;
      },
      rejected => {
        this.saveError = 'error deleting views. try again later'
      }
    );
  }

  dataChanged() {
 
    this.appActionsService.changesSavedOnViewEditor = false;
  }



  subDragStart(subViewButton, viewButton, event) {

    this.lastDragged = {
      main: viewButton,
      sub: subViewButton,
      el: event.source.element.nativeElement
    }

    this.domRenderer.addClass(this.lastDragged.el, 'noTransform')




  }



  close() {
    this.appActionsService.openCloseViewsEditor.next('close')
  }


  deleteViewClicked(viewId) {
    this.dataService.removeViewTemp(viewId)
    this.dataChanged();
    this.currentView = null;
  }


  noEnterPredicte() {
    return false;
  }

  linkPhotoToView(id) {
    this.currentView['initialPhoto'] = id;
    this.appActionsService.checkPhotosLinkedToButton.next();
    this.dataChanged();
  }



}
