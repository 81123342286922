import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ViewButton } from 'src/models/viewButton.model';
import { Subscription } from 'rxjs';
import { AppActionsService } from 'src/app/services/app-actions.service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-button-editor',
  templateUrl: './button-editor.component.html',
  styleUrls: ['./button-editor.component.scss']
})
export class ButtonEditorComponent implements OnInit, OnChanges, OnDestroy {

  @Input('button') button: ViewButton;
  @Input('viewId') viewId;
  @Output() buttonDataChanged: EventEmitter<any> = new EventEmitter<any>();
  note: string;
  subscriptions: Subscription[] = [];

  constructor(private appActionsService: AppActionsService, private dataService: DataService) { }

  ngOnInit() {
    console.log('init')
    this.appActionsService.buttonEditorOpened.next();
    this.subscriptions.push(this.appActionsService.itemDroppedOnButton.subscribe(item => {

      if (item.type == 'photo') {
        this.linkPhotoToButton(item.value);
      }

      if (item.type == 'note') {
        this.linkNoteToButton(item.value);
      }

    
      
    }))
  }

  ngOnDestroy() {
    console.log('destroy')
    
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  ngOnChanges() {
    if (this.button.onClick.action == 'loadNote') {
        this.note = this.dataService.getNoteById(this.button.onClick.value);
    } else {
      this.note = null;
    }

  

  }

  actionChanged() {
    this.button.onClick.value= null;
    this.note = null;
    this.dataChanged();
    console.log(this.button)
    
  }

  noEnterPredicte() {
    return false;
  }

  linkPhotoToButton(photoId) {
    
    this.button.onClick.value = photoId;
    this.dataChanged();
   
  }

  linkNoteToButton(noteId) {
   
    this.button.onClick.value = noteId;
    this.note = this.dataService.getNoteById(noteId);
    this.dataChanged();
    
  }

  linkDownloadUrlToButton(url) {
    this.button.onClick.value = url;
    console.log(url)
    this.dataChanged();
  }

  addChildButton() {
    if (this.button.children == undefined) {
      this.button['children'] = [];
    }

    this.button.children.push( new ViewButton('New child button',null,null,null,'sub',{action:'loadPhoto',value:null}));
    this.dataChanged();
  }

  removeButton() {
    this.dataService.removeButtonFromView(this.viewId,this.button.id)
    this.dataChanged();
    this.close();
  }

  close() {
    this.appActionsService.closeButtonEditor.next();
  }

  trimOnClickValue() {
    console.log( this.button.onClick.value.trim())
    this.button.onClick.value = this.button.onClick.value.trim();
    this.dataChanged();
  }

  dataChanged() {
    
    this.appActionsService.checkPhotosLinkedToButton.next();
    this.buttonDataChanged.emit(null)
  }
}
