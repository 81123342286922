import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
    name: 'customDate'
})

export class CustomDate implements PipeTransform {

    constructor() {

    }
    transform(dateString: string): string {
    let date = new Date(dateString);
    let now = new Date();
    let ONE_HOUR = 60*60*1000;
    let ONE_MINUTE = 60*1000;
    let timeSince =now.getTime() - date.getTime();
    
    

    if ( timeSince < ONE_MINUTE) {

        return 'just now';

    }
    
    if ( timeSince < ONE_HOUR) {

        return ''+(timeSince/ONE_MINUTE).toFixed(0)+' minutes ago';
    }

    if ( timeSince < 48*ONE_HOUR){
        if( now.getDay() == date.getDay()) {
            return 'Today, at '+String(date.getHours()).padStart(2, '0')+':'+String(date.getMinutes()).padStart(2, '0');
        }

        if( (now.getDay() - 1) == date.getDay()) {
            return 'Yesterday, at '+String(date.getHours()).padStart(2, '0')+':'+String(date.getMinutes()).padStart(2, '0');
        }
    }


    return new DatePipe('en-US').transform(date,'medium');
  }
}