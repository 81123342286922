
import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ɵConsole } from '@angular/core';

import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';
import { Note } from '../../models/note.model';
import { ToolboxEvent } from '../../models/toolbox-event';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ZipService } from '../services/zip.service';
import { Tictac } from 'src/models/tictac.model';


import { isArray } from 'util';






declare var SunCalc: any;
console.log(SunCalc);

declare var THREE: any;
declare var Stats: any;
declare var VirtualJoystick: any;

declare var dat: any;




@Component({
  selector: 'app-threejs',
  templateUrl: './threejs.component.html',
  styleUrls: ['./threejs.component.scss']
})
export class ThreejsComponent implements OnInit, OnDestroy {
  @Input('master') masterName: string;

  @ViewChild('rendererContainer', { static: true }) rendererContainer: ElementRef;
  @ViewChild('loadingContainer', { static: true }) loadingContainer: ElementRef;
  @ViewChild('primaryColorDiv', { static: true }) primaryColorDiv: ElementRef;
  @ViewChild('accentColorDiv', { static: true }) accentColorDiv: ElementRef;
  @ViewChild('warnColorDiv', { static: true }) warnColorDiv: ElementRef;
  @ViewChild('okColorDiv', { static: true }) okColorDiv: ElementRef;
  @ViewChild('problemColorDiv', { static: true }) problemColorDiv: ElementRef;
  @ViewChild('noneColorDiv', { static: true }) noneColorDiv: ElementRef;
  @ViewChild('smallMap', { static: true }) smallMap: ElementRef;
  @ViewChild('threeJsStats', { static: true }) threeJsStats: ElementRef;
  @ViewChild('mobileFpsControls', { static: true }) mobileFpsControls: ElementRef;

  @ViewChild('guiContainer', { static: true }) guiContainer: ElementRef;

  @Input() project;
  d: any = document;
  fullscreen = false;

  debugGUI: any = null;

  firstViewLoad = true;

  cameraSound = new Audio('../assets/sounds/camera-shutter-click-03.mp3');



  takingPhoto = false;

  objectsGuidQuery = {
    threejs: {}, mapbox: {}
  }
  labels = {}
  photosphereLabels = {};


  //materials control!:
  materialsList = [];
  //end materials control

  //debugs
  debugWarning = null;
  helper = null;
  testCam = null;
  renderDebuggerOn = false;
  dontRender = false;
  //

  visibilityFilters = {}

  mobileLog = '';

  debuggerMinimized = true;

  subscriptions: Subscription[] = [];
  debug = false;
  zones = [];
  zonesLabels = {};
  zoneLabel = null;
  lastZoneHovered = null;
  zoneHoverTimer = 0;
  hoveredZoneGuid = null;

  //detect Zone

  currentContainedZone = null;
  // zoneCounter = 0;


  //
  clippingPlanes = [];
  clippingPlanesMeshes = [];
  clippingCube = new THREE.Geometry();
  clippingOn = false;
  guidingPlane = null;
  guidingPlane2 = null;
  guidingPlaneZ = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
  clippingPlaneConstantOffset = 0.05;
  clippingModeState = null;




  mousePressedButtons = {
    0: false,
    1: false,
    2: false
  }

  planeDragStartingPoint = null
  //

  edges = [];
  compassAngle = 0;
  firstTimePlaceOnMapMode = false;  //this is false because i wanted to disabled the 1st time animation options
  flightId = 0;
  inFlight = false;
  primaryColor: string;
  warnColor: string;
  accentColor: string;
  okColor: string;
  problemColor: string;
  noneColor: string;
  loadingFinished = false;
  stats;
  mouseOutOfScene = false;
  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderRequired = false;
  checkRenderRequireFrameCount = 30;
  frameCount = 0;

  //SAO
  composer = null;
  SAOOn = false;
  MixedSAOMode = false;
  saoPass = null;
  controlsChangeStarted = false;

  saoParams = {

    saoBias: 0.5,
    saoIntensity: .0003,
    saoScale: 40.5,
    saoKernelRadius: 50,
    saoBlur: true,
    saoBlurRadius: 8,
    saoBlurStdDev: 4,
    saoBlurDepthCutoff: 0.01

  }


  lastCameraPosition = new THREE.Vector3();
  lastCameraTarget = new THREE.Vector3();

  scene = null;
  camera = null;
  cameraDirection = new THREE.Vector3()
  mouse = new THREE.Vector2(-100. - 100);
  controls = null;
  plcControls = null;
  activePhotosphere = null;
  beforePhotosphereCameraProperties = null;
  dragControls = null;

  leftJoystick;
  rightJoystick;
  joysticksOn = false;



  moveRight = 0;
  moveForward = 0;
  moveLeft = 0;
  moveBack = 0;
  speedFactor = 1;

  plcOn = false;

  collidableObjects = []






  loadingManager = null;
  loader = null;
  projectColladaObject = null;
  INTERSECTED = null;
  INTERSECTED_POINT = null;
  raycaster = new THREE.Raycaster();
  raycasterDblClick = new THREE.Raycaster();
  raycasterPhotospheres = new THREE.Raycaster();
  raycasterLabels = new THREE.Raycaster();

  lastSelectedObjectOid = null;
  selectedObject = null;
  selectedMaterial = new THREE.MeshBasicMaterial({ name: 'selectedMaterial', color: 0x00ffff, side: THREE.DoubleSide });
  selectedEdges = null;
  hoveredEdges = null;
  selectedEdgesMaterial = new THREE.LineBasicMaterial({ color: 0x424242, linewidth: 2 });
  hoveredEdgesMaterial = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 2, transparent: true, opacity: 0.35 });

  hoverMat = new THREE.MeshPhongMaterial({ name: 'hoverMat', color: 0xffffff, emissive: 0xffffff, emissiveIntensity: 0.05, side: THREE.DoubleSide, transparent: true, opacity: 1 })

  clippingPlanesHoverMat = new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, opacity: 0.7 })
  nonInteractiveObjects = [];
  selectedMode = null;

  newNoteObject = new THREE.Mesh(
    new THREE.SphereGeometry(0.3, 32, 32),
    new THREE.MeshPhongMaterial({ color: 0xb4b4b4 })
  )
  openedPinnedNoteObject = new THREE.Mesh(
    new THREE.SphereGeometry(0.3, 32, 32),
    new THREE.MeshPhongMaterial({ color: 0x00ffff })
  )
  pinnedNoteScale = null;

  newNoteSize = null;

  pinnedNotes: Note[] = [];
  labelsGroup = new THREE.Group();
  pinnedNotesGroup = new THREE.Group();
  intersectPoint = null;
  measureObject = null;
  measureIntersected = null;
  measurePointGeometry = new THREE.SphereGeometry(0.01, 8, 5);
  measurePointChosenGeometry = new THREE.SphereGeometry(0.011, 8, 5);
  measurePointMaterial = new THREE.MeshPhongMaterial({ color: 0xff0000, });
  measurePointChosenMaterial = new THREE.MeshPhongMaterial({ color: 0x0000FF, });
  magnetOn = true;

  measurePointA = new THREE.Mesh(
    this.measurePointChosenGeometry.clone(),
    this.measurePointChosenMaterial.clone()
  )


  measurePointB = new THREE.Mesh(
    this.measurePointChosenGeometry.clone(),
    this.measurePointChosenMaterial.clone()
  )

  measurePoints = [];

  measureArray = [];

  meter = new THREE.Line(
    new THREE.BufferGeometry(),
    new THREE.LineDashedMaterial({
      color: 0xa4ffa8,
      linewidth: 1,
      scale: 1,
      dashSize: 3,
      gapSize: 1

      // ,depthTest:false
    })

  )


  //ground:
  mapboxOn = false;
  mapboxMode = null;

  flyToSphere = new THREE.Mesh(new THREE.SphereGeometry(1, 8, 8), new THREE.MeshBasicMaterial({ color: 0x000000 }));






  //image layers:
  imageLayers = new THREE.Group()

  //photospheres:
  photospheres = new THREE.Group();

  //download loading info
  downloadingModel = null;


  // //debug spheres
  // debugSphere1 = new THREE.Mesh(
  //   new THREE.SphereGeometry(2, 32, 32),
  //   new THREE.MeshPhongMaterial({ color: 0xff0000 })
  // )

  // debugSphere2 = new THREE.Mesh(
  //   new THREE.SphereGeometry(1, 32, 32),
  //   new THREE.MeshPhongMaterial({ color: 0x0000ff })
  // )


  //

  //materials in colors:
  transparentModeColorGray = new THREE.MeshPhongMaterial({ color: 0xffffff, transparent: true, opacity: 0.8 })
  transparentModeColorGreen = new THREE.MeshPhongMaterial({ color: 0xffffff, transparent: true, opacity: 0.8 })
  transparentModeColorOrange = new THREE.MeshPhongMaterial({ color: 0xffffff, transparent: true, opacity: 0.8 })
  transparentGrayMaterial = new THREE.MeshPhongMaterial({
    name: 'transparentModeMaterial', color: 0xf0f0f0,
    transparent: true,
    opacity: 0.1,

    depthWrite: false
  });

  photosphereGeometry = new THREE.SphereGeometry(1, 16, 16).scale(-1, 1, 1);


  timestamp = performance.now();
  destroyed = false;


  sun = null;
  sunMonth = 0;
  sunHour = 0;

  constructor(private zipService: ZipService, private domRenderer: Renderer2, public appActionsService: AppActionsService, public dataService: DataService, private http: HttpClient, private cdr: ChangeDetectorRef) {




  }

  // to output a message to parent when the threeJS is loaded and rdy
  statusThree = false;
  @Output() eventStatusThree = new EventEmitter<any>();

  ngOnInit() {

    console.log('THREEJS COMPONENT INIT')


    this.stats = new Stats();
    this.stats.showPanel(1); // 0: fps, 1: ms, 2: mb, 3+: custom
    this.threeJsStats.nativeElement.appendChild(this.stats.dom);

    console.log(this.project)
    this.renderer.domElement.id = 'renderer';

    this.setColorsFromTheme();

    Promise.all([
      this.loadProjectModel(),

    ].map(p => p.catch((err) => err)))
      .then(data => {

        console.log('all async loading actions finished.')
        this.setSiteProperties(this.dataService.siteData);
        this.initClippingCube();
        this.placeCameraOnFullProjectView();
        this.generateCollisionObjectsArray();
        this.initAppSubscriptions();

        this.loadingFinished = true;
        this.domRenderer.addClass(this.loadingContainer.nativeElement, 'finished');
        this.appActionsService.project3dObjectGenerated.next();

        this.scanAllMaterials();
        this.appActionsService.materialsGenerated.next();
        this.appActionsService.threeJsIsReady = true;
        this.animate();



        // send message to parent to inform three is loaded
        this.statusThree = true
        this.sendMessage();



      });


    this.initClippingPlanes();
    this.initSceneAndLight();

    this.initCameraAndControls();
    this.initPinnedNotes();








  }


  ngOnDestroy() {




    //this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name))  **caused error, as the view is somehow destroyed and there is still subscription to null that try to do something.. anyway i think this line is from some old logic and not necessery
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
    // this.debugGUI.destroy();

    this.plcControls.dispose();
    this.destroyed = true;

    console.log('THREE JS COMPONENET DESTROIED')

  }



  initAppSubscriptions() {

    this.subscriptions.push(
      this.appActionsService.cookieOk.subscribe(() => {
        setTimeout(() => {
          this.onWindowResize();
        }, 100);

      })
    )
    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(viewConfig => {
        console.log('heys')
        if (!this.firstViewLoad) {
          this.controls.autoRotate = false;
        }
        this.firstViewLoad = false;

        if (viewConfig == null) {
          return;
        }


        this.changeSAOifMixedMode(false)


        this.colorZonesFromConfig(viewConfig.zonesMode)

        if (viewConfig.photospheresConfig) {
          this.updatePhotoSpheres();
        }
        if (viewConfig.clippingMode) {
          this.loadClppingConfig(viewConfig.clippingMode)
        }

        if (viewConfig.cameraParameters != null) {
          if (viewConfig.cameraParameters.position != null && viewConfig.cameraParameters.target != null) {
            // let position = new THREE.Vector3(viewConfig.cameraParameters.position.x, viewConfig.cameraParameters.position.y, viewConfig.cameraParameters.position.z)
            // let target = new THREE.Vector3(viewConfig.cameraParameters.target.x, viewConfig.cameraParameters.target.y, viewConfig.cameraParameters.target.z)

            // this.camera.position.set(position.x, position.y, position.z)
            // this.camera.lookAt(target)
            // this.controls.target = target;
            // this.controls.update();

            // this.compassAngle = this.controls.getAzimuthalAngle() * 180 / Math.PI;;

            // this.changeSAOifMixedMode(true)
            // this.askForRender();

            if ((this.dataService.loadedView ? this.dataService.loadedView.cameraTransitions : false) && (viewConfig.mapboxMode == 'off' || viewConfig.mapboxMode == null)) {
              this.transitionToNewCamera(viewConfig.cameraParameters)

            } else {
              let position = new THREE.Vector3(viewConfig.cameraParameters.position.x, viewConfig.cameraParameters.position.y, viewConfig.cameraParameters.position.z)
              let target = new THREE.Vector3(viewConfig.cameraParameters.target.x, viewConfig.cameraParameters.target.y, viewConfig.cameraParameters.target.z)

              this.camera.position.set(position.x, position.y, position.z)
              this.camera.lookAt(target)
              this.controls.target = target;
              this.controls.update();



              this.changeSAOifMixedMode(true)
              this.askForRender();
            }

            this.compassAngle = this.controls.getAzimuthalAngle() * 180 / Math.PI;;


          }

        }


        //setting mapbox :  
        console.log('hey!!!')

        if (viewConfig.mapboxMode) {

          this.setMapboxMode(viewConfig.mapboxMode)

        } else {

          this.setMapboxMode('off')
        }






      }))

    this.subscriptions.push(
      this.appActionsService.materialsUpdated.subscribe(() => {
        this.askForRender();
      })
    )


    this.subscriptions.push(

      this.appActionsService.toggleVisibilityOfObjects.subscribe(toggle => {


        for (let oid of toggle.oids) {

          this.setVisibilityFilterOut(oid, 'tree', !toggle.state);
          this.updateVisibilityOfObjectFromFilterOut(oid);

        }

        this.askForRender(); //check
        this.appActionsService.rerenderOnMapbox.next();


      })
    )

    this.subscriptions.push(
      this.appActionsService.changeAllZonesVisibility.subscribe(state => {
        this.zones.forEach(zone => {

          zone.visible = state;

        })
        console.log('finished')
        this.askForRender();
      }))



    this.subscriptions.push(this.appActionsService.changeZonesProperties.subscribe(changes => {
      for (let change of changes) {
       
        let zoneMesh = this.objectsGuidQuery.threejs[change.guid]


        if (change['color'] != null) {
          if (this.selectedObject) {
            if (this.selectedObject.name != change.guid) {
              //this.scene.getObjectByName(change.guid).material.color = new THREE.Color(change.color);
              zoneMesh.material.color = new THREE.Color(change.color);
            } else { //here we deal with the case the object is selected.. hence we need to change the previous material...  and deadl with visibility..

              this.selectedObject['lastUsedMaterial'].color = new THREE.Color(change.color);

            }
          } else {

            zoneMesh.material.color = new THREE.Color(change.color);
            //this.scene.getObjectByName(change.guid).material.color = new THREE.Color(change.color);


          }
          this.zonesLabels[change.guid].color = change.color;
     



        }

        if (change['visible'] != null) {
          if ((this.selectedObject ? this.selectedObject.name : null) != change.guid) {
            zoneMesh.visible = change.visible;
          } else {
            zoneMesh.lastVisibleState = change.visible;
            console.log('aasigned lastvisiblestate:', change.visible)
          }


        }

        if (change['opacity'] != null) {
          let zoneIsSelected = false;
          if (this.selectedObject) {
            if (this.selectedObject.name == change.guid) {
              zoneIsSelected = true;
            }

          }

          if (!zoneIsSelected) {
            zoneMesh.material.opacity = change.opacity;

          }

        }

      }

      this.askForRender();
    }))


    this.subscriptions.push(this.appActionsService.changeLayerVisibility.subscribe(layer => {



      for (let oid of layer.objectsOids) {


        this.setVisibilityFilterOut(oid, 'layers', !layer.visibility);
        this.updateVisibilityOfObjectFromFilterOut(oid);



      }
      this.appActionsService.rerenderOnMapbox.next();
      this.askForRender();
    }))

    this.subscriptions.push(this.appActionsService.chosenObjectOid.subscribe(oid => {

      if (this.selectedObject) {
        this.unchooseOnScene()
      }

      if (oid) {
    
        if( this.appActionsService.selectedDataMode!='materials-editor'){
          this.chooseOnScene(oid) 
          this.flyTo(this.scene.getObjectByName(this.dataService.getGuidOfObjectId(oid)), null, null, null, true)
        }
     
      }



    }))





    this.subscriptions.push(this.appActionsService.toolboxEvents.subscribe(event => {

      if (event.tool == null) {


        this.closeSelectedMode();
      }


      if (event.type == 'open') {

        if (this.selectedMode) {
          //close open mode:
          this.closeSelectedMode();

          //resend open event;
          console.log('threejs resend')
          this.appActionsService.toolboxEvents.next(event);
          return;
        }

        if (this.selectedMode == null) {
          //now open the new tool:

          switch (event.tool) {

            case ('transparentMode'):
              this.selectedMode = event.tool;
              this.enterTransparentMode();
              break;
            case ('measureMode'):
              this.selectedMode = event.tool;
              this.enterMeasureMode();
              break;

            case ('placeOnMapMode'):
              this.selectedMode = event.tool;
              this.enterPlaceOnMapMode();
              break;
            case ('zonesMode'):
              this.selectedMode = event.tool;
              this.enterZonesMode();
              break;
            case ('clippingPlanes'):
              this.selectedMode = event.tool;
              this.enterClippingPlanesMode();
              break;



          }
        }

      }

      if (event.type == 'updates') {

        if ((event.tool == 'clippingPlanes') && (event.emitter != this.constructor.name)) {
          if (event.updates) {
            let updates = event.updates


            if (updates.clippingOn == true) {
              this.trunClippingOn();
            }

            if (updates.clippingOn == false) {
              this.turnClippingOff();
            }

            if (updates.reset) {
              this.resetClippingCube()
            }

            if (updates.config) {
              this.loadClppingConfig(updates.config)
            }


          }

          this.askForRender();


        }

        if ((event.tool == 'pdfCompareMode') && (event.emitter != this.constructor.name)) {
          if (event.updates.updateImage) {

            this.updateImageLayer(event.updates.updateImage);
          }

          if (event.updates.updateLayersOrder) {
            this.orderLayers();
          }

          if (event.updates.addNewImage) {


            this.generateImageLayer(event.updates.addNewImage).then(() => {
              this.orderLayers();

              this.updateImageLayer(event.updates.addNewImage)

            }).catch(rejected => {
              if (rejected.code == 2) {
                console.log(rejected.message)
                this.orderLayers();
                this.updateImageLayer(event.updates.addNewImage)
              }

              if (rejected.code == 1) {
                console.error(rejected.err)
              } else {
                console.error(rejected)
              }
            })



          }




          if (event.updates.imageDeleted) {
            this.deleteImageLayer(event.updates.imageDeleted);
          }

        }

        if ((event.tool == 'placeOnMapMode') && (event.emitter != this.constructor.name) && this.selectedMode == 'placeOnMapMode') {

          if (event.updates.switchMapMode) {
            this.setMapboxMode(event.updates.switchMapMode)
          }
          if (event.updates.flyToSite) {
            this.flyToSiteZero();
          }

          if (event.updates.updateSiteProperties) {

            this.setSiteProperties(event.updates.updateSiteProperties);
            this.updateAllImageLayers();
          }

          if (event.updates.cameraProperties && this.mapboxOn) {



            let cp = event.updates.cameraProperties;

            if (cp.compassAngle) { //updating compass
              this.compassAngle = cp.compassAngle;
            }


            //upading threejs camera now :
            //default cp (incase not probided)
            let r = 1000;
            let phi = Math.PI / 2;
            let theta = 0;

            if (cp.zoom) {
              //calc r from zoom
              r = Math.pow(29.16 / cp.zoom, 10.45)

            }

            if (cp.pitch != null) {

              phi = Math.max(Math.PI * (cp.pitch / 180), 0.01);

            }

            if (cp.compassAngle) {

              theta = Math.PI * cp.compassAngle / 180;
            }


            if (cp.center) {
              let xy = this.convertCoordinates(cp.center.long, cp.center.lat);
              let target = new THREE.Vector3(xy.x, xy.y, 0);


              let p = new THREE.Vector3().setFromSpherical(new THREE.Spherical(r, phi, theta)).applyAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2);

              this.camera.position.addVectors(target, p);
              this.controls.target = target;


              this.camera.lookAt(target);
              this.controls.update();
            }

            if (this.mapboxOn) {

            } else {
              this.askForRender();
            }



          }


        }

        if ((event.tool == 'gallery') && (event.emitter != this.constructor.name)) {
          if (event.updates.takePhoto) {
            this.takePhoto();
          }

          if (event.updates.refreshPhoto) {
            this.takePhoto(event.updates.refreshPhoto)
          }


        }


        if ((event.tool == 'measureMode') && (event.emitter != this.constructor.name)) {
         

          if (event.updates.magnetOn != null) {
            this.clearMagnetPoints();
            this.magnetOn = event.updates.magnetOn;
          }
        }

        if (event.tool == 'addNote' && (event.emitter != this.constructor.name)) {
          if (event.type == 'updates') {
            if (event.updates.size) {
              this.pinnedNoteScale = event.updates.size;
            }
            if (event.updates.pinMode != null) {
              if (event.updates.pinMode) {
                this.selectedMode = event.tool;
                this.enterPinnedNoteMode();
              }
              else {
                this.closeSelectedMode();
                this.appActionsService.toolboxEvents.next(new ToolboxEvent('addNote', 'open', this.constructor.name))
              }


            }



          }
        }
      }



    }))


    this.subscriptions.push(this.dataService.notesDataFetched.subscribe(() => {
      console.log('pinned fetched')

      this.refreshPinnedNotes();
      if (this.selectedMode) {
        if (this.selectedMode == 'transparentMode') {

          this.refreshTransparentMode();
        }
      }


    }))

    this.subscriptions.push(this.dataService.labelsUpdated.subscribe(() => {
      this.labels = this.dataService.labels;

      this.updateLabelsWorldPositions()
      this.askForRender(); // will also update labels..
    }))

    this.subscriptions.push(this.dataService.photospheresUpdated.subscribe(() => {


      this.updatePhotoSpheres();
      this.photosphereLabels = {};
      for (let id in this.dataService.photospheres) {
        this.photosphereLabels[id] = {}
      }
      this.updatePhotospheresLabels();


      this.askForRender(); // will also update labels..
    }))



    this.subscriptions.push(this.dataService.notesObjectsRelationsDataFetched.subscribe(() => {

      if (this.selectedMode) {
        if (this.selectedMode == 'transparentMode') {
          console.log('need to refresh the mode now to apply changes')

          this.refreshTransparentMode();

        }
      }
    }))

    this.subscriptions.push(this.appActionsService.photsphereLabelClicked.subscribe(id => {
      this.onPhotosphereClicked(id)
    }))
    this.subscriptions.push(this.appActionsService.flyToPhotosphereClicked.subscribe(id => {

      const mesh = this.photospheres.getObjectByName(id);
      this.transitionToNewCamera({ position: mesh.getWorldPosition(), target: mesh.getWorldPosition().clone().add(new THREE.Vector3(0, 1, 0)) })
    }))

    this.subscriptions.push(this.appActionsService.openNote.subscribe(note => {

      if (note) {
        if (note.position) {

          this.openedPinnedNoteObject.visible = true;

          this.pinnedNotesGroup.getObjectByName("noteMarker_" + note.id).getWorldPosition(this.openedPinnedNoteObject.position)

          let scale = 1.1 * note.scale;

          this.openedPinnedNoteObject.scale.set(scale, scale, scale)

          this.flyTo(this.openedPinnedNoteObject)

        }

        else {
          this.openedPinnedNoteObject.visible = false;
        }
        this.askForRender();
      } else {
        this.openedPinnedNoteObject.visible = false;
        this.askForRender();
      }

    }))
  }


  setColorsFromTheme() {
    this.primaryColor = getComputedStyle(this.primaryColorDiv.nativeElement).color;
    this.accentColor = getComputedStyle(this.accentColorDiv.nativeElement).color;
    this.warnColor = getComputedStyle(this.warnColorDiv.nativeElement).color;
    this.okColor = getComputedStyle(this.okColorDiv.nativeElement).color;
    this.problemColor = getComputedStyle(this.problemColorDiv.nativeElement).color;
    this.noneColor = getComputedStyle(this.noneColorDiv.nativeElement).color;

    this.selectedMaterial.color.set(this.accentColor);
    this.openedPinnedNoteObject.material.color.set(this.accentColor);

    this.hoverMat.color.set(this.primaryColor); //will be overrided if using setHoverMatColorFromMaterial
    this.hoverMat.color.offsetHSL(0, 0.3, 0); //will be overrided if using setHoverMatColorFromMaterial

    this.transparentModeColorGray.color.set(this.noneColor)
    this.transparentModeColorGreen.color.set(this.okColor)
    this.transparentModeColorOrange.color.set(this.problemColor)


    this.clippingPlanesHoverMat.color.set(this.primaryColor)



  }

  setHoverMatColorFromMaterial(material) { //change the hovercolor to be dependent on other material 
    // let originalMaterialColor = null;

    // if (material.length > 0) {
    //   originalMaterialColor = new THREE.Color(material[0].color.getStyle());
    // } else {
    //   originalMaterialColor = new THREE.Color(material.color.getStyle());
    // }

    // let primaryColor = new THREE.Color(this.primaryColor);
    // this.hoverMat.color = originalMaterialColor.lerp(primaryColor, 0.5)



  }

  closeSelectedMode() {
    switch (this.selectedMode) {
      case ('addNote'):
        this.exitPinnedNoteMode();
        break;
      case ('transparentMode'):
        this.exitTransparentMode();
        break;
      case ('measureMode'):
        this.exitMeasureMode();
        break;

      case ('placeOnMapMode'):
        this.exitPlaceOnMapMode();
        break;
      case ('zonesMode'):
        this.exitZonesMode();
        break;
      case ('clippingPlanes'):
        this.exitClippingPlanesMode();
        break;

    }
  }

  getBoundingBoxOfGroup(group) {
    return new THREE.Box3().setFromObject(group)

  }

  placeCameraOnFullProjectView() {
    let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject)
    console.log(modelBox.min, modelBox.max)

    let d = Math.sqrt(Math.pow((modelBox.max.x - modelBox.min.x), 2) + Math.pow((modelBox.max.y - modelBox.min.y), 2))
    d = modelBox.min.distanceTo(modelBox.max);

    let modelCenter = new THREE.Vector3()
    modelBox.getCenter(modelCenter);

    let target = new THREE.Vector3()
    modelBox.getCenter(target)

    let cameraStartPosition = new THREE.Vector3(modelBox.min.x - d / 3, modelCenter.y - d / 3, modelBox.max.z + d / 3)
    cameraStartPosition= target.clone().add(new THREE.Vector3(0,-2,1).normalize().multiplyScalar(d))

    this.camera.position.set(cameraStartPosition.x, cameraStartPosition.y, cameraStartPosition.z);
  


    this.camera.lookAt(target);
    this.controls.target = target;
    this.controls.update();

  }

  loadProjectModel() {
    return new Promise<any>((resolve, reject) => {
      try {

        this.loadingManager = new THREE.LoadingManager(() => {

          this.scene.add(this.projectColladaObject);
          this.projectColladaObject.add(this.pinnedNotesGroup)
          //this.setSiteProperties(this.dataService.siteData);
          // this.placeCameraOnFullProjectView();

          this.subscriptions.push(this.appActionsService.ifcDataFetched.subscribe((ifcData) => {
            // console.log('tree generated subscription in threejs', tree)
            if (ifcData == null) {
              return;
            }


            let zones = this.dataService.getZones();
            // console.log('fetched zones:', zones)
            zones.forEach(zone => {
              let zoneMesh = this.scene.getObjectByName(zone.guid); // (old query way) , but it runs just on loading, and usually we dont have the objectsguidquery ready yet..
              //let zoneMesh = this.objectsGuidQuery[zone.guid]
              if (zoneMesh) {
                zoneMesh.material = new THREE.MeshLambertMaterial({ color: new THREE.Color('hsl(197,70%,39%)'), transparent: true, opacity: 0.15, depthWrite: false, side: THREE.DoubleSide, name: 'zoneMaterial' });
                zoneMesh.isZone = true;
                this.zones.push(zoneMesh)
                zoneMesh.visible = false;

                this.zonesLabels[zone.guid] = { shortName: zone.name, longName: zone.longName, color: zone.color };


              } else {
                console.warn("zone '" + zone.name + "' not found in DAE model")
              }

            })

            //update Zones Colors, inscase its not a zone mode that is loaded aswell in the same time 

            if (this.dataService.viewConfig.zonesMode) {
              let updates = []

              for (let zone of this.dataService.viewConfig.zonesMode) {
                let update = { guid: zone.guid }
                if (zone.color != null) {
                  update['color'] = zone.color;
                } else {
                  update['color'] = 'hsl(197,70%,39%)'; //rememeber to change it if we change the default color
                }

                updates.push(update)

              }
              this.appActionsService.changeZonesProperties.next(updates);
            }


            console.log('loadProjectModel() Done.')

            resolve(null);
          }));

        });

        var loader = new THREE.ColladaLoader(this.loadingManager);


        this.dataService.getProjectModelUrl(this.project.daeFileName).then(url => {


          let filetype = this.project.daeFileName.slice(this.project.daeFileName.length - 3);

          // //when debugging,
          if (this.project.id == '-MNN0q_rm43uhRKmOCQ1a') {
            if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
              this.debugWarning = 'Project loaded localy';
              url = './../assets/data/debug/test.zip' // to save on firebase quota -> load file from asset:
              filetype = 'zip';
            }
          }

          // if (this.project.id == "-LuaNBkzIV7Q3cBQE7-5") {
          //   if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
          //     this.debugWarning = 'Project loaded localy';
          //     url = './../assets/data/debug/mathiashouse.zip' // to save on firebase quota -> load file from asset:
          //     filetype = 'zip';
          //   }
          // }



          // // //

          let loadColladaFile = (collada) => {

            this.projectColladaObject = collada.scene;
            this.projectColladaObject.rotateX(Math.PI / 2)
            console.log('collada project model loaded')
            this.dataService.project3dObject = this.projectColladaObject.clone();


            let t = new Tictac('genlits');
            this.generateObjectsList(this.projectColladaObject, this.objectsGuidQuery.threejs)
            this.generateObjectsList(this.dataService.project3dObject, this.objectsGuidQuery.mapbox)

            t.logPassedTime();




          }

          var xhr = new XMLHttpRequest();
          let t1 = new Date().getTime();
          let dP = 0;

          xhr.responseType = 'blob';
          xhr.onload = (event) => {

            this.downloadingModel = null
            var blob = xhr.response;


            if (filetype == 'dae') {
              let dataurl = window.URL.createObjectURL(blob);
              loader.load(dataurl, (collada) => {
                loadColladaFile(collada);

              })
            }

            if (filetype == 'zip') {

              this.zipService.unzip(blob).then(
                unzipped => {
                  let dataurl = window.URL.createObjectURL(unzipped);

                  loader.load(dataurl, (collada) => {
                    loadColladaFile(collada);

                  })

                },

                rejected => {
                  console.warn(rejected)
                }
              )
            }

          };

          xhr.onprogress = (event) => {
            if (event.lengthComputable) {
              var percentComplete = event.loaded / event.total * 100;
              dP = percentComplete - dP;


              let t2 = new Date().getTime();
              let dt = t2 - t1;
              t2 = t1;

              let speed = dP / dt;
              let timeLeft = (100 - percentComplete) / speed;
              console.log(percentComplete)
              this.downloadingModel = {
                message: 'Downloading 3D Model',
                percentComplete: Math.floor(percentComplete),
                ETA: Math.floor(timeLeft / 1000 / 60)
              }
            }
          }
          xhr.open('GET', url);
          xhr.send();

        })

      } catch (err) {
        console.log('error in loading collada file')
        reject(err)
      }
    })


  }

  initSceneAndLight() {

    this.scene = new THREE.Scene();

    // //debug spheres
    // this.scene.add(this.debugSphere1)

    // this.scene.add(this.debugSphere2)


    this.scene.background = new THREE.Color(0x1a1a1a);


    this.scene.add(this.imageLayers);
    this.scene.add(this.photospheres)
    var ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
    this.scene.add(ambientLight);


    for (let i = 0; i < 6; i++) {
      let deg = 180 * (1 + i) / 9
      let dl = new THREE.DirectionalLight(0xffffff, 0.1 + 0.05 * Math.sin(Math.PI * (deg / 180)))
      dl.position.set(Math.cos(Math.PI * (deg / 180)), Math.sin(Math.PI * (deg / 180)), 1)
      this.scene.add(dl)

    }

    this.newNoteObject.visible = false;
    this.scene.add(this.newNoteObject)
    this.openedPinnedNoteObject.visible = false;
    this.openedPinnedNoteObject.name = "openedNoteMarker"
    this.scene.add(this.openedPinnedNoteObject);

    this.measurePointA.visible = false;
    this.measurePointA.name = 'measurePointA';

    this.measurePointB.visible = false;
    this.measurePointB.name = 'measurePointB';
    this.scene.add(this.measurePointA);
    this.scene.add(this.measurePointB);

    this.meter.visible = false;
    this.scene.add(this.meter)
  }

  initCameraAndControls() {
    let width = document.getElementById('app-content-container').clientWidth;
    let height = document.getElementById('app-content-container').clientHeight;

    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 100000);


    this.camera.position.set(200, 200, 200);
    this.camera.up = new THREE.Vector3(0, 0, 1);
    //
    this.initPlcControls();

    this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target = new THREE.Vector3(0, 0.3, 0.36)



    this.controls.addEventListener('change', (e) => {

      if (this.SAOOn) {

        if (this.controlsChangeStarted) { //hack to make sure it happens only when user did it.
          this.controlsChangeStarted = false;

          this.changeSAOifMixedMode(false)
        }

      }



    });


    this.controls.addEventListener('start', (e) => {

      this.controlsChangeStarted = true; //hack to make sure it happens only when user did it.



    });

    this.controls.addEventListener('end', (e) => {


      this.changeSAOifMixedMode(true)


    });
    this.controls.update()




    // How far you can orbit vertically, upper and lower limits.
    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = Math.PI;


    // How far you can dolly in and out ( PerspectiveCamera only )
    this.controls.minDistance = 0;
    this.controls.maxDistance = Infinity;

    this.controls.enableZoom = true; // Set to false to disable zooming
    this.controls.zoomSpeed = 1.0;


    this.controls.enablePan = true;

    this.subscriptions.push(this.appActionsService.startRotating.subscribe(rotate => {

      this.controls.autoRotateSpeed = 0.3;
      this.controls.autoRotate = true;
      this.controls.addEventListener('start', () => { //stop autoRotate after first interaction with controls
        this.controls.autoRotate = false;
      });


    }))


  }

  initPinnedNotes() {
    this.pinnedNotes = this.dataService.getPinnedNotes();

    this.pinnedNotes.forEach((note, i) => {
      let newObject = this.newNoteObject.clone();
      newObject.material = this.newNoteObject.material.clone();
      newObject.geometry = this.newNoteObject.geometry.clone();
      newObject.visible = true;
      newObject.position.set(note.position.x, note.position.y, note.position.z);
      newObject.scale.set(note.scale, note.scale, note.scale)
      newObject.name = "noteMarker_" + note.id;
      newObject.userData['type'] = 'note';
      newObject.userData['noteId'] = note.id;
      newObject.userData['pinnedNotesIndex'] = i;

      switch (note.type) {
        case 'ok':
          newObject.material.color.set('#4caf50')
          break;
        case 'problem':
          newObject.material.color.set('#ff9800')
          break;
        case 'none':

          break;
      }


      this.pinnedNotesGroup.add(newObject)
    })




    this.askForRender();
  }

  cloneObject(object) {
    let geo = object.geometry.clone();
    let mat = object.material.clone();
    return new THREE.Mesh(geo, mat);
  }

  refreshPinnedNotes() {
    let i = 1;
    let pinnedToDelete = [];
    this.pinnedNotesGroup.children.forEach(o => {
      pinnedToDelete.push(o);



    })
    pinnedToDelete.forEach(p => {
      this.pinnedNotesGroup.remove(p);
      p.material.dispose();
      p.geometry.dispose();
    })



    this.initPinnedNotes();
    this.askForRender();
  }

  ngAfterViewInit() {
    let width = document.getElementById('app-content-container').clientWidth;
    let height = document.getElementById('app-content-container').clientHeight;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);



    this.initJoysticks();

    this.initSAO();


  }

  closestCollisionFromCamera(direction) {


    let cameraDirection = new THREE.Vector3;
    this.camera.getWorldDirection(cameraDirection)

    switch (direction) {
      case 'forward':

        cameraDirection.multiplyScalar(1);
        break;
      case 'back':

        cameraDirection.multiplyScalar(-1);
        break;

      case 'right':

        cameraDirection.cross(new THREE.Vector3(0, 0, 1));
        break;

      case 'left':

        cameraDirection.cross(new THREE.Vector3(0, 0, -1));
        break;


    }





    this.raycaster.set(this.camera.position, cameraDirection);
    let intersects = this.raycaster.intersectObjects(this.collidableObjects, true);


    if (intersects.length > 0) {

      return intersects[0].distance;

    } else {
      return Infinity;
    }

  }

  animate() {
    if (this.destroyed) {
      return
    }

    this.camera.getWorldDirection(this.cameraDirection)
    if (this.plcOn) {


      //collisions:

      let distances = {
        forward: this.closestCollisionFromCamera('forward'),
        back: this.closestCollisionFromCamera('back'),
        right: this.closestCollisionFromCamera('right'),
        left: this.closestCollisionFromCamera('left'),

      }



      let closestCollision = Math.min(distances.forward, distances.back, distances.left, distances.right);



      let speed = 0.1;




      if (closestCollision != Infinity) {

        speed = 0.05 + closestCollision / 100

      } else {
        speed = 1;

      }
      speed = Math.min(1, speed);
      speed = speed * this.speedFactor;
      if (this.activePhotosphere) {
        speed = 0;
      }


      let forward = this.moveForward - this.moveBack;
      let right = this.moveRight - this.moveLeft

      let stopDistance = 0.5;

      if (!this.appActionsService.editorMode) {
        if (forward > 0) {
          if (distances.forward > stopDistance) {

            this.plcControls.moveForward(speed * (forward))

          }
        } else {
          if (distances.back > stopDistance) {

            this.plcControls.moveForward(speed * (forward))

          }
        }

        if (right > 0) {
          if (distances.right > stopDistance) {
            this.plcControls.moveRight(speed * (right))
          }
        } else {
          if (distances.left > stopDistance) {
            this.plcControls.moveRight(speed * (right))
          }
        }
      } else {

        this.plcControls.moveForward(speed * (forward))
        this.plcControls.moveRight(speed * (right))
      }





    }

    if (this.joysticksOn) {

      let p = 80;
      let k = 1000;
      //left joystick
      let forward = -this.leftJoystick.deltaY();

      forward = 0.1 * (Math.abs(forward) < 80 ? (forward / p) : (Math.sign(forward) * ((Math.abs(forward) - p) * ((Math.abs(forward) - p) / k + 1 / p) + 1)));
      console.log(forward)
      let right = this.leftJoystick.deltaX();

      right = 0.1 * (Math.abs(right) < 80 ? (right / p) : (Math.sign(right) * ((Math.abs(right) - p) * ((Math.abs(right) - p) / k + 1 / p) + 1)));

      if (this.activePhotosphere == null) {
        this.plcControls.moveForward(forward)
        this.plcControls.moveRight(right)

      }


      // //right joystick
      var euler = new THREE.Euler(0, 0, 0, 'ZYX');
      euler.setFromQuaternion(this.camera.quaternion);

      euler.x -= this.rightJoystick.deltaY() * 0.0005;
      euler.z -= this.rightJoystick.deltaX() * 0.0005;

      euler.x = Math.max(0, Math.min(2 * (Math.PI / 2), euler.x));

      this.camera.quaternion.setFromEuler(euler);
    }

    //rendering:

    this.stats.begin();
    this.frameCount++;


    if (!((this.mouseOutOfScene && !this.inFlight) || !this.loadingFinished) || this.plcOn) {


      this.render();

    } else {

      if (this.frameCount == this.checkRenderRequireFrameCount) {

        if (this.renderRequired) {
          this.renderIfOutsideScene()
          this.renderRequired = false;
        }
      }

    }

    if (this.frameCount == this.checkRenderRequireFrameCount) {
      this.frameCount = 1;
    }

    this.stats.end();

    if (this.controls.autoRotate) {
      this.controls.update();
      this.renderIfOutsideScene()
    }




    window.requestAnimationFrame(() => this.animate());


  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    //console.log(event)
    if (this.mapboxOn) {
      return;
    }


    if (event.key == 'q' && event.altKey && event.ctrlKey) { //need to find better shourtcut. 
      if (!this.plcOn) {
        this.plcControls.lock();


      } else {

        this.plcControls.unlock();

      }
      return;
    }


    if (this.plcOn) {

      let key = event.key;
      console.log(key)
      switch (key) {
        case 'w': case 'W': case 'z': case 'Z': case 'ArrowUp':
          this.moveForward = 1;
          break;

        case 's': case 'S': case 'ArrowDown':
          this.moveBack = 1;
          break;

        case 'd': case 'D': case 'ArrowRight':
          this.moveRight = 1;
          break;

        case 'a': case 'A': case 'q': case 'Q': case 'ArrowLeft':
          this.moveLeft = 1;
          break;

        case 'Shift':
          this.speedFactor = 4;
          break;

      }
    }



    if (this.selectedMode == 'clippingPlanes' && event.shiftKey && this.clippingModeState == 'rotatingCube') {
      let minDelta = 360;
      let closestAngle = 0;
      for (let i = 0; i < 8; i++) {
        let delta = 0;
        let magnetAngle = i * 360 / 8;
        delta = Math.abs(this.clippingCube.zRotationAngle - magnetAngle);
        if (delta < minDelta) {
          minDelta = delta;
          closestAngle = magnetAngle
        }




      }
      this.setClippingCubeRotation(closestAngle)
      this.updateRotateSphereCenterToIntersectLine(0, 0)
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event) {
    if (this.selectedMode == 'clippingPlanes') {
      if (event.key == 'Control') {
        this.turnRotateSphereOff();
      }

    }


    if (!this.plcOn || this.mapboxOn) {
      return;
    }
    let key = event.key;
    switch (key) {
      case 'w': case 'W': case 'z': case 'Z': case 'ArrowUp':
        this.moveForward = 0;
        break;

      case 's': case 'S': case 'ArrowDown':
        this.moveBack = 0;
        break;
      case 'd': case 'D': case 'ArrowRight':
        this.moveRight = 0;
        break;
      case 'a': case 'A': case 'q': case 'Q': case 'ArrowLeft':
        this.moveLeft = 0;
        break;

      case 'Shift':
        this.speedFactor = 1;
        break;

    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {

    if (!this.loadingFinished) {

      return;
    }
    let width = this.rendererContainer.nativeElement.clientWidth;
    let height = this.rendererContainer.nativeElement.clientHeight;

    this.camera.aspect = width / height;

    this.camera.updateProjectionMatrix();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height)
    this.composer.setSize(width, height)
    console.log(width, height)

    this.render();
    this.askForRender();
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event?) {


    this.mouseOutOfScene = true;


    this.clearINTERSECTED();

    this.askForRender();

  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event?) {

    this.mouseOutOfScene = false;

  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event) {

    this.onMouseMoveClippingPlaneHandler(event)

    //this.mouseOutOfScene = false; //i commented it , i think we dont need it,maybe previous workaraound? i let it here in case we try to debug something and might need to added it again..


    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components


    this.mouse.x = (event.layerX / (this.renderer.domElement.clientWidth / 1)) * 2 - 1;
    this.mouse.y = - (event.layerY / (this.renderer.domElement.clientHeight / 1)) * 2 + 1;
    this.mouse.layerX = event.layerX;
    this.mouse.layerY = event.layerY;




    this.zoneHoverTimer = performance.now();



  }



  @HostListener('mousedown', ['$event'])
  onMouseDownClippingPlaneHandler(event) {
    this.mousePressedButtons[event.button] = true;

    if (this.selectedMode != 'clippingPlanes' || !(event.button == 0 || event.button == 2)) {
      return;
    }

    if (this.mousePressedButtons[2] && event.ctrlKey) {
      this.turnRotateSphereOn();
    }



    if (this.clippingPlanesMeshes.indexOf(this.INTERSECTED) != -1) {
      this.controls.enabled = false;

      this.clippingCube.chosenPlane = this.INTERSECTED;
      let intersect = this.raycaster.intersectObject(this.INTERSECTED);
      if (intersect.length > 0) {

        this.planeDragStartingPoint = intersect[0].point
        let normal = this.clippingCube.chosenPlane.geometry.faces[0].normal.clone();
        if (this.clippingCube.chosenPlane == this.clippingCube.planeZUp || this.clippingCube.chosenPlane == this.clippingCube.planeZDown) { //plane is Zmin / Zmax
          let guidingPlaneNormal = normal.clone().cross(new THREE.Vector3(0, 1, 0));
          guidingPlaneNormal.normalize();

          let d = guidingPlaneNormal.dot(this.planeDragStartingPoint)

          this.guidingPlane = new THREE.Plane(guidingPlaneNormal, -d);


          //2nd
          let guidingPlaneNormal2 = normal.clone().cross(new THREE.Vector3(1, 0, 0));
          guidingPlaneNormal2.normalize();

          let d2 = guidingPlaneNormal2.dot(this.planeDragStartingPoint)

          this.guidingPlane2 = new THREE.Plane(guidingPlaneNormal2, -d2);





        } else {
          let guidingPlaneNormal = normal.clone().cross(new THREE.Vector3(0, 0, 1));
          guidingPlaneNormal.normalize();

          let d = guidingPlaneNormal.dot(this.planeDragStartingPoint)

          this.guidingPlane = new THREE.Plane(guidingPlaneNormal, -d);

          //2nd
          let guidingPlaneNormal2 = normal.clone().cross(guidingPlaneNormal.clone());
          guidingPlaneNormal2.normalize();

          let d2 = guidingPlaneNormal2.dot(this.planeDragStartingPoint)

          this.guidingPlane2 = new THREE.Plane(guidingPlaneNormal2, -d2);



        }




      }



    }

  }

  @HostListener('mouseup', ['$event'])
  onMouseUpandler(event) {

    //clipping planes:
    this.clippingModeState = null;
    this.mousePressedButtons[event.button] = false;
    if (this.selectedMode != 'clippingPlanes') {
      return;
    }

    this.turnRotateSphereOff();
    this.guidingPlane = null;
    this.clippingCube.chosenPlane = null;
    this.controls.enabled = true;

  }






  @HostListener('dblclick', ['$event'])
  onDoubleClick(event) {
    if (this.INTERSECTED) {
      console.log(this.INTERSECTED)
    }





    if (this.INTERSECTED) {
      if (this.INTERSECTED.name == 'openedNoteMarker') {
        this.appActionsService.openNote.next();
        return;
      }
      if (this.INTERSECTED.userData.type == 'note') {
        this.appActionsService.openNote.next(this.pinnedNotes[this.INTERSECTED.userData.pinnedNotesIndex])
        return;
      }



      //objects of ifc:


      if (this.INTERSECTED == this.selectedObject) { //unchosing if alreayd selected  


        this.appActionsService.chosenObjectOid.next(null);
        return;
      }


      this.onUnHoverObject(this.INTERSECTED)


      this.appActionsService.chosenObjectOid.next(this.dataService.getObjectIdOfGuid(this.INTERSECTED.name))





    } else {

      this.appActionsService.chosenObjectOid.next(null)

    }






  }



  @HostListener('click', ['event'])
  onClick(event) {

    this.printMe(performance.now() + ': click')

    this.mouseOutOfScene = false;

    if (this.selectedMode) {
      if (this.selectedMode == 'addNote') {
        this.addNoteOnClick();
      }

      if (this.selectedMode == 'measureMode') {
        this.measureModeOnClick();
      }


    }

    //emit event out on default mode
    if (this.selectedMode == null && !this.clippingOn && !this.plcOn) {
      if (this.INTERSECTED && this.projectColladaObject) {

        const positionModelSpace = this.projectColladaObject.worldToLocal(this.INTERSECTED_POINT);


        const clickEvent = {
          positionWorldSpace: {
            x: positionModelSpace.x,
            y: positionModelSpace.y,
            z: positionModelSpace.z,
          },
          positionModelSpace: {
            x: this.INTERSECTED_POINT.x,
            y: this.INTERSECTED_POINT.y,
            z: this.INTERSECTED_POINT.z,
          },

          guid: this.INTERSECTED.name

        };


        this.appActionsService.threejsClick.next(clickEvent)

      }
    }






  }

  clearINTERSECTED() {

    if (this.INTERSECTED) {
      if (this.INTERSECTED != this.selectedObject) {


        this.onUnHoverObject(this.INTERSECTED)


      }
    }
    this.INTERSECTED = null;
  }

  onHoverObject(object) {


    if (object.isZone) {
      this.onZoneModeHover(object)

      return;
    }

    object['lastUsedMaterial'] = object.material;
    this.hoverMat.color.copy(Array.isArray(object.material) ? object.material[0].color : object.material.color)

    this.hoverMat.map = Array.isArray(object.material) ? object.material[0].map : object.material.map;

    object.material = this.hoverMat;
    this.hoverMat.needsUpdate = true;


    object.hover = true;

    this.setToHoveredEdges(object)

  }

  onUnHoverObject(object) {

    if (object.isZone) {
      this.onZoneModeUnHover(object)
      return;
    }
    if (object['lastUsedMaterial']) {
      object.material = object['lastUsedMaterial'];
      object.lastUsedMaterial = null;
    }


    object.hover = false;

    this.removeHoveredEdges();

  }

  defaultIntersecter() {

    this.raycaster.setFromCamera(this.mouse, this.camera);
    var intersects = this.raycaster.intersectObjects([this.projectColladaObject].concat(this.pinnedNotesGroup.children), true);



    if ((intersects.length > 0) && (this.nonInteractiveObjects.indexOf(intersects[0].object) == -1)) {

      this.INTERSECTED_POINT = intersects[0].point //every time mouse moves!

      if (this.INTERSECTED != intersects[0].object) { //only when object changes

        if (this.INTERSECTED) {

          if (this.INTERSECTED != this.selectedObject) { this.onUnHoverObject(this.INTERSECTED) }
        }

        this.INTERSECTED = intersects[0].object;



        if (this.INTERSECTED != this.selectedObject) {

          this.onHoverObject(this.INTERSECTED)

        }
      }
    }

    else {

      if (this.INTERSECTED) { if (this.INTERSECTED != this.selectedObject) { this.onUnHoverObject(this.INTERSECTED) } }
      this.INTERSECTED = null;
      this.INTERSECTED_POINT = null

    }






  }

  onZoneModeHover(zone) {
    
    this.hoveredZoneGuid = zone.name;
    zone.material.opacity = 0.5;
    zone.hover = true;

  }

  onZoneModeUnHover(zone) {
    zone.material.opacity = 0.15
    zone.hover = false;
    this.hoveredZoneGuid = null;
  }

  zonesIntersecter() {

    this.raycaster.setFromCamera(this.mouse, this.camera);
    var intersects = this.raycaster.intersectObjects(this.zones, true);

    if ((intersects.length > 0) && (this.nonInteractiveObjects.indexOf(intersects[0].object) == -1)) {


      if (this.INTERSECTED != intersects[0].object) {

        if (this.INTERSECTED) {

          if (this.INTERSECTED != this.selectedObject) {
            this.onZoneModeUnHover(this.INTERSECTED);
          }
        }

        this.INTERSECTED = intersects[0].object;
        this.zoneHoverTimer = performance.now();


        if (this.INTERSECTED != this.selectedObject) { //hover if the object is not currently selected..
          this.onZoneModeHover(this.INTERSECTED)





        }
      }
    }

    else {

      if (this.INTERSECTED) {
        if (this.INTERSECTED != this.selectedObject) {
          this.onZoneModeUnHover(this.INTERSECTED);

        }
      }
      this.INTERSECTED = null;
      this.hoveredZoneGuid = null;

    }





  }

  addNoteIntersecter() {


    this.raycaster.setFromCamera(this.mouse, this.camera);
    let intersect = this.raycaster.intersectObjects([this.projectColladaObject], true)[0];
    if (intersect) {
      let position = intersect.point;


      this.newNoteObject.position.copy(position);
    }

  }



  addNoteOnClick() {

    let newNotePosition = this.projectColladaObject.worldToLocal(this.newNoteObject.position.clone());

    let scale = this.newNoteObject.scale.x;

    this.exitPinnedNoteMode();

    this.dataService.createNote('New pinned note', 'type your description here', 'none', { position: { x: newNotePosition.x, y: newNotePosition.y, z: newNotePosition.z }, scale: scale }).then(
      resolved => {

        this.appActionsService.openNote.next(resolved);



      },
      rejected => {


      }
    ).catch(err => { console.warn(err) })



  }

  measureIntersecter() {
    this.scaleObject(this.measurePointA)
    this.scaleObject(this.measurePointB)

    this.raycaster.setFromCamera(this.mouse, this.camera);
    let intersects = this.raycaster.intersectObjects([this.projectColladaObject].concat(this.measureArray), true);
    let intersect = null;
    if (this.clippingOn) {
      for (let i = 0; i < intersects.length; i++) {
        if (intersect) break;

        if (this.clippingCube.boundingBox.distanceToPoint(intersects[i].point.clone()) == 0) {
          intersect = intersects[i]
        }
      }
    } else {
      intersect = intersects[0]
    }


    if (intersect) {


      if (this.measureArray.indexOf(intersect.object) != -1) {
        //magent point intersected
        this.measureIntersected = intersect.object;
        this.intersectPoint = intersect.object.position;
      }
      else {
        this.measureIntersected = null;

        this.intersectPoint = intersect.point;

        if (this.measureObject != intersect.object) { //not need to do all this if its the same object..
          this.measureObject = intersect.object;

          if (this.magnetOn) {
            this.createMagnetPoints();
          }
        }


      }



      //meter drawer:
      switch (this.measurePoints.length) {
        case 0:
          break;
        case 1:

          this.drawMeter(this.measurePointA.position, this.intersectPoint)
          break;
        case 2:

          break;
      }

    } else {
      this.measureIntersected = null;
      this.measureObject = null;
      this.intersectPoint = null;

    }






  }

  measureModeOnClick() {

    switch (this.measurePoints.length) {
      case 0:

        if (this.measureIntersected) {
          this.measurePointA.position.copy(this.measureIntersected.position);
        }
        else {
          if (this.measureObject)
            this.measurePointA.position.copy(this.intersectPoint);
        }

        this.measurePointA.visible = true;
        this.measurePoints.push(this.measurePointA);





        break;
      case 1:
        if (this.measureIntersected) {
          this.measurePointB.position.copy(this.measureIntersected.position);
        }
        else {
          if (this.measureObject)
            this.measurePointB.position.copy(this.intersectPoint);
        }

        this.measurePointB.visible = true;
        this.measurePoints.push(this.measurePointB);
        this.drawMeter(this.measurePointA.position, this.measurePointB.position);



        break;
      case 2:

        this.initMeter();
        if (this.measureIntersected) {
          this.measurePointA.position.copy(this.measureIntersected.position);
        }
        else {
          if (this.measureObject)
            this.measurePointA.position.copy(this.intersectPoint);
        }

        this.measurePointA.visible = true;
        this.measurePoints.push(this.measurePointA);

        break;
    }




  }

  initMeter() {

    this.measurePointB.visible = false;
    this.measurePointA.visible = false;

    this.measurePoints = [];

    this.meter.visible = false;
    this.askForRender();
  }

  drawMeter(point1, point2) {

    this.meter.visible = true;

    let l1 = point1.clone()
    let l2 = point2.clone();
    this.projectColladaObject.worldToLocal(l1)
    this.projectColladaObject.worldToLocal(l2)
    this.meter.position.copy(this.projectColladaObject.position)
    this.meter.quaternion.copy(this.projectColladaObject.quaternion)


    this.meter.geometry.addAttribute('position', new THREE.Float32BufferAttribute([l1.x, l1.y, l1.z, l2.x, l2.y, l2.z], 3));
    this.meter.geometry.verticesNeedUpdate = true;
    this.meter.geometry.computeBoundingSphere();
    //this.meter.computeLineDistances(); //to make it dashed

    //update component:
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('measureMode', 'updates', this.constructor.name, { pointA: l1, pointB: l2, distance: (l1.distanceTo(l2)) }))


  }

  clearMagnetPoints() {
    this.measureArray.forEach(o => {
      this.scene.remove(o);
      o.material.dispose();
      o.geometry.dispose();
      o = undefined;
    })
    this.measureArray = [];

    this.askForRender();

  }
  createMagnetPoints() {

    this.clearMagnetPoints();

    let array = this.measureObject.geometry.attributes.position.array;
    if (array.length > 800) { //jsut for now, change this!!!!
      return;
    }
    let vertices = [];
    for (let i = 0; i < array.length; i = i + 3) {
      vertices.push(new THREE.Vector3(array[i], array[i + 1], array[i + 2]));
    }


    //remove duplicates;
    let finalVertices = [];







    for (let i = 0; i < vertices.length; i++) {
      let v = vertices[i];


      if (!(this.clippingCube.boundingBox.distanceToPoint(this.measureObject.localToWorld(vertices[i].clone())) != 0 && this.clippingOn)) { //if its not inside clipping box
        let existalready = false;
        finalVertices.forEach(fv => {
          if (fv.distanceTo(v) == 0) {
            existalready = true;
          }

        })

        if (!existalready) {
          finalVertices.push(v)
        }
      }



    }




    finalVertices.forEach((v, i) => {
      let position = this.measureObject.localToWorld(v);

      let sphere = new THREE.Mesh(this.measurePointGeometry.clone(), this.measurePointMaterial.clone())


      sphere.visible = true;
      this.measureArray.push(sphere)
      sphere.position.copy(position)
      this.scaleObject(sphere);
      this.scene.add(sphere);
      this.measureArray.push(sphere)




    })


  }

  scaleObject(object) {

    let s = object.position.distanceTo(this.camera.position)
    object.scale.set(s, s, s)
  }



  render() {

    //console.log('render runs')

    if (this.renderDebuggerOn) {
      debugger;
    }


    //wonder why we need this.. maybe fickering issues?
    // if (this.camera.position.distanceTo(this.controls.target) > 100) {

    //   this.camera.near = 10;
    //   this.camera.updateProjectionMatrix()

    // } else {
    //   this.camera.near = 0.1;
    //   this.camera.updateProjectionMatrix()
    // }




    if (!this.appActionsService.isMobile) {
      if (this.selectedMode == null && !this.clippingOn && !this.plcOn) {
        this.defaultIntersecter();
        this.updateZoneLabel();
      }

      else {
        if (this.selectedMode == 'addNote') {

          this.addNoteIntersecter();
        }

        if (this.selectedMode == 'transparentMode') {

          this.defaultIntersecter();
        }

        if (this.selectedMode == 'measureMode') {

          this.measureIntersecter();
        }

        if (this.selectedMode == 'placeOnMapMode') {


        }

        if (this.selectedMode == 'zonesMode') {

          this.zonesIntersecter();
          this.updateZoneLabel();
        }

        if (this.selectedMode == 'clippingPlanes') {
          this.clippingPlanesIntersecter();
        }
      }
    }




    //sao check





    if (this.SAOOn) {


      if (this.composer) {
        this.composer.render();
      } else {
        this.renderer.render(this.scene, this.camera);
      }
    } else {
      this.renderer.render(this.scene, this.camera);
    }





    this.updateDomRelatedElements()


  }

  updateDomRelatedElements() {
    if (!this.mapboxOn) {
      this.compassAngle = this.controls.getAzimuthalAngle() * 180 / Math.PI;
    }

    this.updateLabels();
    this.updatePhotospheresLabels();

    this.updateContainedZone()

  }

  updateContainedZone() {
    let t = new Tictac('t')
    if (!this.plcOn) {
      return;
    }

    for (let i = 0; i < this.zones.length; i++) {

      let x1 = [];
      let x2 = [];
      let y1 = [];
      let y2 = [];
      let z1 = [];
      let z2 = [];

      this.raycaster.set(this.camera.position, new THREE.Vector3(1, 0, 0));
      this.zones[i].raycast(this.raycaster, x1)

      this.raycaster.set(this.camera.position, new THREE.Vector3(-1, 0, 0));
      this.zones[i].raycast(this.raycaster, x2)

      this.raycaster.set(this.camera.position, new THREE.Vector3(0, 1, 0));
      this.zones[i].raycast(this.raycaster, y1)

      this.raycaster.set(this.camera.position, new THREE.Vector3(0, -1, 0));
      this.zones[i].raycast(this.raycaster, y2)

      this.raycaster.set(this.camera.position, new THREE.Vector3(0, 0, 1));
      this.zones[i].raycast(this.raycaster, z1)

      this.raycaster.set(this.camera.position, new THREE.Vector3(0, 0, -1));
      this.zones[i].raycast(this.raycaster, z2)


      if (x1.length > 0 && x2.length > 0 && y1.length > 0 && y2.length > 0 && z1.length > 0 && z2.length > 0) {
        if (this.zones[i].name != this.currentContainedZone) {
          this.currentContainedZone = this.zones[i].name;




        }
        return;
      }






    }

    if (null != this.currentContainedZone) {
      this.currentContainedZone = null;

    }



    // t.logPassedTime();
  }

  renderIfOutsideScene() {

    if (this.mouseOutOfScene) {

      if (this.SAOOn) {


        if (this.composer) {
          this.composer.render();
        } else {
          this.renderer.render(this.scene, this.camera);
        }
      } else {
        this.renderer.render(this.scene, this.camera);
      }


      this.updateDomRelatedElements()


      //this.cdr.detectChanges();

    }
  }

  askForRender() {
    this.renderRequired = true;
  }

  chooseOnScene(oid) {

    console.log('choosing ' + oid)
    let guid = this.dataService.getGuidOfObjectId(oid);
    this.selectedObject = this.scene.getObjectByName(guid)
    if (this.selectedObject == null) {
      console.warn('object not found, cant select')
      return;
    }




    this.selectedObject.userData.oid = oid;



    //start


    this.colorAllObjectInGrayTransparent();
    this.setToSelectedMaterialAndEdges(this.selectedObject)
    //stop




    this.askForRender();



  }


  setToSelectedMaterialAndEdges(object) {
    this.removeSelectedMaterialEdges();
    object.lastUsedMaterial = object.material;
    object.material = this.selectedMaterial;



    var geometry = new THREE.EdgesGeometry(object.geometry); // or WireframeGeometry
    this.selectedEdges = new THREE.LineSegments(geometry, this.selectedEdgesMaterial);


    object.getWorldQuaternion(this.selectedEdges.quaternion)

    object.getWorldPosition(this.selectedEdges.position)
    this.scene.add(this.selectedEdges); // add wireframe as a child of the parent mesh


    if (object.isZone) {
      object.lastVisibleState = object.visible;
    }
    object.visible = true;



  }

  removeSelectedMaterialEdges() {
    if (this.selectedEdges) {
      this.scene.remove(this.selectedEdges)
      this.selectedEdges.geometry.dispose();
      this.selectedEdges.material.dispose();
      this.selectedEdges = null;
    }
  }

  setToHoveredEdges(object) {
    this.removeHoveredEdges();


    var geometry = new THREE.EdgesGeometry(object.geometry); // or WireframeGeometry
    this.hoveredEdges = new THREE.LineSegments(geometry, this.hoveredEdgesMaterial);


    object.getWorldQuaternion(this.hoveredEdges.quaternion)

    object.getWorldPosition(this.hoveredEdges.position)
    this.scene.add(this.hoveredEdges); // add wireframe as a child of the parent mesh



  }

  removeHoveredEdges() {

    if (this.hoveredEdges) {
      this.scene.remove(this.hoveredEdges)
      this.hoveredEdges.geometry.dispose();
      this.hoveredEdges.material.dispose();
      this.hoveredEdges = null;
    }

  }

  unchooseOnScene() {
    console.log('unchoosig ')



    if (this.selectedObject.isZone) {
      console.log('its zone', this.selectedObject)

      this.selectedObject.material = this.selectedObject.lastUsedMaterial;
      this.selectedObject.material.opacity = 0.15;

      if (this.selectedObject.lastVisibleState != null) {
        console.log('its not null')
        this.selectedObject.visible = this.selectedObject.lastVisibleState;
        delete this.selectedObject.lastVisibleState;
      }

    }
    this.lastSelectedObjectOid = this.selectedObject.userData.oid;



    this.dontRender = true;
    this.returnAllObjectsToOriginalMaterial();


    this.INTERSECTED = null;


    this.selectedObject = null;
    this.removeSelectedMaterialEdges();


    this.dontRender = false;

    this.updateVisibilityOfObjectFromFilterOut(this.lastSelectedObjectOid);

    this.askForRender();

  }

  enterPinnedNoteMode() {


    console.log('Entering/refreshing pinnedNote mode')

    this.newNoteObject.visible = true;
    let d = this.pinnedNoteScale;
    this.newNoteObject.scale.set(d, d, d)

    this.askForRender();




  }

  exitPinnedNoteMode() {
    if (this.selectedMode) {
      if (this.selectedMode == "addNote") {
        console.log('Exiting pinnedNote mode')
        this.selectedMode = null;

        this.newNoteObject.visible = false;
        this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));
      }
    }

    this.askForRender();

  }

  labelToScreenPosition(key, camera) {              //calc 2d coordinate of object


    if (this.labels[key].worldPosition == null) {
      return {
        x: -1000,
        y: -1000
      }
    }



    var widthHalf = 0.5 * parseInt(this.renderer.context.canvas.style.width, 10);
    var heightHalf = 0.5 * parseInt(this.renderer.context.canvas.style.height, 10);



    let obj = this.objectsGuidQuery.threejs[this.labels[key].guid];
    let cameraVector = new THREE.Vector3();
    let vector = this.labels[key].worldPosition.clone();
    cameraVector.copy(vector)
    cameraVector.project(camera);







    this.raycasterLabels.setFromCamera(cameraVector, this.camera)

    let rayDistance = Math.abs(this.raycasterLabels.ray.origin.distanceTo(vector));

    this.raycasterLabels.far = rayDistance + 1;





    let intersects = this.raycasterLabels.intersectObjects([this.projectColladaObject], true);



    if (intersects.length > 0) {
      if (intersects[0].object != obj) {

        return {
          x: -1000,
          y: -1000
        };
      }
    } else {
      if (!obj.visible) {

        return {
          x: -1000,
          y: -1000
        };
      }

    }



    cameraVector.x = (cameraVector.x * widthHalf) + widthHalf;
    cameraVector.y = - (cameraVector.y * heightHalf) + heightHalf;


    if (cameraVector.z > 1) { //for hiding object  projecting from the back
      return {
        x: -1000,
        y: -1000
      };
    }

    return {
      x: cameraVector.x,
      y: cameraVector.y
    };

  }

  photosphereToScreenPosition(photosphereMesh) {              //calc 2d coordinate of object
    if (photosphereMesh == null) {

      return null;
    }
    var widthHalf = 0.5 * parseInt(this.renderer.context.canvas.style.width, 10);
    var heightHalf = 0.5 * parseInt(this.renderer.context.canvas.style.height, 10);


    let cameraVector = new THREE.Vector3();
    let vector = new THREE.Vector3();
    photosphereMesh.getWorldPosition(vector);
    cameraVector.copy(vector)
    cameraVector.project(this.camera);






    // let raycaster = new THREE.Raycaster(); //hiding if object center is blocked
    this.raycasterPhotospheres.setFromCamera(cameraVector, this.camera)

    let rayDistance = Math.abs(this.raycasterPhotospheres.ray.origin.distanceTo(vector));

    this.raycasterPhotospheres.far = rayDistance + 1;





    let intersects = this.raycasterPhotospheres.intersectObjects([this.projectColladaObject], true);

    let hidden = false;

    if (intersects.length > 0) {

      if (intersects[0].distance < (rayDistance - 0.001)) {
        hidden = true;
      }
    }


    if (cameraVector.z > 1) { //for hiding object  projecting from the back
      return null;
    }


    cameraVector.x = (cameraVector.x * widthHalf) + widthHalf;
    cameraVector.y = - (cameraVector.y * heightHalf) + heightHalf;


    return {
      x: cameraVector.x,
      y: cameraVector.y,
      hidden: hidden
    };

  }



  enterTransparentMode() {
    console.log('entered transparent mode')
    let objectIsChosen = !!this.selectedObject;
    if (objectIsChosen) {

      this.unchooseOnScene();


    }

    this.clearINTERSECTED();



    this.saveObjectsMaterials();

    this.colorAllObjectInGrayTransparent();

    this.colorAllObjectByRelatedNotesForTransparentMode();



    if (objectIsChosen) {


      this.chooseOnScene(this.lastSelectedObjectOid)


    }


    this.askForRender();
  }

  exitTransparentMode() {




    if (this.selectedMode == "transparentMode") {
      console.log('Exiting transparent mode')


      let objectIsChosen = !!this.selectedObject;
      if (objectIsChosen) {

        this.unchooseOnScene();
      }

      this.loadLastObjectsMaterials();

      if (objectIsChosen) {

        this.chooseOnScene(this.lastSelectedObjectOid)
      }
      this.askForRender();
      this.selectedMode = null;
      this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));
    }






  }

  enterZonesMode() {
    console.log('enterZoneMode')


    let objectIsChosen = !!this.selectedObject;
    if (objectIsChosen) {

      this.appActionsService.chosenObjectOid.next(null);
      //this.unchooseOnScene();


    }

    this.clearINTERSECTED();



    this.saveObjectsMaterials();




    this.colorAllObjectInGrayTransparent();




    this.pinnedNotesGroup.visible = false;
    this.askForRender();
  }

  exitZonesMode() {




    if (this.selectedMode == "zonesMode") {
      console.log('Exiting zones mode')
      let objectIsChosen = !!this.selectedObject;
      if (objectIsChosen) {

        this.unchooseOnScene();
      }

      this.loadLastObjectsMaterials();

      if (objectIsChosen) {

        this.chooseOnScene(this.lastSelectedObjectOid)
      }

      this.pinnedNotesGroup.visible = true;
      this.askForRender();
      this.selectedMode = null;
      this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));
      this.appActionsService.refreshLayersVisability.next();
    }

  }

  updateZoneLabel() {


    if (performance.now() - this.zoneHoverTimer > 100) {
      this.zoneLabel = this.zonesLabels[this.hoveredZoneGuid];

    } else {
      this.zoneLabel = null
    }



  }

  saveObjectsMaterials() {
    this.projectColladaObject.children.forEach((mesh) => {

      if (!mesh.isZone) {
        mesh.savedMaterial = mesh.material;

      }

    })
  }

  loadLastObjectsMaterials() {
    this.projectColladaObject.children.forEach((mesh) => {

      if (!mesh.isZone) {
        mesh.material = mesh.savedMaterial

      }

    })
  }

  colorAllObjectInGrayTransparent() {



    for (let i = 0; i < this.projectColladaObject.children.length; i++) {
      let mesh = this.projectColladaObject.children[i];


      //wont color zones!
      if (!mesh.isZone) {
        mesh['originalMaterial'] = mesh.material;
        mesh['lastUsedMaterial'] = null;
        mesh.material = this.transparentGrayMaterial;
      }




    }
  }



  returnAllObjectsToOriginalMaterial() {
    this.projectColladaObject.children.forEach((mesh) => {

      if (!mesh.isZone) {
        mesh.material = mesh['originalMaterial'];
        mesh['lastUsedMaterial'] = null;
      }

    })
  }

  colorAllObjectByRelatedNotesForTransparentMode() {
    let objectIsChosen = !!this.selectedObject;
    if (objectIsChosen) {

      this.unchooseOnScene();
    }
    let allNotes: Note[] = this.dataService.getAllNotes();
    let objectsToColor = { ok: [], problem: [], none: [] }

    allNotes.forEach(note => {
      this.dataService.getRelatedObjectsOfNote(note.id).forEach(rel => {
        objectsToColor[note.type].push(rel.objectGuid);
      })
    })



    new THREE.MeshPhongMaterial({ color: 0xffffff, transparent: true, opacity: 0.8 })
    //now we need to deal with multiple notes for one object, so we need to choose the type hirarchy we would color... simply we color multiple time, but in order that will perserve our goal
    objectsToColor.none.forEach(objectGuid => {
      let object = this.scene.getObjectByName(objectGuid);
      if (object) {
        object['beforeColoredMaterial'] = object.material;
        object.material = this.transparentModeColorGray;
      }
    })

    objectsToColor.ok.forEach(objectGuid => {
      console.log('coloring ok object')
      let object = this.scene.getObjectByName(objectGuid);
      if (object) {
        object['beforeColoredMaterial'] = object.material;
        object.material = this.transparentModeColorGreen;
      }

    })

    objectsToColor.problem.forEach(objectGuid => {
      let object = this.scene.getObjectByName(objectGuid);
      if (object) {
        object['beforeColoredMaterial'] = object.material;
        object.material = this.transparentModeColorOrange;
      }
    })

    if (objectIsChosen) {

      this.chooseOnScene(this.lastSelectedObjectOid)
    }
    this.askForRender();
  }

  refreshTransparentMode() {
    this.exitTransparentMode();
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('transparentMode', 'open', this.constructor.name));
  }

  enterMeasureMode() {
    console.log('entered measure mode')
  }

  exitMeasureMode() {


    if (this.selectedMode == "measureMode") {
      console.log('exited measure mode')
      this.initMeter();
      this.clearMagnetPoints();
      this.selectedMode = null;

      this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));
    }



  }


  enterClippingPlanesMode() {
    console.log('entered clipping  mode')
    this.setClippingCubeVisibility(true);
    this.askForRender();
  }

  exitClippingPlanesMode() {


    if (this.selectedMode == "clippingPlanes") {
      console.log('exited clipping mode')

      this.setClippingCubeVisibility(false);
      this.turnRotateSphereOff();
      this.askForRender();


      this.selectedMode = null;

      this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));


    }



  }





  setSiteProperties(data) {
    //this now send an update to update in the mapbox component (as the mapbox componenet??)

    this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { siteProperties: data }))

    let XY = this.convertCoordinates(data.longitude, data.latitude);
    let Z = 0;
    console.log(XY)
    this.projectColladaObject.position.set(XY.x + data.offsetX, XY.y + data.offsetY, Z + data.offsetZ);
    this.projectColladaObject.setRotationFromAxisAngle(new THREE.Vector3(0, 0, 1), data.rotate * Math.PI / 180)



    this.projectColladaObject.position.set(XY.x + data.offsetX, XY.y + data.offsetY, Z + data.offsetZ);


    this.photospheres.position.copy(this.projectColladaObject.position)
    this.photospheres.quaternion.copy(this.projectColladaObject.quaternion)

    this.updateLabelsWorldPositions();
    //finished , render
    this.askForRender();

  }

  flyToSiteZero() {

    if (this.mapboxOn) {
      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { flyTo: { target: this.convertToLongLat(this.projectColladaObject.position.x, this.projectColladaObject.position.y), zoom: 17 } }))
    } else {
      this.flyTo(this.projectColladaObject, 0, 0.2, 1)

    }

  }


  enterPlaceOnMapMode() {
    console.log('entered place on mp mode')



    this.setMapboxMode((this.mapboxMode == 'off' || this.mapboxMode == null) ? this.appActionsService.lastMapboxMode : this.mapboxMode) //when quitting placeonmapbox we also set the mapboxmode to null.. now, sometimes our loadview subscribption set already the mapbox mode to the photo's config. so now we know we dont need the 'lastMapboxMode', we need the current one. but when we toggle ourself the place on map mode, we want to have the last one that used. all this mass is because of the old way we implemented the changes here...But.. it works


    this.askForRender();
  }

  exitPlaceOnMapMode() {
    if (this.selectedMode == "placeOnMapMode") {




      //this.flyTo(this.projectColladaObject, 0, 0.1, 1)
      this.scene.fog = null;
      this.pinnedNotesGroup.visible = true;
      this.askForRender();
      console.log('exited placeonmap mode')

      this.selectedMode = null;

      this.setMapboxMode('off');


      this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));
      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { openMapbox: false }))

    }
  }







  placeOnMapIntersecter() {


  }

  takePhoto(refreshPhotoId?) {

    this.appActionsService.openPhoto.next(null)
    this.cameraSound.play();
    this.takingPhoto = true;
    setTimeout(() => {
      this.takingPhoto = false;
    }, 300)

    this.appActionsService.showCameraOverlay = false;


    this.renderer.render(this.scene, this.camera)
    let imgData = null;
    if (this.mapboxOn) {
      imgData = this.appActionsService.mapboxThreejsModel.takePhoto();
    } else {
      imgData = this.renderer.domElement.toDataURL();

    }

    //updating viewConfig
    this.dataService.viewConfig.cameraParameters = {
      position: this.toSimpleVector(this.camera.position),
      target: this.toSimpleVector(this.controls.target)

    }

    this.dataService.viewConfig.mapboxMode = this.mapboxMode;


    this.saveClippingToConfig();


    let ratio = this.renderer.domElement.clientWidth / this.renderer.domElement.clientHeight;
    this.resizedataURL(imgData, 150 * ratio, 150).then(thumb => {
      let date = new Date();
      let title = this.dataService.user.firstName[0] + this.dataService.user.lastName[0] + '_' + date.getTime();
      let description = 'No description';

      let newPhoto = {
        title: title,
        description: description,
        createdBy: this.dataService.user.id,
        dateTaken: date.toString(),
        data: imgData,
        thumbData: thumb,
        // cameraParameters:
        // {
        //   position: this.toSimpleVector(this.camera.position),
        //   target: this.toSimpleVector(this.controls.target)
        // },

        viewConfig: this.dataService.viewConfig,


        // mapboxMode: this.mapboxMode

      }

      console.log(newPhoto.viewConfig, this.dataService.viewConfig)

      if (refreshPhotoId) {
        console.log('refreshing photo ' + refreshPhotoId)
        this.dataService.refreshPhoto(refreshPhotoId, newPhoto)
      } else {
        console.log('creating new photo')
        this.dataService.addPhoto(newPhoto).catch(err => {
          console.warn(err.message)
        })
      }

    })


  }

  toSimpleVector(vector) {
    return { x: vector.x, y: vector.y, z: vector.z }
  }

  resizedataURL(datas, wantedWidth, wantedHeight) {
    // We create an image to receive the Data URI
    var img = document.createElement('img');
    img.src = datas;

    return new Promise<any>((resolve, reject) => {
      img.onload = () => {
        // We create a canvas and get its context.
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');

        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);

        var thumb = canvas.toDataURL();
        resolve(thumb)

        /////////////////////////////////////////
        // Use and treat your Data URI here !! //
        /////////////////////////////////////////
      };
    })



  }

  transitionToNewCamera(cameraParameters) {


    function easeInOutCubic(t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 };
    function easeInOutQuad(t) { return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t }
    this.controls.enableZoom = false;
    this.controls.enablePan = false;
    this.controls.enableRotate = false;
    const time = 2;
    const endTarget = new THREE.Vector3(cameraParameters.target.x, cameraParameters.target.y, cameraParameters.target.z);
    const endPosition = cameraParameters.position;

    this.flightId = this.flightId + 1;

    return new Promise((resolve, reject) => {
      this.changeSAOifMixedMode(false)

      let id = this.flightId;


      this.inFlight = true;
      var T = time; //flight time in seconds;




      var startpoint = { x: 0, y: 0, z: 0 };
      startpoint.x = 0 + this.camera.position.x;
      startpoint.y = 0 + this.camera.position.y;
      startpoint.z = 0 + this.camera.position.z;

      let cameraDirection = new THREE.Vector3();
      this.camera.getWorldDirection(cameraDirection);
      cameraDirection.normalize(); //just in case.  i think it is already normailzed.




      var starttarget = { x: +this.controls.target.x, y: +this.controls.target.y, z: +this.controls.target.z };




      if (T == 0) { //no flight, instant change..
        this.camera.position.set(endPosition.x, endPosition.y, endPosition.z);

        // this.camera.lookAt(target);
        // this.controls.target = target;
        // this.controls.update()

        console.log('position:', this.camera.position, 'target:', this.controls.target)
        this.askForRender();
        this.inFlight = false;
        this.controls.enableZoom = true;
        this.controls.enablePan = true;
        this.controls.enableRotate = true;
        resolve(null);
        return;
      }

      function limitTan(x) {
        if (x < 0) {
          return
        }
      }
      const r0 = new THREE.Vector3(startpoint.x, startpoint.y, startpoint.z).sub(new THREE.Vector3(starttarget.x, starttarget.y, starttarget.z));
      const theta0 = Math.acos(r0.z / r0.length())
      let phi0 = Math.atan2(r0.y, r0.x)

      phi0 = phi0 > 0 ? phi0 :( 2*Math.PI + phi0)

      const r1 = new THREE.Vector3(endPosition.x, endPosition.y, endPosition.z).sub(new THREE.Vector3(endTarget.x, endTarget.y, endTarget.z));
      const theta1 = Math.acos(r1.z / r1.length())
      let phi1 = Math.atan2(r1.y, r1.x);

      phi1 = phi1 > 0 ? phi1 :( 2*Math.PI + phi1)




      const totalFrames = T * 1 * 60;
      let frame = 0;
      console.log(phi0,phi1)

      var i = setInterval(() => {


        this.camera.position.x = startpoint.x + (endPosition.x - startpoint.x) * easeInOutQuad(frame / totalFrames)
        this.camera.position.y = startpoint.y + (endPosition.y - startpoint.y) * easeInOutQuad(frame / totalFrames)
        this.camera.position.z = startpoint.z + (endPosition.z - startpoint.z) * easeInOutQuad(frame / totalFrames)



        if ((theta1 * 180 / Math.PI > 5) && (theta0 * 180 / Math.PI > 5)) {

          this.controls.target.x = starttarget.x + (endTarget.x - starttarget.x) * easeInOutQuad(frame / totalFrames)
          this.controls.target.y = starttarget.y + (endTarget.y - starttarget.y) * easeInOutQuad(frame / totalFrames)
          this.controls.target.z = starttarget.z + (endTarget.z - starttarget.z) * easeInOutQuad(frame / totalFrames)
        } else {

          let theta = theta0 + (theta1 - theta0) * easeInOutQuad(frame / totalFrames);
          let phi = phi0 + (phi1 - phi0) * easeInOutQuad(frame / totalFrames);

          let r = new THREE.Vector3(
            Math.cos(phi) * Math.sin(theta),
            Math.sin(phi) * Math.sin(theta),
            Math.cos(theta)
          )


          this.controls.target.x = this.camera.position.x - r.x;
          this.controls.target.y = this.camera.position.y - r.y;
          this.controls.target.z = this.camera.position.z - r.z;

        }



        this.camera.lookAt(this.controls.target);

        this.controls.update()




        frame++;

        if (frame > totalFrames) {

          clearInterval(i);


          this.camera.position.set(endPosition.x, endPosition.y, endPosition.z);
          this.camera.lookAt(endTarget);
          this.controls.target = endTarget;
          this.controls.update()

          this.changeSAOifMixedMode(true)
          this.askForRender();
          this.inFlight = false;
          this.controls.enableZoom = true;
          this.controls.enablePan = true;
          this.controls.enableRotate = true;

          resolve(null);
        }

        if (this.flightId != id) {

          clearInterval(i);
          console.log('new flight initiated, flight ' + id + ' finished')


          this.askForRender();

          resolve(null);



        }
      }, 16);

    });
  }

  flyTo(object, zjump?, time?, zoomFactor?, moveOnlyForward?) {

    if (object == null) { //treminate function if object is null for any reason.  

      return
    }
    this.controls.enableZoom = false;
    this.controls.enablePan = false;
    this.controls.enableRotate = false;


    let rotateInterfered = false;

    if (zjump == null) {

      zjump = 0;
    }

    if (time == null) {
      time = 0.5;
    }
    function easeInOutCubic(t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 };
    function easeInOutQuad(t) { return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t }



    let box = new THREE.Box3().setFromObject(object);
    let target = new THREE.Vector3();
    box.getCenter(target);




    let size = new THREE.Vector3();
    box.getSize(size);

    let distance = Math.sqrt(size.x * size.x + size.y * size.y + size.z * size.z) * 2;
    if (zoomFactor) {
      distance = Math.sqrt(size.x * size.x + size.y * size.y + size.z * size.z) * zoomFactor;

    }


    //onlyforward:
    if (moveOnlyForward) {

      if (this.camera.position.distanceTo(target) < distance) {
        distance = this.camera.position.distanceTo(target);
      }
    }




    this.flightId = this.flightId + 1;

    new Promise((resolve, reject) => {
      this.changeSAOifMixedMode(false)

      let id = this.flightId;


      this.inFlight = true;
      var T = time; //flight time in seconds;



      let t = 0;
      var startpoint = { x: 0, y: 0, z: 0 };
      startpoint.x = 0 + this.camera.position.x;
      startpoint.y = 0 + this.camera.position.y;
      startpoint.z = 0 + this.camera.position.z;

      let cameraDirection = new THREE.Vector3();
      this.camera.getWorldDirection(cameraDirection);
      cameraDirection.normalize(); //just in case.  i think it is already normailzed.


      let endpoint = target.clone().add(cameraDirection.clone().multiplyScalar(-1 * distance))

      var starttarget = { x: +this.controls.target.x, y: +this.controls.target.y, z: +this.controls.target.z };




      if (T == 0) { //no flight, instant change..
        this.camera.position.set(endpoint.x, endpoint.y, endpoint.z);

        this.camera.lookAt(target);
        this.controls.target = target;
        this.controls.update()

        console.log('position:', this.camera.position, 'target:', this.controls.target)
        this.askForRender();
        this.inFlight = false;
        this.controls.enableZoom = true;
        this.controls.enablePan = true;
        this.controls.enableRotate = true;
        resolve(null);
        return;
      }

      var i = setInterval(() => {


        this.camera.position.x = startpoint.x + (endpoint.x - startpoint.x) * easeInOutQuad(t / T);
        this.camera.position.y = startpoint.y + (endpoint.y - startpoint.y) * easeInOutQuad(t / T);
        this.camera.position.z = startpoint.z + (endpoint.z - startpoint.z) * easeInOutQuad(t / T) + (1 * (-t * (t - T)) * zjump)








        t += 0.016;

        if (t > T) {
          clearInterval(i);
          this.camera.position.set(endpoint.x, endpoint.y, endpoint.z);
          this.camera.lookAt(target);
          this.controls.target = target;
          this.controls.update()

          this.changeSAOifMixedMode(true)
          this.askForRender();
          this.inFlight = false;
          this.controls.enableZoom = true;
          this.controls.enablePan = true;
          this.controls.enableRotate = true;

          resolve(null);
        }

        if (this.flightId != id) {

          clearInterval(i);
          console.log('new flight initiated, flight ' + id + ' finished')


          this.askForRender();

          resolve(null);



        }
      }, 16);

    });










  }




  addSkyAndWater() {

    let sky = null;
    let sunSphere = null;
    let directionalLight = null;
    let water = null;


    console.log('adding sky')

    // Add Sky
    sky = new THREE.Sky();
    sky.scale.setScalar(1000000);


    this.scene.add(sky);
    // Add Sun Helper
    sunSphere = new THREE.Mesh(
      new THREE.SphereBufferGeometry(20000, 16, 8),
      new THREE.MeshBasicMaterial({ color: 0xffffff })
    );
    //sunSphere.position.z = + 700000;
    sunSphere.visible = false;
    this.scene.add(sunSphere);
    sunSphere.name = "sun";





    /// GUI
    var effectController = {
      turbidity: 10,
      rayleigh: 2,
      mieCoefficient: 0.005,
      mieDirectionalG: 0.8,
      luminance: 1,
      inclination: 0.17, // elevation / inclination
      azimuth: 0.2581, // Facing front,
      sun: ! true
    };

    sunSphere.inclination = effectController.inclination;

    var distance = 100000;


    //add light:

    //"skylight"
    var hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.2);

    hemiLight.position.set(0, 0, 500);
    this.scene.add(hemiLight);

    //"sunlight"


    let directionalLight2 = new THREE.DirectionalLight(0xffffff);

    directionalLight2.position.set(0, 4, 10).normalize();
    directionalLight2.position.set(-1500, 0, 1500)

    directionalLight2.castShadow = false;            // default false


    //this.scene.add(directionalLight2);

    // //Set up shadow properties for the light
    // directionalLight.shadow.mapSize.width = 2 * 1024;  // default
    // directionalLight.shadow.mapSize.height = 2 * 1024; // default
    // directionalLight.shadow.camera.near = -2000;    // default
    // directionalLight.shadow.camera.far = 1555;     // default

    // directionalLight.shadow.camera.left = 1000;
    // directionalLight.shadow.camera.right = -1000;
    // directionalLight.shadow.camera.top = 650;
    // directionalLight.shadow.camera.bottom = -600;


    // directionalLight.shadow.camera.bias = 0.0002;





    // var planeGeometry = new THREE.PlaneGeometry(1800, 1500);
    // var planeMaterial = new THREE.ShadowMaterial();
    // planeMaterial.opacity = 0.5;

    // var plane = new THREE.Mesh(planeGeometry, planeMaterial);
    // plane.position.z = 0.1;
    // plane.position.x = -200;
    // plane.receiveShadow = true;
    // this.scene.add(plane);
    // plane.name = "shadow Plane"

    // this.nonInteractiveObjects.push(plane);




    //Create a helper for the shadow camera (optional)
    // helper = new THREE.CameraHelper( directionalLight.shadow.camera );
    // scene.add( helper );

    //water:

    var waterGeometry = new THREE.PlaneBufferGeometry(749480, 797170);
    //moving water: (causing problem in new three.js version.. need to work on it... )
    // water = new THREE.Water(
    //   waterGeometry,
    //   {
    //     textureWidth: 512,
    //     textureHeight: 512,
    //     landArea: new THREE.TextureLoader().load('../../assets/data/geo/reunionsealevel_6000.png', function (texture) {
    //       texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    //     }),
    //     waterNormals: new THREE.TextureLoader().load('../../assets/data/textures/waternormals.jpg', function (texture) {
    //       texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    //     }),
    //     alpha: 1.0,
    //     shiftX: -65.0,
    //     shiftY: -770.0,
    //     size: 8,
    //     sunDirection: sunSphere.position.clone().normalize(),
    //     sunColor: 0xffffff,
    //     height: 0.0,
    //     waterColor: 0x000622,
    //     distortionScale: 3.7,
    //     fog: this.scene.fog !== undefined
    //   }
    // );

    water = new THREE.Mesh(waterGeometry, new THREE.MeshBasicMaterial({ color: 0x233543 }))
    water.position.x = +74948 / 2;
    water.position.y = -79717 / 2;
    water.position.z = -100;

    water.name = "water";


    this.scene.add(water);


    setSkyWaterParameters();
    // var gui = new dat.GUI();
    // let skyfolder = gui.addFolder('Sky')
    // skyfolder.open();

    // skyfolder.add(effectController, "turbidity", 1.0, 20.0, 0.1).onChange(guiChanged);
    // skyfolder.add(effectController, "rayleigh", 0.0, 4, 0.001).onChange(guiChanged);
    // skyfolder.add(effectController, "mieCoefficient", 0.0, 0.1, 0.001).onChange(guiChanged);
    // skyfolder.add(effectController, "mieDirectionalG", 0.0, 1, 0.001).onChange(guiChanged);
    // skyfolder.add(effectController, "luminance", 0.0, 2).onChange(guiChanged);
    // skyfolder.add(effectController, "inclination", 0, 1, 0.0001).onChange(guiChanged);
    // skyfolder.add(effectController, "azimuth", 0, 1, 0.0001).onChange(guiChanged);
    // skyfolder.add(effectController, "sun").onChange(guiChanged);


    // document.getElementById("Controllers").appendChild(gui.domElement)


    // guiChanged();

    // var uniforms = water.material.uniforms;
    // var folder = gui.addFolder('Water');
    // folder.add(uniforms.distortionScale, 'value', 0, 8, 0.1).name('distortionScale');
    // folder.add(uniforms.size, 'value', 0.1, 100, 0.1).name('size');
    // folder.add(uniforms.alpha, 'value', 0.9, 1, .001).name('alpha');
    // folder.add(uniforms.height, 'value', 0, 1, 0.01).name('height');
    // folder.add(uniforms.shiftX, 'value', -1000, 1000, 1).name('shiftX');
    // folder.add(uniforms.shiftY, 'value', -1000, 1000, 1).name('shiftY');
    // folder.open();


    function setSkyWaterParameters() {
      sunSphere.inclination = effectController.inclination;

      var uniforms = sky.material.uniforms;
      uniforms.turbidity.value = effectController.turbidity;
      uniforms.rayleigh.value = effectController.rayleigh;
      uniforms.luminance.value = effectController.luminance;
      uniforms.mieCoefficient.value = effectController.mieCoefficient;
      uniforms.mieDirectionalG.value = effectController.mieDirectionalG;
      var theta = Math.PI * (effectController.inclination - 0.5);
      var phi = 2 * Math.PI * (effectController.azimuth - 0.5);
      sunSphere.position.z = distance * Math.cos(theta);
      sunSphere.position.x = distance * Math.sin(theta) * Math.sin(phi);
      sunSphere.position.y = distance * Math.sin(theta) * Math.cos(phi);
      sunSphere.visible = effectController.sun;
      uniforms.sunPosition.value.copy(sunSphere.position);
      //water.material.uniforms.sunDirection.value.copy(sunSphere.position).normalize();

      directionalLight2.position.set(sunSphere.position.normalize().x, sunSphere.position.normalize().y, sunSphere.position.normalize().z);



    }


  }











  convertCoordinatesToReal(lon2, lat2) {

    var o = { long: 55.17986, lat: -20.81986 };
    let lat1 = o.lat;
    let lon1 = o.long;

    //go back to lonitude axis corseponse to zero point. (50.18)
    let LAT = lat2 * Math.PI / 180;

    let km_per_deg_lon = 111.41513 * Math.cos(LAT) - 0.09455 * Math.cos(3 * LAT) + 0.00012 * Math.cos(5 * LAT);

    let totalX = (lon2 - lon1) * km_per_deg_lon * 1000;


    //now lets climb up. we must integrate
    let total_Lat = (lat2 - lat1) * Math.PI / 180;;
    let steps = 100;
    var dLat = total_Lat / steps;
    let totalY = 0;


    for (let i = 0; i < steps; i++) {
      let km_per_deg_lat = 111.13209 - 0.56605 * Math.cos(2 * LAT) + 0.00120 * Math.cos(4 * LAT)

      totalY = totalY + km_per_deg_lat * dLat * 1000 / (Math.PI / 180);

      LAT = LAT + dLat

    }


    console.log(totalX, totalY)
    return { x: totalX, y: totalY }


  }

  convertToLongLatFromReal(x, y) {
    var o = { long: 55.17986, lat: -20.81986 };


    var lat = o.lat;




    let steps = Math.floor(Math.abs(y));
    let res = Math.abs(y) - steps;

    var dY = 1 * Math.sign(y)



    for (let i = 0; i <= steps; i++) {

      let km_per_deg_lat = 111.13209 - 0.56605 * Math.cos(2 * lat * Math.PI / 180) + 0.00120 * Math.cos(4 * lat * Math.PI / 180)

      if (i == steps) {
        dY = res;

      }


      lat = lat + dY / (km_per_deg_lat * 1000);


    }

    let km_per_deg_lon = 111.41513 * Math.cos(lat * Math.PI / 180) - 0.09455 * Math.cos(3 * lat * Math.PI / 180) + 0.00012 * Math.cos(5 * lat * Math.PI / 180);

    var long = o.long + x / (km_per_deg_lon * 1000);

    // console.log(long, lat)

    return { long: long, lat: lat }



  }

  convertCoordinates(lon, lat) {

    // upper width (x) = 74.948km
    // lower width (x) = 74.586km
    // height (Y) = 79.717km

    // Extent
    // 55.1798611110000010,-21.5398611110000004 : 55.8998611109999999,-20.8198611110000016



    let widthX = 74948;
    let heightY = 79717;



    let min = { lon: 55.17986, lat: -20.81986 };
    let max = { lon: 55.89986, lat: -21.53986 };

    let widthLon = Math.abs(max.lon - min.lon);
    let heightLat = Math.abs(max.lat - min.lat);

    let x = widthX * (lon - min.lon) / widthLon;
    let y = heightY * (lat - min.lat) / heightLat;

    // console.log(x,y)
    return { x: x, y: y }
  }

  convertToLongLat(x, y) {

    let widthX = 74948;
    let heightY = 79717;



    let min = { lon: 55.17986, lat: -20.81986 };
    let max = { lon: 55.89986, lat: -21.53986 };

    let widthLon = Math.abs(max.lon - min.lon);
    let heightLat = Math.abs(max.lat - min.lat);


    let lon = min.lon + x * widthLon / widthX;
    let lat = min.lat + y * heightLat / heightY;



    return ({ long: lon, lat: lat })

  }





  moveToNorth() {
    let target = this.controls.target.clone();
    let pos = this.camera.position.clone();
    let r = new THREE.Vector3().subVectors(pos, target);

    r = r.applyAxisAngle(new THREE.Vector3(0, 0, 1), -this.controls.getAzimuthalAngle());
    r.add(target);
    this.camera.position.set(r.x, r.y, r.z)
    this.camera.lookAt(target);
    this.controls.update();

    if (this.mapboxOn) {

      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { resetNorth: true }))
    }




  }



  flyToSiteNavButtonClicked() {
    if (this.mapboxOn) {
      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { flyTo: { target: this.convertToLongLat(this.projectColladaObject.position.x, this.projectColladaObject.position.y), zoom: 15 } }))
    } else {
      this.flyTo(this.projectColladaObject, 0, 0.2, true)
    }
  }



  zoom(x) {

    if (this.mapboxOn) {
      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { zoomInOut: -Math.sign(x) }))
    } else {
      let d = this.camera.position.distanceTo(this.controls.target);
      d = d * (1 + x);

      this.flyToSphere.scale.set(d, d, d);
      this.flyToSphere.position.copy(this.controls.target)
      this.flyTo(this.flyToSphere, 0, 0.2, 0.288)
    }



  }

  getImage(imageId) {
    let image = null;
    for (let existingImage of this.dataService.compareImages) {
      if (existingImage.id == imageId) {
        image = existingImage;
      }
    }

    return image;
  }

  generateImageLayer(imageId) {
    console.log('strated generating image ' + imageId)


    return new Promise<any>((resolve, reject) => {

      if (this.imageLayers.getObjectByName(imageId)) { //verify this layer is not already generated!
        reject({ code: 2, message: 'layer already exist, generateImageLayer stoped.' })
        return;
      }

      let image = this.getImage(imageId)

      if (image == null) {
        reject({ code: 2, message: 'image doesnt exist in compareImages array! generateImageLayer stoped.' })
        return;
      }

      let geometry = new THREE.PlaneGeometry(1, 1);


      // instantiate a loader
      var loader = new THREE.TextureLoader();

      // load a resource
      loader.load(
        // resource URL (if imageData managed to fetch already, it will load it.. if not, it will download again...)
        image.imageData ? URL.createObjectURL(image.imageData) : image.url,

        // onLoad callback
        (imageTexture) => {
          imageTexture.minFilter = THREE.LinearFilter;
          // in this example we create the material when the texture is loaded

          let opcaity, visible;
          if (this.dataService.viewConfig.pdfCompareMode[image.id]) {
            opcaity = this.dataService.viewConfig.pdfCompareMode[image.id].opacity;
            visible = this.dataService.viewConfig.pdfCompareMode[image.id].visible;
          }


          let material = new THREE.MeshBasicMaterial({
            map: imageTexture,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: opcaity ? opcaity : 1,
            depthWrite: false

          });

          let plane = new THREE.Mesh(geometry, material);
          plane.visible = (visible != null) ? visible : false;

          if (image.id) {
            plane.name = image.id;
          }


          this.imageLayers.add(plane);
          console.log('finished generating image ' + image.id)
          image['modelReady'] = true;

          resolve(null)
          return;

        },

        // onProgress callback currently not supported
        undefined,

        // onError callback
        (err) => {
          reject({ code: 1, message: 'error happened', error: err });
          console.error('An error happened.', err);

        }
      );
    })


  }

  updateAllImageLayers() {
    this.dataService.compareImages.forEach(image => {
      this.updateImageLayer(image.id)
    })
  }

  updateImageLayer(imageId) {



    let imageLayer = this.imageLayers.getObjectByName(imageId)
    let image = this.getImage(imageId);


    if ((image == null) || (imageLayer == null)) {
      console.log('returned')
      return;
    }



    if (image.width != null) {
      imageLayer.scale.x = image.width;
    }

    if (image.height != null) {
      imageLayer.scale.y = image.height;
    }

    let pos = new THREE.Vector3();


    if ((image.longitude != null) && (image.latitude != null)) {

      let xy = this.convertCoordinates(image.longitude, image.latitude)


      pos.set(xy.x, xy.y, 0)


    } else {

      pos.copy(this.projectColladaObject.position)
    }





    let offset = new THREE.Vector3(image.offsetX ? image.offsetX : 0, image.offsetY ? image.offsetY : 0, image.offsetZ ? image.offsetZ : 0)

    imageLayer.position.copy(pos.add(offset));

    //reset all rotations:
    imageLayer.setRotationFromAxisAngle(new THREE.Vector3(1, 0, 0), 0)

    if (image.vertical) { //flip

      imageLayer.setRotationFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2)

    }

    if (image.rotation != null) {

      if (image.vertical) {
        imageLayer.rotateOnAxis(new THREE.Vector3(0, 1, 0), -image.rotation * Math.PI / 180) //rotate around y
      } else {
        imageLayer.rotateOnAxis(new THREE.Vector3(0, 0, 1), -image.rotation * Math.PI / 180) //rotate around z
      }


    }

    if (image.viewConfig.opacity != null) {

      imageLayer.material.opacity = image.viewConfig.opacity;
    }

    if (image.viewConfig.visible != null) {
      imageLayer.visible = image.viewConfig.visible;
    }


    this.askForRender();





  }


  deleteImageLayer(id) {
    let img = this.imageLayers.getObjectByName(id);
    if (img) {
      this.imageLayers.remove(img);
      img.material.dispose();
      img.geometry.dispose();
    }

    this.askForRender();
  }

  orderLayers() {
    let i = -1;
    let layerOrder = [];
    if (this.dataService.viewConfig['pdfCompareMode']['layersOrderById']) { //incase config exist for layerorder
      layerOrder = this.dataService.viewConfig['pdfCompareMode']['layersOrderById'];

    }

    this.dataService.compareImages.forEach(image => { //start pushing all ids that doesnt exist in config
      if (layerOrder.indexOf(image.id) == -1) {
        layerOrder.push(image.id);
      }

    })


    layerOrder.forEach(id => { //now set renderOrder
      let imageLayerToOrder = this.imageLayers.getObjectByName(id); //there is chance layer was deleted..
      if (imageLayerToOrder) {
        imageLayerToOrder.renderOrder = i;
        i--;
      }

    })

    this.askForRender();
  }





  setMapboxMode(mode) {
    switch (mode) {

      case ('off'):
        this.mapboxMode = 'off';
        this.mapboxOn = false;

        break;
      case ('map'):
        this.mapboxOn = true;
        this.mapboxMode = 'map'
        break;
      case ('sat'):
        this.mapboxOn = true;
        this.mapboxMode = 'sat'
        break;
    }

    if (this.mapboxOn) {
      this.controls.autoRotate = false;
    }

    if (this.appActionsService.mapboxMode != 'off' && this.appActionsService.mapboxMode != null) {
      this.appActionsService.lastMapboxMode = this.appActionsService.mapboxMode;
    }

    this.appActionsService.mapboxMode = this.mapboxMode


    this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, {
      openMapbox: this.mapboxOn,
      mapboxMode: this.mapboxMode,
      target: this.convertToLongLat(this.controls.target.x, this.controls.target.y),
      zoom: (29.16 * 1 / (Math.pow(this.controls.target.distanceTo(this.camera.position), 0.0956))).toFixed(1),
      compassAngle: this.compassAngle,
      pitch: this.controls.getPolarAngle() * 180 / Math.PI,
      siteProperties: this.dataService.siteData
    }))
  }



  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  screenChange(event) {

    this.fullscreen = !this.fullscreen;

  }

  toggleFullscreen() {




    if (!this.fullscreen) {
      this.d['documentElement'].requestFullscreen();
    } else {
      this.d.webkitExitFullscreen()
    }



  }

  //vis filters:
  setVisibilityFilterOut(oid, mode, state) {
    if (this.visibilityFilters[oid] == null) {
      this.visibilityFilters[oid] = {};

    }

    this.visibilityFilters[oid][mode] = state;

  }

  updateVisibilityOfObjectFromFilterOut(oid) {


    if (this.visibilityFilters[oid] == null || this.selectedMode == 'zonesMode') {

      return;
    }

    let visible = !(this.visibilityFilters[oid]['layers'] || this.visibilityFilters[oid]['tree']);

    let guid = this.dataService.getGuidOfObjectId(oid) //threejs model
    if (guid != null) {

      let object = this.objectsGuidQuery.threejs[guid];
      if (object) {
        object.visible = visible;

        if (visible == false) {
          if (object == this.selectedObject) {
            object.visible = true;
          }
        }



      }


      //need to create a list for mapbox object query aswell!!!
      let objectInMapboxModel = this.objectsGuidQuery.mapbox[guid] //for mapboxmodel


      if (objectInMapboxModel) {

        objectInMapboxModel.visible = visible;

      }
    }





  }



  //debugger
  debugMouseEntered() {

    this.onMouseLeave()
  }

  debugMouseLeft() {
    this.onMouseEnter()

  }


  debugHandleDblClicked(e) {

    this.toggleMinimizeDebugger();

  }


  toggleMinimizeDebugger() {
    this.debuggerMinimized = !this.debuggerMinimized;
  }


  printMe(p) {
    this.mobileLog = p;
  }

  toggleColladaProject() {
    this.projectColladaObject.visible = !this.projectColladaObject.visible;
  }



  toggleControls() {
    this.controls.enabled = !this.controls.enabled
  }

  addFlashLight() {
    let flashlight = new THREE.SpotLight(0xffffff, 1, 15);
    this.camera.add(flashlight);
    flashlight.position.set(0, 0, 1);
    this.scene.add(this.camera)
    flashlight.target = this.camera;

    // let sphere = new THREE.Mesh(new THREE.SphereGeometry(2,8,8), new THREE.MeshBasicMaterial({color:0xffffff}));
    // let spot =  new THREE.SpotLight(0xffffff, 1);
    // this.projectColladaObject.add(spot);
    // this.projectColladaObject.add(sphere)
    // spot.position.set(10, 10, 30);
    // sphere.position.set(10,10,30)
    // spot.target = this.projectColladaObject;


  }

  test() {

  
    this.renderer.shadowMap.enabled = true;

    let sun = new THREE.DirectionalLight(0xffffff, 1);
    this.sun = sun;


    const modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject);

    const targetObject = new THREE.Object3D();

    targetObject.position.copy(modelBox.min.add(modelBox.max).multiplyScalar(0.5));
    this.scene.add(targetObject);
    sun.target = targetObject;
    sun.position.copy(sun.target.position).add(new THREE.Vector3(0, 50, 50));


    sun.castShadow = true;
    sun.shadow.bias = - 0.001;
    const d = 100;
    sun.shadow.camera.left = -d;
    sun.shadow.camera.right = d;
    sun.shadow.camera.top = d;
    sun.shadow.camera.bottom = -d;
    sun.shadow.camera.far = 100;

    sun.shadow.radius = 2;

    sun.shadowMapWidth = 4 * 4096;
    sun.shadowMapHeight = 4 * 4096;
    this.scene.add(sun)
    // this.scene.add(new THREE.CameraHelper(sun.shadow.camera))

    for (let guid in this.objectsGuidQuery.threejs) {
      const obj = this.objectsGuidQuery.threejs[guid];

      obj.castShadow = true;
      obj.receiveShadow = true;

      if (isArray(obj.material)) {
        for (let mat of obj.material) {
          mat.needsUpdate = true;
        }
      } else {
        if (obj.material) {
          obj.material.needsUpdate = true;
        }

      }

    }

    this.sunChange('month', 1)
    this.sunChange('hour', 15)
  }

  mixedModeChanged() {
    if (this.MixedSAOMode) {
      this.SAOOn = true;
    }
  }


  lockPlc() {
    this.plcControls.lock();
  }




  toggleDebuggerOnRender() {
    this.renderDebuggerOn = !this.renderDebuggerOn;
  }

  initPlcControls() {

    this.plcControls = new THREE.PointerLockControls(this.camera, document.body)



    this.plcControls.addEventListener('lock', () => {
      this.changeSAOifMixedMode(false)
      this.onPlcLock()


    });

    this.plcControls.addEventListener('unlock', () => {
      this.changeSAOifMixedMode(true)
      console.log('three plc unlock', performance.now())


      this.onPlcUnlock()


    });

  }

  onPlcLock() {
    this.controls.autoRotate = false;
    this.moveForward = this.moveLeft = this.moveBack = this.moveRight = 0;
    this.controls.enabled = false;
    this.controls.update();
    this.plcOn = true;




  }

  onPlcUnlock() {

    this.exitFromActivePhotosphere()

    this.currentContainedZone = null;


    let raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(new THREE.Vector2(0, 0), this.camera);
    let intersects = raycaster.intersectObjects([this.projectColladaObject], true);
    let target = null;

    if (intersects.length > 0) {
      target = new THREE.Vector3(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z)
      console.log('target', target)


    } else {
      let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject)
      let d = Math.pow(
        Math.abs((modelBox.max.x - modelBox.min.x) * (modelBox.max.y - modelBox.min.y) * (modelBox.max.z - modelBox.min.z)),
        1 / 3)
      let v = new THREE.Vector3();
      this.camera.getWorldDirection(v)

      v.multiplyScalar(d).add(this.camera.position.clone());


      target = v;
      console.log('no target', target)

    }

    this.camera.lookAt(target)
    this.controls.target = target;

    this.plcOn = false; // should it be here ? on start of function?
    this.controls.enabled = true;

    this.controls.update();










  }

  sendMessage() {
    this.eventStatusThree.emit(this.statusThree);
  }

  //materials list
  scanAllMaterials() {



    let scannedMaterials = [];
    let objectsUsingMaterials = {};

    for (let i = 0; i < this.projectColladaObject.children.length; i++) {
      let child = this.projectColladaObject.children[i];

      if (child.material) {

        if (this.zones.indexOf(child) == -1) {

          let oid = this.dataService.getObjectIdOfGuid(child.name); // push material / materials to objectsdata

          if (this.dataService.objectsData[oid] == null) {
            this.dataService.objectsData[oid] = {}
          }

          this.dataService.objectsData[oid].material = child.material;


          if (child.material.length > 1) {



            child.material.forEach(mat => {

              if (scannedMaterials.indexOf(mat) == -1) {

                objectsUsingMaterials[mat.uuid] = [];
                scannedMaterials.push(mat)

              }


              objectsUsingMaterials[mat.uuid].push(child.name)




            })

          } else {


            if (scannedMaterials.indexOf(child.material) == -1) {

              objectsUsingMaterials[child.material.uuid] = [];

              scannedMaterials.push(child.material)

            }

            objectsUsingMaterials[child.material.uuid].push(child.name)

          }
        }


      }



    }



    scannedMaterials.forEach(material => {
      material.side = THREE.DoubleSide;

      material.userData['name'] = material.name;
      this.materialsList.push(
        {
          material: material,
          usedByObjects: objectsUsingMaterials[material.uuid]
        }
      )
    })

    this.dataService.materials = this.materialsList;

    console.log(this.materialsList)







  }

  updateLabelsWorldPositions() {
    console.log('labels world position updated')

    for (let key in this.labels) {
      if (this.projectColladaObject) {
        let object = this.objectsGuidQuery.threejs[this.labels[key].guid];

        var vector = new THREE.Vector3();
        let box = new THREE.Box3().setFromObject(object)

        box.getCenter(vector)

        //if offset exist:
        if (this.labels[key].offset) {
          vector.x = vector.x + this.labels[key].offset.x;
          vector.y = vector.y + this.labels[key].offset.y;
          vector.z = vector.z + this.labels[key].offset.z;
        }

        this.labels[key].worldPosition = vector;


      }


    }

  }


  updateLabels() {

    for (let key in this.labels) {
      if (this.projectColladaObject) {
        if (this.labels[key].worldPosition && this.labels[key].visible) {
          this.labels[key].position = this.labelToScreenPosition(key, this.camera);
        }
      }


    }
  }

  updatePhotospheresLabels() {

    for (let id in this.photosphereLabels) {
      let showLabel = false;

      if (this.dataService.viewConfig.photospheresConfig != null) {



        const config = this.dataService.viewConfig.photospheresConfig[id];
        if (config) {
          const visible = this.dataService.viewConfig.photospheresConfig[id].visible;
          const ps = this.dataService.photospheres[id];

          if (ps) {

            if (!ps.showAlways && visible) {
              const screenPosition = this.photosphereToScreenPosition(this.photospheres.getObjectByName(id));
              if (screenPosition) {
                this.photosphereLabels[id].screenPosition = screenPosition;
                showLabel = true;
              }
            }
          }

        }


      }

      if (showLabel == false) {
        this.photosphereLabels[id].screenPosition = null;
      }


    }

    // if (this.dataService.viewConfig.photospheresConfig) {
    //   for (let id in this.dataService.viewConfig.photospheresConfig) {

    //     const visible = this.dataService.viewConfig.photospheresConfig[id].visible;

    //     const ps = this.dataService.photospheres[id];
    //     let shouldBeRemoved = false;
    //     if (ps) {

    //       if (!ps.showAlways && visible) {
    //         console.log('read id',id)
    //         const screenPosition = this.photosphereToScreenPosition(this.photospheres.getObjectByName(id));
    //         console.log(screenPosition)
    //         if (screenPosition) {
    //           this.photosphereLabels[id].screenPosition = screenPosition;
    //         } else {
    //           console.log(1)
    //           shouldBeRemoved = true;
    //         }

    //       } else {console.log(2)
    //         shouldBeRemoved = true;
    //       }
    //     } else {console.log(3)
    //       shouldBeRemoved = true;
    //     }


    //     if (shouldBeRemoved) {
    //       console.log('delete id',id)
    //       delete this.photosphereLabels[id];
    //     }




    //   }

    // }




  }


  generateObjectsList(object, list) {

    let name = object.name;
    if (name != null && name != '') {
      list[object.name] = object;
    }

    if (object.children.length > 0) {
      console.log('object has children')
      for (let i = 0; i < object.children.length; i++) {
        this.generateObjectsList(object.children[i], list)
      }
    }



  }

  initClippingPlanes() {

    this.clippingPlanes = [
      new THREE.Plane(new THREE.Vector3(1, 0, 0), -100000),
      new THREE.Plane(new THREE.Vector3(-1, 0, 0), 100000),
      new THREE.Plane(new THREE.Vector3(0, 1, 0), -100000),
      new THREE.Plane(new THREE.Vector3(0, -1, 0), 100000),
      new THREE.Plane(new THREE.Vector3(0, 0, 1), -100000),
      new THREE.Plane(new THREE.Vector3(0, 0, -1), 100000)
    ]
  }

  resetClippingCube() {

    let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject);

    var cc = {
      o: modelBox.min,
      a: new THREE.Vector3(modelBox.max.x - modelBox.min.x, 0, 0),
      b: new THREE.Vector3(0, modelBox.max.y - modelBox.min.y, 0),
      c: new THREE.Vector3(0, 0, modelBox.max.z - modelBox.min.z)
    };

    this.clippingCube.vertices[0].copy(new THREE.Vector3());
    this.clippingCube.vertices[1].copy(cc.a);
    this.clippingCube.vertices[2].copy(cc.b);
    this.clippingCube.vertices[3].copy(cc.a.clone().add(cc.b));
    this.clippingCube.vertices[4].copy(cc.c);
    this.clippingCube.vertices[5].copy(cc.a.clone().add(cc.c));
    this.clippingCube.vertices[6].copy(cc.b.clone().add(cc.c));
    this.clippingCube.vertices[7].copy(cc.a.clone().add(cc.b).add(cc.c));


    this.clippingCube.zRotationAngle = 0;


    this.clippingCube.position = modelBox.min;

    this.clippingCube.planeXDown.position.copy(cc.o)
    this.clippingCube.planeXUp.position.copy(cc.o)
    this.clippingCube.planeYDown.position.copy(cc.o)
    this.clippingCube.planeYUp.position.copy(cc.o)
    this.clippingCube.planeZDown.position.copy(cc.o)
    this.clippingCube.planeZUp.position.copy(cc.o)


    this.updateClippingPlanes();
    this.updateClippingPlanesFromClippingCube();





  }


  initClippingCube() {

    let modelBox = this.getBoundingBoxOfGroup(this.projectColladaObject)

    var cc = {
      o: modelBox.min,
      a: new THREE.Vector3(modelBox.max.x - modelBox.min.x, 0, 0),
      b: new THREE.Vector3(0, modelBox.max.y - modelBox.min.y, 0),
      c: new THREE.Vector3(0, 0, modelBox.max.z - modelBox.min.z)
    };


    this.clippingCube.vertices.push(
      new THREE.Vector3(),
      cc.a,
      cc.b,
      cc.a.clone().add(cc.b),
      cc.c,
      cc.a.clone().add(cc.c),
      cc.b.clone().add(cc.c),
      cc.a.clone().add(cc.b).add(cc.c)

    );


    this.clippingCube.zRotationAngle = 0;

    this.clippingCube.position = modelBox.min;

    this.clippingCube.computeBoundingBox();


    //plane   
    var geometry = new THREE.Geometry();
    geometry.vertices.push(
      this.clippingCube.vertices[0],
      this.clippingCube.vertices[1],
      this.clippingCube.vertices[2],
      this.clippingCube.vertices[3],
    );
    geometry.faces.push(
      new THREE.Face3(0, 2, 1),
      new THREE.Face3(2, 3, 1),
    );
    geometry.computeBoundingSphere();
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();

    var material = new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, opacity: 0.2, depthWrite: false });

    var planeZDown = new THREE.Mesh(geometry, material)
    planeZDown.clippingPlaneRef = new THREE.Plane(new THREE.Vector3(0, 0, 1), -geometry.vertices[0].z + 0.1);

    this.scene.add(planeZDown);
    this.clippingCube.planeZDown = planeZDown;

    //plane
    var geometry = new THREE.Geometry();
    geometry.vertices.push(
      this.clippingCube.vertices[4],
      this.clippingCube.vertices[5],
      this.clippingCube.vertices[6],
      this.clippingCube.vertices[7],
    );
    geometry.faces.push(
      new THREE.Face3(0, 1, 2),
      new THREE.Face3(1, 3, 2),
    );
    geometry.computeBoundingSphere();
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();




    var planeZUp = new THREE.Mesh(geometry, material)
    planeZUp.clippingPlaneRef = new THREE.Plane(new THREE.Vector3(0, 0, -1), geometry.vertices[0].z + 0.1);

    this.scene.add(planeZUp);
    this.clippingCube.planeZUp = planeZUp;


    //plane
    var geometry = new THREE.Geometry();
    geometry.vertices.push(
      this.clippingCube.vertices[0],
      this.clippingCube.vertices[2],
      this.clippingCube.vertices[4],
      this.clippingCube.vertices[6],
    );
    geometry.faces.push(
      new THREE.Face3(1, 0, 2),
      new THREE.Face3(3, 1, 2),
    );
    geometry.computeBoundingSphere();
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();

    ;

    var planeXDown = new THREE.Mesh(geometry, material)
    planeXDown.clippingPlaneRef = new THREE.Plane(new THREE.Vector3(1, 0, 0), -geometry.vertices[0].x + 0.1);

    this.scene.add(planeXDown);
    this.clippingCube.planeXDown = planeXDown;



    //plane
    var geometry = new THREE.Geometry();
    geometry.vertices.push(
      this.clippingCube.vertices[1],
      this.clippingCube.vertices[3],
      this.clippingCube.vertices[5],
      this.clippingCube.vertices[7],
    );
    geometry.faces.push(
      new THREE.Face3(0, 1, 2),
      new THREE.Face3(1, 3, 2),
    );
    geometry.computeBoundingSphere();
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();



    var planeXUp = new THREE.Mesh(geometry, material)
    planeXUp.clippingPlaneRef = new THREE.Plane(new THREE.Vector3(-1, 0, 0), geometry.vertices[0].x + 0.1);

    this.scene.add(planeXUp);
    this.clippingCube.planeXUp = planeXUp;

    //plane
    var geometry = new THREE.Geometry();
    geometry.vertices.push(
      this.clippingCube.vertices[0],
      this.clippingCube.vertices[1],
      this.clippingCube.vertices[4],
      this.clippingCube.vertices[5],
    );
    geometry.faces.push(
      new THREE.Face3(0, 1, 2),
      new THREE.Face3(1, 3, 2),
    );
    geometry.computeBoundingSphere();
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();



    var planeYDown = new THREE.Mesh(geometry, material)
    planeYDown.clippingPlaneRef = new THREE.Plane(new THREE.Vector3(0, 1, 0), -geometry.vertices[0].y + 0.1);

    this.scene.add(planeYDown);
    this.clippingCube.planeYDown = planeYDown;

    //plane
    var geometry = new THREE.Geometry();
    geometry.vertices.push(
      this.clippingCube.vertices[2],
      this.clippingCube.vertices[3],
      this.clippingCube.vertices[6],
      this.clippingCube.vertices[7],
    );
    geometry.faces.push(
      new THREE.Face3(1, 0, 2),
      new THREE.Face3(3, 1, 2),
    );
    geometry.computeBoundingSphere();
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();


    var planeYUp = new THREE.Mesh(geometry, material)
    planeYUp.clippingPlaneRef = new THREE.Plane(new THREE.Vector3(0, -1, 0), geometry.vertices[0].y + 0.1);

    this.scene.add(planeYUp);
    this.clippingCube.planeYUp = planeYUp;

    this.clippingCube.planeXDown.position.copy(cc.o)
    this.clippingCube.planeXUp.position.copy(cc.o)
    this.clippingCube.planeYDown.position.copy(cc.o)
    this.clippingCube.planeYUp.position.copy(cc.o)
    this.clippingCube.planeZDown.position.copy(cc.o)
    this.clippingCube.planeZUp.position.copy(cc.o)


    this.clippingPlanes[0] = this.clippingCube.planeXDown.clippingPlaneRef;
    this.clippingPlanes[1] = this.clippingCube.planeXUp.clippingPlaneRef;
    this.clippingPlanes[2] = this.clippingCube.planeYDown.clippingPlaneRef;
    this.clippingPlanes[3] = this.clippingCube.planeYUp.clippingPlaneRef;
    this.clippingPlanes[4] = this.clippingCube.planeZDown.clippingPlaneRef;
    this.clippingPlanes[5] = this.clippingCube.planeZUp.clippingPlaneRef;

    this.clippingPlanesMeshes = [
      this.clippingCube.planeXDown,
      this.clippingCube.planeXUp,
      this.clippingCube.planeYDown,
      this.clippingCube.planeYUp,
      this.clippingCube.planeZDown,
      this.clippingCube.planeZUp
    ]




    this.setClippingCubeVisibility(false)


    //adding rotateguide sphere an dline

    this.clippingCube.rotateCubeSphere = new THREE.Mesh(
      new THREE.SphereGeometry(1, 16, 16),
      new THREE.MeshPhongMaterial({ color: 0xff0000 })
    );
    var material = new THREE.LineBasicMaterial({
      color: 0x0000ff
    });
    var points = [];
    points.push(new THREE.Vector3(0, 0, -1000));
    points.push(new THREE.Vector3(0, 0, 1000));
    var geometry = new THREE.BufferGeometry().setFromPoints(points);
    var line = new THREE.Line(geometry, material);
    this.clippingCube.rotateCubeSphere.add(line);


    var material = new THREE.LineBasicMaterial({
      color: 0x00ff00
    });

    material.color.set(this.accentColor)
    var points = [];
    points.push(new THREE.Vector3(0, 0, 0));
    points.push(new THREE.Vector3(0, 0, 0));
    var geometry = new THREE.BufferGeometry().setFromPoints(points);
    this.clippingCube.rotateCubeSphere.centerToIntersectLine = new THREE.Line(geometry, material);
    this.clippingCube.rotateCubeSphere.add(this.clippingCube.rotateCubeSphere.centerToIntersectLine);




    this.scene.add(this.clippingCube.rotateCubeSphere)

    this.clippingCube.rotateCubeSphere.material.color.set(this.accentColor)
    line.material.color.set(this.accentColor)

    let center = new THREE.Vector3(0, 0, 0);
    center.add(this.clippingCube.planeZUp.geometry.vertices[0]).add(this.clippingCube.planeZUp.geometry.vertices[1]).add(this.clippingCube.planeZUp.geometry.vertices[2]).add(this.clippingCube.planeZUp.geometry.vertices[3]);
    center.multiplyScalar(1 / 4);
    this.clippingCube.rotateCubeSphere.position.copy(center);
    this.turnRotateSphereOff();

  }


  setClippingCubeVisibility(visible) {

    this.clippingPlanesMeshes.forEach(planeMesh => {

      planeMesh.visible = visible;
    })

  }



  trunClippingOn() {

    this.materialsList.forEach(materialItem => {

      materialItem.material.clippingPlanes = this.clippingPlanes;
    })
    this.renderer.localClippingEnabled = true;
    this.clippingOn = true;

  }

  turnClippingOff() {
    this.materialsList.forEach(materialItem => {
      materialItem.material.clippingPlanes = this.clippingPlanes;
    })
    this.renderer.localClippingEnabled = false;
    this.clippingOn = false;
  }

  clippingPlanesIntersecter() {
    if (this.clippingCube.chosenPlane != null) {
      return;
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);
    var intersects = this.raycaster.intersectObjects(
      this.clippingPlanesMeshes
      , true);



    if (intersects.length > 0) {

      if (this.INTERSECTED != intersects[0].object) {

        if (this.INTERSECTED) {

          if (this.INTERSECTED != this.selectedObject) { this.INTERSECTED.material = this.INTERSECTED['lastUsedMaterial']; }
        }

        this.INTERSECTED = intersects[0].object;

        if (this.INTERSECTED != this.selectedObject) {

          this.INTERSECTED['lastUsedMaterial'] = this.INTERSECTED.material;

          this.INTERSECTED.material = this.clippingPlanesHoverMat;


        }
      }
    }

    else {

      if (this.INTERSECTED) { if (this.INTERSECTED != this.selectedObject) { this.INTERSECTED.material = this.INTERSECTED['lastUsedMaterial']; } }
      this.INTERSECTED = null;

    }
  }

  onMouseMoveClippingPlaneHandler(event) {

    if (this.selectedMode != 'clippingPlanes') {
      return;
    }



    if (this.clippingCube.chosenPlane) {





      if (event.ctrlKey) {
        if (this.mousePressedButtons[0]) {
          this.clippingModeState = 'draggingCube';

          this.translateCubeOnDrag();
          this.updateClippingPlanes()
          this.updateClippingPlanesFromClippingCube()
          return;
        }

        if (this.mousePressedButtons[2]) {
          this.clippingModeState = 'rotatingCube';

          this.turnRotateSphereOn();
          this.rotateCubeOnDrag(event)
          this.updateClippingPlanes()
          this.updateClippingPlanesFromClippingCube()
          return;
        }

      } else {
        if (this.mousePressedButtons[0]) {
          this.clippingModeState = 'draggingPlane';
          this.translatePlaneOnDrag();
          this.updateClippingPlanes()
          this.updateClippingPlanesFromClippingCube()
          return;
        }
      }










    }

  }

  rotateCubeOnDrag(event) {



    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.guidingPlaneZ.constant = -this.planeDragStartingPoint.z;
    let intersect = this.raycaster.ray.intersectPlane(this.guidingPlaneZ, new THREE.Vector3());

    if (intersect) {
      let center = new THREE.Vector3(0, 0, 0);
      for (let i = 0; i < 4; i++) {
        center.add(this.clippingCube.planeZUp.geometry.vertices[i]).add(this.clippingCube.planeZUp.position);
      }

      center.multiplyScalar(1 / 4);
      center.z = this.planeDragStartingPoint.z;
      this.clippingCube.rotateCubeSphere.position.z = center.z;

      let v1 = new THREE.Vector3().subVectors(this.planeDragStartingPoint.clone(), center)
      let v2 = new THREE.Vector3().subVectors(intersect.clone(), center)

      this.updateRotateSphereCenterToIntersectLine(v2.x, v2.y)


      if (v2.length() < 2 || event.shiftKey) {
        this.planeDragStartingPoint = intersect;
        this.updateRotateSphereCenterToIntersectLine(0, 0)
        return;
      }


      v1.normalize();
      v2.normalize();

      let dot = v1.dot(v2);
      if (dot < -1) {
        dot = -1;
      }

      if (dot > 1) {
        dot = 1;
      }

      let angle = 180 * Math.acos(dot) / Math.PI;
      let cross = new THREE.Vector3().copy(v1).cross(v2);



      if (cross.dot(this.guidingPlaneZ.normal.clone()) < 0) { // Or > 0

        angle = -angle;
      }

      this.rotateClippingCube(angle)


      this.planeDragStartingPoint = intersect;


    } else {
      //controll rotation by mouse event movement (left/right = decrease/increase)
      this.updateRotateSphereCenterToIntersectLine(0, 0)
      let angle = event.movementX * 0.4;

      this.rotateClippingCube(-angle)


    }


  }

  translateCubeOnDrag() {
    console.log('translateCubeOnDrag')
    this.raycaster.setFromCamera(this.mouse, this.camera);
    console.log(this.clippingCube.chosenPlane.clippingPlaneRef)

    let intersect = this.raycaster.ray.intersectPlane(this.clippingCube.chosenPlane.clippingPlaneRef, new THREE.Vector3());

    console.log(intersect)

    if (intersect) {
      let delta = new THREE.Vector3().subVectors(intersect, this.planeDragStartingPoint)

      delta.add(this.clippingCube.chosenPlane.clippingPlaneRef.normal.multiplyScalar(-this.clippingPlaneConstantOffset));
      this.translateClippingCube(delta)

      this.planeDragStartingPoint.add(delta);
    }


  }

  translatePlaneOnDrag() {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    let normal = this.clippingCube.chosenPlane.geometry.faces[0].normal.clone();
    let delta = new THREE.Vector3(0, 0, 0);

    var intersect1 = this.raycaster.ray.intersectPlane(this.guidingPlane, new THREE.Vector3());
    var intersect2 = this.raycaster.ray.intersectPlane(this.guidingPlane2, new THREE.Vector3());

    let d1 = intersect1 ? intersect1.distanceTo(this.camera.position) : Infinity;
    let d2 = intersect1 ? intersect1.distanceTo(this.camera.position) : Infinity;

    let intersect = intersect1;

    if (d2 < d1) {
      intersect = intersect2;
    }

    if (intersect) {
      if (this.clippingCube.chosenPlane == this.clippingCube.planeZUp || this.clippingCube.chosenPlane == this.clippingCube.planeZDown) {
        delta.z = intersect.z - this.planeDragStartingPoint.z;
      } else {
        delta.x = intersect.x - this.planeDragStartingPoint.x;
        delta.y = intersect.y - this.planeDragStartingPoint.y;
      }


    }





    for (let n = 0; n < 8; n++) {

      if (!(this.clippingCube.chosenPlane.geometry.vertices[0] === this.clippingCube.vertices[n])) { //suppose to detect negative cube and cancel

        if (this.clippingCube.chosenPlane.geometry.vertices[0].clone().add(delta).distanceTo(this.clippingCube.vertices[n]) < 5) {

          return;
        }


      }

    }


    for (let i = 0; i < 4; i++) {

      this.clippingCube.chosenPlane.geometry.vertices[i].add(delta);


    }


    //if did something not good, cancel movement:
    if (
      (this.clippingCube.vertices[4].z - this.clippingCube.vertices[0].z < 0) ||
      (this.clippingCube.vertices[2].y - this.clippingCube.vertices[0].y < 0) ||
      (this.clippingCube.vertices[1].x - this.clippingCube.vertices[0].x < 0)
    ) {
      console.log('flipped cube..canceling')
      for (let i = 0; i < 4; i++) {
        this.clippingCube.chosenPlane.geometry.vertices[i].sub(delta);

      }
      return;
    }

    this.planeDragStartingPoint.add(delta);
  }
  updateClippingPlanesFromClippingCube() {




    for (let planeMesh of this.clippingPlanesMeshes) {

      // 

      let normal = planeMesh.geometry.faces[0].normal.clone();
      planeMesh.clippingPlaneRef.normal = normal.clone().multiplyScalar(-1);
      planeMesh.clippingPlaneRef.constant = planeMesh.geometry.vertices[0].clone().add(planeMesh.position).dot(normal); //set constant
      planeMesh.clippingPlaneRef.constant -= this.clippingPlaneConstantOffset; //offset constant abit inside the cube 


    }




  }

  turnRotateSphereOn() {
    this.updateRotateSpherePosition()
    this.clippingCube.rotateCubeSphere.visible = true;

  }

  turnRotateSphereOff() {
    this.clippingCube.rotateCubeSphere.visible = false;

    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.array[3] = 0;
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.array[4] = 0;
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.needsUpdate = true;

  }

  updateRotateSphereCenterToIntersectLine(x, y) {


    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.array[3] = x;
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.array[4] = y;
    this.clippingCube.rotateCubeSphere.centerToIntersectLine.geometry.attributes.position.needsUpdate = true;


  }

  updateRotateSpherePosition() {
    let center = new THREE.Vector3();
    center.add(this.clippingCube.planeZUp.geometry.vertices[0]).add(this.clippingCube.planeZUp.geometry.vertices[1]).add(this.clippingCube.planeZUp.geometry.vertices[2]).add(this.clippingCube.planeZUp.geometry.vertices[3]);
    center.multiplyScalar(1 / 4);
    this.clippingCube.rotateCubeSphere.position.copy(center.add(this.clippingCube.position));
  }

  updateClippingPlanes() {
    this.clippingCube.computeBoundingBox();
    for (let plane of this.clippingPlanesMeshes) {

      plane.geometry.computeBoundingSphere();
      plane.geometry.computeFaceNormals();
      plane.geometry.computeVertexNormals();

      plane.geometry.facesNeedUpdate = true;
      plane.geometry.verticesNeedUpdate = true;
      plane.geometry.boundingSphere = null;
      plane.geometry.boundingBox = null;

    }
  }

  setClippingCubeRotation(angle) {

    angle = angle % 360;
    if (angle < 0) {
      angle = angle + 360
    }

    let center = new THREE.Vector3(0, 0, 0);
    for (let i = 0; i < 8; i++) {

      center.add(this.clippingCube.vertices[i])
    }
    center = center.multiplyScalar(1 / 8);

    for (let i = 0; i < 8; i++) {

      this.clippingCube.vertices[i].addScaledVector(center, -1)
    }

    this.clippingCube.rotateZ(-this.clippingCube.zRotationAngle * Math.PI / 180)

    this.clippingCube.rotateZ(angle * Math.PI / 180)

    this.clippingCube.zRotationAngle = angle;

    for (let i = 0; i < 8; i++) {

      this.clippingCube.vertices[i].addScaledVector(center, 1)
    }

    this.updateClippingPlanes();
    this.updateClippingPlanesFromClippingCube()


  }




  rotateClippingCube(angle) {

    this.setClippingCubeRotation(this.clippingCube.zRotationAngle + angle);
  }




  translateClippingCube(vector) {
    console.log('translateClippingCube')
    for (let i = 0; i < 8; i++) {

      this.clippingCube.vertices[i].addScaledVector(vector, 1)
    }
    this.updateClippingPlanes();
    this.updateClippingPlanesFromClippingCube()

  }


  saveClippingToConfig() {
    let vertices = [];
    for (let i = 0; i < this.clippingCube.vertices.length; i++) {
      vertices.push(this.toSimpleVector(this.clippingCube.vertices[i]));
    }

    this.dataService.viewConfig.clippingMode = {
      vertices: vertices,
      rotation: this.clippingCube.zRotationAngle,
      position: this.toSimpleVector(this.clippingCube.position),
      clippingOn: this.clippingOn
    }


  }

  loadClppingConfig(config) {
    console.log(config)

    if (config.vertices) {
      for (let i = 0; i < config.vertices.length; i++) {
        this.clippingCube.vertices[i].set(
          config.vertices[i].x,
          config.vertices[i].y,
          config.vertices[i].z)

      }
    }


    if (config.rotation) {
      this.clippingCube.zRotationAngle = config.rotation;
    }

    if (config.position) {
      this.clippingCube.position.set(
        config.position.x,
        config.position.y,
        config.position.z
      )

    }

    if (config.clippingOn) {
      this.trunClippingOn();
    } else {
      this.turnClippingOff();
    }


    this.updateClippingPlanes();
    this.updateClippingPlanesFromClippingCube();
  }


  gameModeClicked() {

    if (!this.appActionsService.isMobile) {
      this.lockPlc();
    } else {
      this.joysticksOn = true;
    }

  }

  initJoysticks() {

    // return;

    this.leftJoystick = new VirtualJoystick({
      container: this.mobileFpsControls.nativeElement,
      strokeStyle: this.accentColor,
      limitStickTravel: true,
      stickRadius: 120,
    });

    this.leftJoystick.addEventListener('touchStartValidation', function (event) {
      var touch = event.changedTouches[0];
      if (touch.pageX >= window.innerWidth / 2) return false;
      return true
    });

    this.rightJoystick = new VirtualJoystick({
      container: this.mobileFpsControls.nativeElement,
      strokeStyle: this.accentColor,
      limitStickTravel: true,
      stickRadius: 120
    });
    this.rightJoystick.addEventListener('touchStartValidation', function (event) {
      var touch = event.changedTouches[0];
      if (touch.pageX < window.innerWidth / 2) return false;
      return true
    });
    this.rightJoystick.addEventListener('touchStart', function () {
      console.log('fire')
    })
  }


  closeJoysticksClicked() {
    this.joysticksOn = false;
    this.exitFromActivePhotosphere();
  }


  initSAO() {







    let composer = new THREE.EffectComposer(this.renderer);
    this.composer = composer;

    let width = document.getElementById('app-content-container').clientWidth;
    let height = document.getElementById('app-content-container').clientHeight;

    this.composer.setSize(width, height)

    // let renderPass = new THREE.RenderPass(this.scene, this.camera);
    // composer.addPass(renderPass);

    let ssaaRenderPass = new THREE.SSAARenderPass(this.scene, this.camera, 0xAAAAAA, 0)
    this.composer.addPass(ssaaRenderPass)




    let saoPass = new THREE.SAOPass(this.scene, this.camera, false, true);
    this.saoPass = saoPass;
    saoPass.renderToScreen = true;

    saoPass.resolution.set(8192, 8192)

    composer.addPass(saoPass);


    // let effectFXAA = new THREE.ShaderPass(THREE.FXAAShader);
    // effectFXAA.uniforms['resolution'].value.x = 1 / (width * window.devicePixelRatio);
    // effectFXAA.uniforms['resolution'].value.y = 1 / (height * window.devicePixelRatio);
    // composer.addPass(effectFXAA);





    for (let param in this.saoParams) {
      this.saoPass.params[param] = this.saoParams[param];

    }


    // Init gui
    this.debugGUI = new dat.GUI({ autoPlace: false });
    this.domRenderer.appendChild(this.guiContainer.nativeElement, this.debugGUI.domElement)

    this.debugGUI.add(this.saoPass.params, "output", {
      'Beauty': +THREE.SAOPass.OUTPUT.Beauty,
      'Beauty+SAO': +THREE.SAOPass.OUTPUT.Default,
      'SAO': +THREE.SAOPass.OUTPUT.SAO,
      'Depth': +THREE.SAOPass.OUTPUT.Depth,
      'Normal': +THREE.SAOPass.OUTPUT.Normal
    }).onChange(value => {
      this.saoPass.params.output = parseInt(value)
    });

    console.log({
      'Beauty': THREE.SAOPass.OUTPUT.Beauty,
      'Beauty+SAO': THREE.SAOPass.OUTPUT.Default,
      'SAO': THREE.SAOPass.OUTPUT.SAO,
      'Depth': THREE.SAOPass.OUTPUT.Depth,
      'Normal': THREE.SAOPass.OUTPUT.Normal
    }
      ,
      this.saoPass)



    this.debugGUI.add(this.saoPass.params, "saoBias", -1, 1);
    this.debugGUI.add(this.saoPass.params, "saoIntensity", 0, .001).step(0.00001);
    this.debugGUI.add(this.saoPass.params, "saoScale", 0, 50).step(0.1);
    this.debugGUI.add(this.saoPass.params, "saoKernelRadius", 1, 100);
    this.debugGUI.add(this.saoPass.params, "saoMinResolution", 0, 1);
    this.debugGUI.add(this.saoPass.params, "saoBlur");
    this.debugGUI.add(this.saoPass.params, "saoBlurRadius", 0, 50);
    this.debugGUI.add(this.saoPass.params, "saoBlurStdDev", 0.5, 150);
    this.debugGUI.add(this.saoPass.params, "saoBlurDepthCutoff", 0.0, 0.1);




  }

  toggleAmbientOcclusion() {
    this.MixedSAOMode = false;
    this.SAOOn = !this.SAOOn;
  }




  generateCollisionObjectsArray() {
    let noCollideGuids = this.dataService.getAllDoorsAndWindows();
    for (let zone of this.zones) {
      noCollideGuids.push(zone.name)
    }
    for (let guid in this.objectsGuidQuery.threejs) {
      let object = this.objectsGuidQuery.threejs[guid];
      if (noCollideGuids.indexOf(guid) == -1) {
        this.collidableObjects.push(this.objectsGuidQuery.threejs[guid])
      }
    }
  }



  fadeSAO() {

  }


  changeSAOifMixedMode(state) {

    if (this.MixedSAOMode) {
      // console.log('sao state changed to: ' + state)
      this.SAOOn = state;
    }

  }

  updatePhotoSpheres() {
    console.log(this.photospheres)
    //update or add
    for (let id in this.dataService.photospheres) {
      const photosphere = this.dataService.photospheres[id]
      let mesh = this.photospheres.getObjectByName(id);

      if (mesh == null) {
        console.log('created')

        this.createPhotospere(photosphere)



      } else {

        this.updatePhotosphere(mesh, photosphere)
      }


    }

    //remove
    const ids = Object.keys(this.dataService.photospheres);

    for (let mesh of this.photospheres.children) {
      if (ids.indexOf(mesh.name) == -1) {
        this.photospheres.remove(mesh)
      }
    }
  }

  updatePhotosphere(mesh, properties) {
    mesh.material.opacity = properties.opacity;
    mesh.position.set(properties.position.x, properties.position.y, properties.position.z)
    mesh.scale.set(properties.radius, properties.radius, properties.radius);

    if (this.dataService.viewConfig.photospheresConfig[mesh.name]) { //make sure visible for showAlways mesh if its on on config.

      mesh.visible = Boolean(properties.showAlways) && Boolean(this.dataService.viewConfig.photospheresConfig[mesh.name].visible);
    } else {
      mesh.visible = false;
    }

  }



  createPhotospere(photosphere) {

    const mat = new THREE.MeshBasicMaterial();
    mat.opacity = 1;
    mat.transparent = true;
    mat.visible = false;

    const mesh = new THREE.Mesh(this.photosphereGeometry, mat);
    mesh.name = photosphere.id;
    mesh.rotateX(Math.PI / 2);
    mesh.up = this.camera.up;
    mesh.visible = false;
    this.photospheres.add(mesh)
    this.updatePhotosphere(mesh, photosphere)

    this.dataService.getPhotosphereImageUrl(photosphere.storageName).then(textureUrl => {




      console.log('mmm', photosphere.name)
      const texture = new THREE.TextureLoader().load(textureUrl);

      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      mat.map = texture;
      mat.visible = true;
      texture.needsUpdate = true;
      mat.needsUpdate = true;


    })







  }

  onPhotosphereClicked(id) {
    const mesh = this.photospheres.getObjectByName(id);

    if (mesh) {
      this.beforePhotosphereCameraProperties = {
        position: this.camera.position.clone(),
        target: this.controls.target.clone()
      }

      this.transitionToNewCamera({ position: mesh.getWorldPosition(), target: mesh.getWorldPosition().clone().add(new THREE.Vector3(0, 1, 0)) }).then(
        flightEnded => {
          this.activePhotosphere = id;

          this.gameModeClicked()
          mesh.visible = true;
        },
        rejected => {
          // do nothing?
        }
      )

    }

  }

  exitFromActivePhotosphere() {

    if (this.activePhotosphere) {
      const mesh = this.photospheres.getObjectByName(this.activePhotosphere);
      mesh.visible = false;
      this.activePhotosphere = null;
      this.transitionToNewCamera(this.beforePhotosphereCameraProperties)
    }

  }

  sunChange(n, value) {


    if (n == 'month') {
      this.sunMonth = Number(value);
    } else {
      this.sunHour = Number(value);
    }

    let date = new Date();
    const month = Math.floor(this.sunMonth - 1)
    date.setMonth(month);
    const day = (this.sunMonth - 1 - month) * 31
    date.setDate(day)
    const h = Math.floor(this.sunHour);
    const m = (this.sunHour - h) * 60;
    date.setHours(h, m, 0, 0)

    const angles = SunCalc.getPosition(date, Number(this.dataService.siteData.latitude), Number(this.dataService.siteData.longitude));

    const thehta = Math.PI / 2 - angles.altitude;
    const phi = +Math.PI * 3 / 2 - angles.azimuth;



    const r = 50;
    const pos = new THREE.Vector3(
      r * Math.sin(thehta) * Math.cos(phi),
      r * Math.sin(thehta) * Math.sin(phi),
      r * Math.cos(thehta)
    )


    this.sun.position.copy(this.sun.target.position.clone().add(pos))

  }

  colorZonesFromConfig(zonesColors) {
   
    for (let change of zonesColors) {
      if( this.zonesLabels[change.guid]) {
        this.zonesLabels[change.guid].color = change.color;
      }
     
     
      const zoneMesh = this.objectsGuidQuery.threejs[change.guid];

      if (zoneMesh) {
        if (this.selectedObject == zoneMesh) {
          this.selectedObject['lastUsedMaterial'].color = new THREE.Color(change.color);
        } else {
          zoneMesh.material.color = new THREE.Color(change.color);
          
        }
      }

    }
  }




}
