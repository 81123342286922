import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { Note } from '../../models/note.model';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notes-viewer',
  templateUrl: './notes-viewer.component.html',
  styleUrls: ['./notes-viewer.component.scss']
})
export class NotesViewerComponent implements OnInit, OnDestroy {

  allowDrag = false;
  lastMovedPath = null;
  notes: Note[] = [];
  openNoteId: String = null;
  subscriptions: Subscription[] = [];
  constructor(private dataService: DataService, private appActionsService: AppActionsService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscriptions.push(this.dataService.notesDataFetched.subscribe(notes => {
      this.notes = notes;
    }))

    this.subscriptions.push(this.appActionsService.openNote.subscribe((note) => {
      if (note) {
        this.openNoteId = note.id;
      } else {
        this.openNoteId = '';
      }
     
    }))

    this.subscriptions.push(this.appActionsService.buttonEditorOpened.subscribe( ()=> {
      this.allowDrag = true;
      this.cdr.detectChanges();
    }))

    this.subscriptions.push(this.appActionsService.closeButtonEditor.subscribe( ()=> {
      this.allowDrag = false;
      this.cdr.detectChanges();
    }))



  }


  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }



  noteClickedOnList(note: Note) {
    this.appActionsService.openNote.next(note);
  }

  createNoteClicked() {

    this.dataService.createNote('New unpinned note', 'type your description here', 'none')
      .then(
        resolved => {

          this.appActionsService.openNote.next(resolved);




        },
        rejected => {
          console.log('note was not created!');


        }
      ).catch(err => { console.warn(err) })
  }


  dragReleased(note) {
    this.lastMovedPath.forEach(target => {


      if (target.id == "noteDropZone-button") {
        

        this.appActionsService.itemDroppedOnButton.next({type:'note', value:note.id})
      }
    })

    this.lastMovedPath = null;



  }

  dragMoved(e) {

    this.lastMovedPath = e.event.path
  }

 


}
