import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { AppActionsService } from '../../services/app-actions.service';
import { ToolboxEvent } from '../../../models/toolbox-event';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { ViewConfig } from 'src/models/viewConfig.model';

@Component({
  selector: 'app-pdf-compare-tool',
  templateUrl: './pdf-compare-tool.component.html',
  styleUrls: ['./pdf-compare-tool.component.scss']
})
export class PdfCompareToolComponent implements OnInit, OnDestroy {

  uploadImageForm: FormGroup;
  fileName = null;
  createImageError = null;
  images = [];
  subscriptions = [];
  firstLoad = true;
  fileTypeOk = false;
  inUpload = false;
  aspectRatio = null;

  uploadFormToggle = false;

  alreadyLoadedImagesIds = [];

  @ViewChild('uploadImageFile', { static: true }) uploadImageFile: ElementRef;
  @ViewChild('pdfViewer', { static: true }) pdfViewer: PdfJsViewerComponent;

  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {
    console.log('init ' + this.constructor.name)
    this.initCreateProjectForm()

    this.images = this.dataService.compareImages;
    this.sortImagesFromConfig();

    this.subscriptions.push(this.dataService.compareImageAdded.subscribe(image => { //this is required for generating the layer in threejs.
      console.log('pdf compare react to Add')
      //verify that this image is exisiting in the images array (and not have been removed previously.....), as we have infinite replaySubject... and we see also added subjects that have been removed later.. anyway we have a check for this aswell in threejs function... so we have double check in this situatuin
      if (this.images.indexOf(image) != -1) {
        this.appActionsService.toolboxEvents.next(new ToolboxEvent('pdfCompareMode', 'updates', this.constructor.name, { addNewImage: image.id }))
      }


    }))



    this.subscriptions.push(this.dataService.compareImageRemoved.subscribe(id => {
      console.log('pdf compare react to remove');


      this.appActionsService.toolboxEvents.next(new ToolboxEvent('pdfCompareMode', 'updates', this.constructor.name, { imageDeleted: id }))



    }))

    this.subscriptions.push(this.dataService.compareImageChanged.subscribe(image => {
      console.log('pdf compare react to change')

      this.updateImageProperties(image)
    }))

    this.subscriptions.push(
      this.appActionsService.loadViewConfig.subscribe(viewConfig => {
       

        let config = {};

        if (viewConfig) {
          if (viewConfig.pdfCompareMode) {
           config = viewConfig.pdfCompareMode;
  
          }
  
        }





        for (let image of this.images) {

          if (config[image.id]) {
            image.viewConfig.opacity = config[image.id].opacity;
            image.viewConfig.visible = config[image.id].visible;
          } else {

            image.viewConfig.visible = false;
          }




          this.updateImageProperties(image)
        }



        this.sortImagesFromConfig()
      })

    )

  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  initCreateProjectForm() {
    this.uploadImageForm = new FormGroup({
      'imageName': new FormControl(null, Validators.required),
      "imageFile": new FormControl(null, [Validators.required, this.fileTypeVerify.bind(this)])
    })

    this.fileName = null;
  }

  uploadClicked() {
    if (this.uploadImageForm.invalid) {
      return;
    }
    this.inUpload = true;
    this.createImageError = null;

    let name = this.uploadImageForm.controls.imageName.value;
    let file = this.uploadImageFile.nativeElement.files[0];

    if (file.type == 'application/pdf') {

      this.convertPdfToImageBlob(file).then(
        convertedImage => {
          this.getAspectRatio(convertedImage).then(
            ratio => {


              this.upload(convertedImage, name, ratio)
            },
            rejected => {

              this.upload(convertedImage, name, 1)
            }
          )

        },
        rejected => {
          this.createImageError = rejected;
          this.inUpload = false;
        }
      )

    } else {
      this.upload(file, name, this.aspectRatio)
    }




  }

  upload(file, name, aspectRatio) {

    this.dataService.uploadImageToFirebase(file, name, aspectRatio).then(
      resolved => {
        console.log('resolved')
        this.uploadFormToggle = false;
        this.uploadImageForm.reset();
        this.fileName = null;
        this.inUpload = false;
      },
      rejected => {
        this.createImageError = rejected;
        this.inUpload = false;
      }
    )
  }

  onImageSelected() {

    if (this.uploadImageFile.nativeElement.files.length > 0) {
      this.fileName = this.uploadImageFile.nativeElement.files[0].name;

      //check aspectratio:
      this.getAspectRatio(this.uploadImageFile.nativeElement.files[0]).then(ratio => {
        this.aspectRatio = ratio;

      })



    }

    else {
      this.fileName = null;
    }
  }

  getAspectRatio(file) {
    return new Promise<any>((resolve, reject) => {
      let img = new Image();
      img.onload = function () {

        resolve(img.width / img.height);
      };
      img.src = URL.createObjectURL(file);
    }).catch(err => {
      console.warn(err);
    })

  }

  onSizeInput(dim, image) {
    console.log(image.lockAspectRatio, image.width, image.height)
    if (image.lockAspectRatio) {
      if (dim == 'height') {

        image.width = (image.height * image.aspectRatio).toFixed(2);

      }

      if (dim == 'width') {
        image.height = (image.width / image.aspectRatio).toFixed(2);
      }
    }

    this.updateImageProperties(image, true)

  }

  lockAspectRatioClicked(image) {
    image.lockAspectRatio = !image.lockAspectRatio;
    this.onSizeInput('height', image);
  }

  fileTypeVerify(control: FormControl): { [s: string]: boolean } {

    let allowedFileTypes = [
      'image/png',
      'image/jpeg',
      'image/gif',
      'application/pdf'
    ]

    if (this.uploadImageFile.nativeElement.files[0]) {

      if (allowedFileTypes.indexOf(this.uploadImageFile.nativeElement.files[0].type) == -1) {

        return { 'fileTypeNotAllowed': true };
      }
    }



    return null;

  }

  toggleVisibility(image, event) {
    event.stopPropagation()
    image.viewConfig.visible = !image.viewConfig.visible;
    this.updateImageProperties(image)



    if (this.dataService.viewConfig.pdfCompareMode[image.id] == null) {
      this.dataService.viewConfig.pdfCompareMode[image.id] = {};
    }

    this.dataService.viewConfig.pdfCompareMode[image.id]['visible'] = image.viewConfig.visible;





  }

  changeOpacity(image, opacity) {
    image.viewConfig.opacity = opacity;
    this.updateImageProperties(image)

    if (this.dataService.viewConfig.pdfCompareMode[image.id] == null) {
      this.dataService.viewConfig.pdfCompareMode[image.id] = {};
    }

    this.dataService.viewConfig.pdfCompareMode[image.id]['opacity'] = image.viewConfig.opacity;




  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    let layersOrderById = [];
    this.images.forEach(image => {
      layersOrderById.push(image.id)
    })
    this.dataService.viewConfig.pdfCompareMode['layersOrderById'] = layersOrderById;

    this.appActionsService.toolboxEvents.next(new ToolboxEvent('pdfCompareMode', 'updates', this.constructor.name, { updateLayersOrder: true }))
  }

  updateImageProperties(image, updateInFirebase?) {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('pdfCompareMode', 'updates', this.constructor.name, { updateImage: image.id })) // This cause double update on threejs when changing in client side (becuase it update threejs, and then changing in firebase, then, oursubscribtion to change update it again( this time without 'true' on the 2nd option variable "updateInFirebase" , hence *NOT* creating an endless loop). its not a big deal though..

    if (updateInFirebase) {
      this.dataService.updateImageDataInFirebase(image);
    }
  }

  deleteImage(image) {
    let idToRemove = image.id;
    this.dataService.removeCompareImage(image);

  }







  sortImagesFromConfig() {




    if (this.dataService.viewConfig['pdfCompareMode']['layersOrderById']) {
      let sortingArr = this.dataService.viewConfig['pdfCompareMode']['layersOrderById'];
      //now add missing items to sorting array : 

      this.images.forEach(image => {
        if (sortingArr.indexOf(image.id) == -1) {
          sortingArr.push(image.id)
        }
      })


      this.images.sort(function (a, b) {
        if (sortingArr.indexOf(a.id) > sortingArr.indexOf(b.id)) {
          return 1;
        } else {
          return -1;
        }

      });


    }

    this.appActionsService.toolboxEvents.next(new ToolboxEvent('pdfCompareMode', 'updates', this.constructor.name, { updateLayersOrder: true }))

  }

  convertPdfToImageBlob(pdfFile) {

    return new Promise<any>((resolve, reject) => {

      this.pdfViewer.pdfSrc = pdfFile.slice();
      this.pdfViewer.zoom = "100"
      this.pdfViewer.refresh();


      let T = 0;
      let ivl = setInterval(() => {

        T++;


        let iframe = this.pdfViewer.iframe.nativeElement;
        let pageCanvas: HTMLCanvasElement = iframe.contentWindow.document.getElementById('page1');
        if (pageCanvas) {
          pageCanvas.toBlob(blob => {

            clearInterval(ivl)
            let image = new File([blob], 'converteFromPdf.png', { type: 'image/png' });

            console.log(image)
            resolve(image)
            return;
          });
        }



        if (T == 40) {
          clearInterval(ivl)
          reject('PDF Convertion timeout');
          return;
        }

      }, 1000)



    })


  }






}
