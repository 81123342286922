export class Tictac {
    public t0: number;
    public name: string;
 


    constructor(name:string){
      this.t0 =  performance.now();
      this.name = name;
    }

   
    getPassedTime():number {
      return (performance.now()-this.t0)
    }

    logPassedTime() {
    
      console.log(this.name + 'was running for '+ (performance.now()-this.t0) +'[ms]') 
    }
  

   
}

