import { Component, OnInit, Input } from '@angular/core';
import { NoteComment } from '../../../../models/note-comment.model';
import { DataService } from '../../../services/data.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  @Input('comment') comment: NoteComment;
  userAsync:Observable<any>;

  constructor(private dataService:DataService) { }

  ngOnInit() {
    this.dataService.getUserById(this.comment.createdBy).then(user =>{
      this.userAsync = of(user);
    })

    
  }

  deleteComment() {
    console.log(this.comment)
    this.dataService.deleteComment(this.comment.noteId,this.comment.id)
  }

}
