import { Component, Input, OnInit } from '@angular/core';
import { AppActionsService } from 'src/app/services/app-actions.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-photospheres-labels',
  templateUrl: './photospheres-labels.component.html',
  styleUrls: ['./photospheres-labels.component.scss']
})
export class PhotospheresLabelsComponent implements OnInit {
  @Input('photosphereLabels') photosphereLabels: any[];


  constructor(public dataService : DataService, private appAction: AppActionsService) { }

  ngOnInit() {

  }




  ngOnChanges(c) {
  
  }

  photosphereLabelClicked(id) {
    this.appAction.photsphereLabelClicked.next(id)
  }
}
