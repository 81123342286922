import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';


@Component({
  selector: 'app-view-menu',
  templateUrl: './view-menu.component.html',
  styleUrls: ['./view-menu.component.scss']
})
export class ViewMenuComponent implements OnInit {

  @Input('view') view;
  mobileMenuOn = false;
  toggleControl = {}

  navMenuActiveBtn = 0;
  navMenuLength = 0;

  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {

  }

  mainButtonClicked(button) {
    this.toggleButton(button.id)
    this.runButtonOnClick(button)

  }

  subButtonClicked(button) {
    this.runButtonOnClick(button)
  }

  toggleButton(id) {
    this.toggleControl[id] = !this.toggleControl[id];
  }

  runButtonOnClick(button) {
    this.appActionsService.openNote.next(); //close open note if exist


    if (button.onClick) {
      let action = button.onClick.action;
      let value = button.onClick.value;

      if (action) {
        switch (action) {
          case 'loadPhoto':
            let photo = this.dataService.photos[value];
            if (photo) {
              this.dataService.loadPhotoView(photo)
            }
            this.appActionsService.chosenObjectOid.next(null)


            break;
          case 'loadNote':
            let note = this.dataService.getNoteById(value)
            if (note) {
              this.appActionsService.openNote.next(note);
            }
            this.appActionsService.chosenObjectOid.next(null)
            break;

          case 'download':

            if (value) {
              this.downloadUrl(value);
            }

            break;

          case 'selectObject':

            if (value) {


              this.appActionsService.chosenObjectOid.next(this.dataService.getObjectIdOfGuid(value));

            }

            break;

          case 'toggleViewGallery':
            this.appActionsService.viewGalleryOn = !this.appActionsService.viewGalleryOn;
            break;

          case 'downloadIFC':
            this.dataService.getProjectIFCUrl(this.dataService.project.daeFileName).then(url => {
              this.downloadUrl(url)
            })

            break;

          case 'downloadDAE':
            this.dataService.getProjectModelUrl(this.dataService.project.daeFileName).then(url => {
              this.downloadUrl(url)
            })
            break;

        }

        if (value) {
          this.openCloseMobileMenu('close')
        }

      }




    }




  }


  downloadUrl(dataurl) {
    console.log(dataurl)
    var a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute('target', '_blank');
    //a.setAttribute('download', 'test.txt')
    a.click();
  }

  openCloseMobileMenu(state) {
    if (state == 'close') {
      this.mobileMenuOn = false;
    }

    if (state == 'open') {
      this.mobileMenuOn = true;
    }
  }

  flatButtons(menu) {
    let buttons = [];
    for (let viewButton of menu) {
      buttons.push(viewButton);
      if (viewButton.children) {
        for (let child of viewButton.children) {
          buttons.push(child)
        }
      }

    }
    this.navMenuLength = buttons.length;
    // console.log(buttons)
    return buttons;
  }

  navButton(i) {
 
    this.navMenuActiveBtn += i;
    if(this.navMenuActiveBtn < 0 ) {
      this.navMenuActiveBtn = this.navMenuLength -1 +this.navMenuActiveBtn;
    }

    console.log( this.navMenuActiveBtn)
   this.runButtonOnClick(this.flatButtons(this.view.menu)[(this.navMenuActiveBtn % this.navMenuLength)])

  }



}
