import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { DataService } from '../services/data.service';

@Injectable()
export class ManagedInterceptor implements HttpInterceptor {

  constructor(private dataService: DataService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes("ifc2collada") || request.url.includes("firebase-access-api") || request.url.includes("bimshow.io/textures")) {
      
      return from( this.dataService.getToken()).pipe(
            switchMap (
              token => {
                  request = request.clone({
                      setHeaders: {
                        Authorization: 'Bearer '+token
                      }
                  });
               
                  return next.handle(request);
              }
            )
          );
    }
    else {
      return next.handle(request);
    }
  }
}
