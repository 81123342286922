import { Pipe, PipeTransform} from '@angular/core';
/*

*/
@Pipe({ name: 'valuesFromKeyValue',
pure:false })
export class ValuesFromKeyValue implements PipeTransform {

    transform(keyvalues:any[]): any {
        let values =[];
        if( keyvalues) {
            keyvalues.forEach(kv => {
                if (kv.value) {
                    values.push(kv.value)
                }
                else {
                    values.push(null)
                }
             
              })
        }
       

        return values;

    }
}