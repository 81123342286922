import { Component, OnInit, Input, ViewChild, Renderer } from '@angular/core';
import { DataService } from '../../services/data.service';

import { Note } from '../../../models/note.model';



@Component({
  selector: 'app-note-comments',
  templateUrl: './note-comments.component.html',
  styleUrls: ['./note-comments.component.scss']
})
export class NoteCommentsComponent implements OnInit {
  @Input('note') note: Note;
  @ViewChild('newCommentTextArea', { static: true }) newCommentTextArea;
  @Input ('comments') comments;
  @ViewChild('comentsList', { static: true}) commentsList;
  newComment: string;
  arrangedComments = [];

  constructor(private dataService: DataService, private renderer: Renderer) { }

  ngOnInit() {

    //  this.loadNoteComments();
    this.arrangeComments()


  }

  ngOnChanges() {
    console.log('changed')
    //  this.loadNoteComments();
     this.arrangeComments()
  }



  // loadNoteComments() {
  //   // this.dataService.getCommentsOfNote(this.noteId).then(
  //   //   resolved => {
  //   //     this.comments = resolved;
  //   //     console.log(resolved)
  //   //     this.arrangeComments();
  //   //   },
  //   //   rejected => {
  //   //     console.log(rejected)
  //   //   }
  //   // ).catch(err => {
  //   //   console.warn(err)
  //   // })
    
  // }

  arrangeComments() {
   this.arrangedComments = [];
    for(let key in this.comments) {
      console.log()
      this.comments[key]['id'] = key;
      this.arrangedComments.push( this.comments[key]);

    }

    this.arrangedComments.reverse();
  
  }

  sendCommentClicked() {



    this.dataService.addNewNoteComment(this.newComment, this.note.id).then(res => {console.log(res)});
    this.newComment = '';


  }



}
