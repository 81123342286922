import { Component, OnInit, Input, OnChanges, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { Observable, of, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit, OnChanges, OnDestroy {

  userAsync: Observable<any>;
  photo = null;
  @ViewChild('photoTitle', { static: false }) photoTitle: ElementRef;
  @ViewChild('photoDescription', { static: false }) photoDescription: ElementRef;
  showPhotoBig = false;
  subscriptions: Subscription[] = [];

  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {
    console.log('oninit')
    this.subscriptions.push(this.appActionsService.openPhoto.subscribe(photo => {
      if (this.photo) {

        this.close();

      }

      if (photo) {

        this.loadPhoto(photo);
      }


    }))



  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  ngOnChanges() {

  }

  loadPhoto(photo) {

    this.photo = photo;


    this.dataService.getPhotoData(photo.id).then(data => {
      this.photo['data'] = data;
    })
    console.log(photo)
    this.dataService.getUserById(photo.createdBy).then(user => {
      this.userAsync = of(user);
    })
  }


  inputKeyDown(e) {
    console.log('a')
    return e.which != 13;

  }

  photoTitleBlured() {

    if (this.photoTitle.nativeElement.innerText == '') {

      this.photoTitle.nativeElement.innerText = 'Untitled Photo'
    }
    let oldText = this.photo.title;
    this.dataService.updatePhotoDetails(this.photo.id, { title: this.photoTitle.nativeElement.innerText }).catch(err => {
      console.warn('photo update failed', err.message)
      this.photoTitle.nativeElement.innerText = oldText;
    })

  }

  photoDescriptionBlured() {
    if (this.photoDescription.nativeElement.innerText == '') {

      this.photoDescription.nativeElement.innerText = 'No Description'
    }

    let oldText = this.photo.description;
    this.dataService.updatePhotoDetails(this.photo.id, { description: this.photoDescription.nativeElement.innerText }).catch(err => {
      console.warn('photo update failed', err.message)
      this.photoDescription.nativeElement.innerText = oldText;
    })

  }

  onCloseClicked() {
    this.appActionsService.openPhoto.next(null);
  }

  close() {
    this.userAsync = null;
    this.photo = null;
  }

  togglePhotoBig() {
    this.showPhotoBig = !this.showPhotoBig
  }



  onDeleteClicked() {
    this.dataService.deletePhoto(this.photo).then(()=>{
          
    })
    this.onCloseClicked();
  }

  loadPhotoViewClicked() {

    this.dataService.loadPhotoView(this.photo);

  }

}
