import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { AppActionsService } from '../../../services/app-actions.service';
import { Subscription } from 'rxjs';
import { ToolboxEvent } from 'src/models/toolbox-event';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnDestroy {

  showUsersPhotos = true;
  photos = [];
  selectedPhotoId = null;
  lastMovedPath = null;
  openNoteId = null;
  linkedButtons = {};
  initialPhotoId = null;

  subscriptions: Subscription[] = [];

  constructor(private dataService: DataService, private appActionsService: AppActionsService) { }

  ngOnInit() {
    this.subscriptions.push(this.appActionsService.openNote.subscribe(note => {
      if (note) {
        this.openNoteId = note.id;
      } else {
        this.openNoteId = null;
      }

    }))


    this.subscriptions.push(this.dataService.photosDataFetched.subscribe(photos => {
      this.photos = photos;
      this.updateLinkedButtons()
    }))

    this.subscriptions.push(this.appActionsService.openPhoto.subscribe(photo => {

      if (photo) {
        this.selectedPhotoId = photo.id;
      } else {
        this.selectedPhotoId = null;
      }

    }))

    this.subscriptions.push(this.appActionsService.checkPhotosLinkedToButton.subscribe(()=>{
      this.updateLinkedButtons()
    }))
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  showUsersPhotoChanged(event) {

    this.showUsersPhotos = !event.checked;
  }

  openPhoto(photo) {

    if (photo.id == this.selectedPhotoId) {
      return;
    }

    this.appActionsService.openPhoto.next(photo);

  }

  loadPhotoView(photo) {
    this.dataService.loadPhotoView(photo);
  }

  refreshPhotoView(photoId) {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('gallery', 'updates', this.constructor.name, { refreshPhoto: photoId }))
  }



  dragReleased(photo) {
    if (this.lastMovedPath == null) {
      return;
    }
    this.lastMovedPath.forEach(target => {

      if (target.id == "thumbsList-note") {
        this.dataService.relatePhotoToNote(this.openNoteId, photo.id);
        console.log('relat')

      }

      if (target.id == "photoDropZone-button") {


        this.appActionsService.itemDroppedOnButton.next({ type: 'photo', value: photo.id })
      }

      if (target.id == "photoDropZone-view") {


        this.appActionsService.photoDroppedOnView.next(photo.id)
      }

    })


    this.lastMovedPath = null;



  }

  dragMoved(e) {

    this.lastMovedPath = e.event.path
  }

  close() {
    this.appActionsService.galleryIsOpen = !this.appActionsService.galleryIsOpen;
  }

  updateLinkedButtons() {
    console.log('getting linked')
    this.linkedButtons = {};
  
    for (let button of this.dataService.loadedView.menu) {
      console.log(button)
      if (button.onClick.action == "loadPhoto") {
  
        this.linkedButtons[button.onClick.value] = button.id
      }

      if(button.children) {
        for (let childButton of button.children) {
          if (childButton.onClick.action == "loadPhoto") {
        
            this.linkedButtons[childButton.onClick.value] = childButton.id
          }
    
    
        }
      }

     
    }
   
    this.initialPhotoId = this.dataService.loadedView.initialPhoto;

  }


}
