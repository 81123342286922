export class ToolboxEvent {
    public tool: string;
    public type: string;
    public updates: any;
    public emitter: string;


    constructor(tool,type,emitter,updates?){
     this.tool = tool;
     this.type = type;
     this.emitter = emitter;
     if (updates) {
        this.updates  = updates;
     }
     

       
    }


}

