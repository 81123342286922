import { Component, OnInit, Renderer2, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { ToolboxEvent } from '../../models/toolbox-event';
import { DataService } from '../services/data.service';

import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as tilebelt from '@mapbox/tilebelt';
import * as png from '@vivaxy/png';




declare var mapboxgl: any;
declare var THREE: any;

@Component({
  selector: 'app-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss']
})
export class MapboxComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @ViewChild('mapboxContainer', { static: true }) mapboxContainer: ElementRef;
  map = null;
  currentZoom = null;
  objectLoaded = false;
  transform = {};
  renderAction = { dontRender: true }
  @ViewChild('rendererContainer', { static: true }) rendererContainer: ElementRef;

  siteZero = null;

  lastFetchedElevation = {
    lat: null,
    lng: null,
    elevation: null
  }

  

  constructor(private appActionsService: AppActionsService, private domRenderer: Renderer2, private dataService: DataService, private http: HttpClient) { }

  ngOnInit() {
    console.log('mapbox component init')

    this.initMapbox();



  }

  ngOnDestroy() {
    console.log('mapbox compoenent destroyed')
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  initMapbox() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYXNoa2VuYXppb3IiLCJhIjoiY2pveHhvcmhkMjVvMDN3cGg4eGtneWhxMSJ9.3pFGOEZUl_n-xkVNkS1kFg';
    this.map = new mapboxgl.Map({
      container: 'mapboxContainer', // container id
      style: 'mapbox://styles/ashkenazior/cjysbd07d0fdx1coc71jxlew8', // stylesheet location
      center: [55.5, -21.3], // starting position [lng, lat]
      zoom: 22, // starting zoom
      antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased, 
      preserveDrawingBuffer: true
    });
    console.log('initing mapbox')
    this.map.on('load', () => {

      this.initTerrain()

      this.subscriptions.push(
        this.appActionsService.rerenderOnMapbox.subscribe(() => {
          this.map.triggerRepaint();
     
        })
      )

      this.subscriptions.push(this.appActionsService.materialsUpdated.subscribe(() => {
        this.map.triggerRepaint();
       
      }))

      this.subscriptions.push(
        this.appActionsService.project3dObjectGenerated.subscribe(() => {

          this.add3dObject();


        })
      )
      this.subscriptions.push(
        this.appActionsService.toolboxEvents.subscribe(event => {
          

          if (event.tool == 'placeOnMapMode' && event.emitter != this.constructor.name) {
            console.log('reacting to subscribtion emitted by other compoenent', event)
            
            if (event.type == 'updates') {


              if (event.updates['openMapbox'] != false  && !event.updates['flyTo'] ) { //also on openMapbox, and also on normal update
               
            
                const updates= {};
                if (event.updates['zoom']) {
        
                  updates['zoom'] = Number(event.updates['zoom'])
                 
                }
                if (event.updates['target']) {
           
                  updates['center'] = [event.updates['target'].long, event.updates['target'].lat]
                }

                if (event.updates['compassAngle'] != null) {
                  updates['bearing'] = -event.updates['compassAngle'];
               
                }

                if (event.updates['pitch'] != null) {
             
                  updates['pitch'] = event.updates['pitch'];
                }
                
                console.log(updates)
                this.map.jumpTo(updates);




              }

              if (event.updates['openMapbox'] == true) {
                this.domRenderer.addClass(this.mapboxContainer.nativeElement, 'on');
                this.domRenderer.addClass(this.rendererContainer.nativeElement, 'on');
                this.renderAction.dontRender = false;
                this.map.resize();

                if (event.updates['mapboxMode'] != null) {

                  switch (event.updates['mapboxMode']) {

                    case ('sat'):
                      this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'visible');
                      break;
                    default:
                      this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
                      break;

                  }



                }
              }




              if (event.updates['openMapbox'] == false) {

                this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
                let cp = {
                  compassAngle: -this.map.getBearing(),
                  pitch: this.map.getPitch(),
                  zoom: this.map.getZoom(),
                  center: {
                    lat: this.map.getCenter().lat,
                    long: this.map.getCenter().lng
                  }
                }
                console.log('open mapbox false', cp)


                this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { cameraProperties: cp }))
                this.domRenderer.removeClass(this.mapboxContainer.nativeElement, 'on');
                this.domRenderer.removeClass(this.rendererContainer.nativeElement, 'on');
                this.renderAction.dontRender = true;
              }

              if (event.updates['resetNorth']) {
                this.map.resetNorth();
              }

              if (event.updates['zoomInOut']) {
                if (event.updates['zoomInOut'] > 0) {
                  this.map.zoomIn();
                } else {
                  this.map.zoomOut();
                }
              }

              if (event.updates['flyTo']) {
             
                this.map.flyTo({
                  center: [event.updates['flyTo'].target.long, event.updates['flyTo'].target.lat],
                  speed: 0.5,
                  zoom: event.updates['flyTo'].zoom ? event.updates['flyTo'].zoom : this.map.getZoom()
                });
              }



              if (event.updates['siteProperties']) {
                console.log('mapbox componment react to sitePropreties update')
                console.log(event.updates['siteProperties'])

                this.siteZero = [event.updates['siteProperties'].longitude, event.updates['siteProperties'].latitude]


                this.updateTransform(event.updates['siteProperties'].latitude, event.updates['siteProperties'].longitude, event.updates['siteProperties'].offsetX, event.updates['siteProperties'].offsetY, event.updates['siteProperties'].offsetZ, event.updates['siteProperties'].rotate)
                this.updateAllLayers();

              }



            }
          }


          if ((event.tool == 'pdfCompareMode') && (event.emitter != this.constructor.name)) {

            if (event.type == 'updates') {
              if (event.updates.updateImage) {

                this.updateImageLayer(event.updates.updateImage);
              }

              if (event.updates.updateLayersOrder) {
                this.orderLayers();
              }

              if (event.updates.addNewImage) {


                this.generateImageLayer(event.updates.addNewImage)
                this.orderLayers();

                this.updateImageLayer(event.updates.addNewImage)





              }




              if (event.updates.imageDeleted) {
                this.deleteImageLayer(event.updates.imageDeleted);
              }
            }
          }

        })
      )
    })





    this.map.on('move', () => {
      console.log(this.map.getZoom())
      let cp = {
        compassAngle: -this.map.getBearing(),
        pitch: this.map.getPitch(),
        zoom: this.map.getZoom(),
        center: {
          lat: this.map.getCenter().lat,
          long: this.map.getCenter().lng
        }
      }

      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { cameraProperties: cp }))
    })

    this.map.on('dblclick', (e) => {

      this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { LLFromDblClick: { lon: e.lngLat.lng, lat: e.lngLat.lat } }))
    });
  }


  async updateTransform(Lat, Lon, offsetX, offsetY, offsetZ, rotate) {
    console.log(this.map.getZoom())

    var modelOrigin = [Lon, Lat];
    var modelAltitude = 0;


    var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
    var meter = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();

    let z = 0;
    if (this.lastFetchedElevation.lat != Lat || this.lastFetchedElevation.lng != Lon) {
      console.log('getting elevation')
      this.lastFetchedElevation.elevation = await this.getElevation(Lon, Lat);
      this.lastFetchedElevation.lat = Lat;
      this.lastFetchedElevation.lng = Lon;

    }


    this.transform['translateX'] = modelAsMercatorCoordinate.x + offsetX * meter
    this.transform['translateY'] = modelAsMercatorCoordinate.y - offsetY * meter;
    this.transform['translateZ'] = (this.lastFetchedElevation.elevation + offsetZ) * meter;
    // this.lastFetchedElevation.elevation + offsetZ;
    this.transform['rotateX'] = Math.PI / 2;
    this.transform['rotateY'] = Math.PI * rotate / 180;
    this.transform['rotateZ'] = 0;

    this.transform['scale'] = meter;
    this.map.triggerRepaint();
    console.log('triggerRepaint')

  }

  add3dObject() {
    console.log('add3dobject rus')


    let addObject = (id) => {
      //getting object and manipulating;
      let object = this.dataService.project3dObject;
      console.log(object)

      let rendererContainer = this.rendererContainer;
      let renderAction = this.renderAction;

      object.rotateX(-Math.PI / 2)
      this.dataService.getZones().forEach(zone => {
        let zoneMesh = object.getObjectByName(zone.guid);
        if (zoneMesh) {

          zoneMesh.visible = false;


        } else {
          console.warn("zone '" + zone.name + "' not found in DAE model")
        }

      })


      //this is just init data.. it will update according to project data...
      var modelOrigin = [55.455, -20.888];
      var modelAltitude = 0;
      var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
      var meter = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();


      var initTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: 0,
        rotateX: Math.PI / 2,
        rotateY: 0,
        rotateZ: 0,
        scale: meter
      }




      var transform: any = initTransform;

      if (this.transform == {}) {
        this.transform = transform;
      } else {
        transform = this.transform
      }




      // Create the Mapbox Custom Layer object
      // See 
      var map = this.map;
      var threeJSModel = {
        map: map,
        id: id,
        type: 'custom',
        onAdd: function (map, gl) {
          this.camera = new THREE.Camera();


          this.scene = new THREE.Scene();

          var ambientLight = new THREE.AmbientLight(0xcccccc, 0.8);
          this.scene.add(ambientLight);



          for (let i = 0; i < 6; i++) {
            let deg = 180 * (1 + i) / 9
            let dl = new THREE.DirectionalLight(0xffffff, 0.1 + 0.05 * Math.sin(Math.PI * (deg / 180)))
            dl.position.set(Math.cos(Math.PI * (deg / 180)), Math.sin(Math.PI * (deg / 180)), 1)
            this.scene.add(dl)

          }






          this.scene.add(object);
          this.map = map;

          this.renderer = new THREE.WebGLRenderer({
            // canvas: map.getCanvas(),
            // context: gl,
            antialias: true,
            alpha: true
          });

          rendererContainer.nativeElement.appendChild(this.renderer.domElement);


        },
        render: function (gl, matrix) {

          if (renderAction.dontRender) {

            return;

          }



          var rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), transform.rotateX);
          var rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), transform.rotateY);
          var rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), transform.rotateZ);

          var m = new THREE.Matrix4().fromArray(matrix);
          var l = new THREE.Matrix4().makeTranslation(transform.translateX, transform.translateY, transform.translateZ)
            .scale(new THREE.Vector3(transform.scale, -transform.scale, transform.scale))
            .multiply(rotationX)
            .multiply(rotationY)
            .multiply(rotationZ);

          this.camera.projectionMatrix.elements = matrix;
          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.state.reset();


          let width = rendererContainer.nativeElement.clientWidth;
          let height = rendererContainer.nativeElement.clientHeight;

          this.renderer.setSize(width, height)


          // console.log('mapbox renders')


          this.renderer.render(this.scene, this.camera);



        },
        takePhoto: function () {

          let cloneCanvas = (oldCanvas) => {

            //create a new canvas
            var newCanvas = document.createElement('canvas');
            var context = newCanvas.getContext('2d');

            //set dimensions
            newCanvas.width = oldCanvas.width;
            newCanvas.height = oldCanvas.height;

            //apply the old canvas to the new one
            context.drawImage(oldCanvas, 0, 0);

            //return the new canvas
            return newCanvas;
          }

          let addToCanvas = (oldCanvas, toAdd) => {
            oldCanvas.getContext('2d').drawImage(toAdd, 0, 0)
          }

          let newC = cloneCanvas(this.map.getCanvas());
          this.renderer.render(this.scene, this.camera)
          addToCanvas(newC, this.renderer.domElement)

          // let imgData = this.renderer.domElement.toDataURL();
          let imgData = newC.toDataURL();
          return imgData;

        }
      }

      this.appActionsService.mapboxThreejsModel = threeJSModel

      this.map.addLayer(threeJSModel);




      this.objectLoaded = true;



    }








    addObject('project3dObject')

  }



  initTerrain() {

    this.map.addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
      'maxzoom': 14
    });
    // add the DEM source as a terrain layer with exaggerated height

    this.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1 });

    // add a sky layer that will show when the map is highly pitched

    this.map.addLayer({
      'id': 'sky',
      'type': 'sky',
      'paint': {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15
      }
    });
  }

  getImage(imageId) {
    let image = null;
    for (let existingImage of this.dataService.compareImages) {
      if (existingImage.id == imageId) {
        image = existingImage;
      }
    }

    return image;
  }

  generateImageLayer(imageId) {
    let image = this.getImage(imageId)

    this.map.addSource(image.id, {
      "type": "image",
      "url": image.url,
      "coordinates": [
        [55.6, -21.1],
        [55.7, -21.1],
        [55.7, -21.2],
        [55.6, -21.2]
      ]
    });

    this.map.addLayer({
      "id": image.id,
      "source": image.id,
      "type": "raster",
      "paint": {
        "raster-opacity": image.viewConfig.opacity
      }
    });

  }

  deleteImageLayer(id) {
    if (this.map.getLayer(id)) { this.map.removeLayer(id); }
  }

  updateImageLayer(imageId) {


    let image = this.getImage(imageId);
    let imageLayer = this.map.getLayer(imageId);

    if ((image == null) || (imageLayer == null)) {
      console.log('*no-image* error')
      return;
    }





    if (image.viewConfig.opacity != null) {

      this.map.setPaintProperty(imageId, 'raster-opacity', image.viewConfig.opacity);


    }

    if (image.viewConfig.visible != null) {

      this.map.setLayoutProperty(imageId, 'visibility', image.viewConfig.visible ? 'visible' : 'none');
    }



    let o = this.siteZero;
    if ((image.longitude != null) && (image.latitude != null)) {

      let o = [image.longitude, image.latitude];


    }

    let o_xy = mapboxgl.MercatorCoordinate.fromLngLat({ lng: o[0], lat: o[1] }, 0);
    let meter = o_xy.meterInMercatorCoordinateUnits();


    let offsetXinMercCoordinates = (image.offsetX ? image.offsetX : 0) * meter;
    let offsetYinMercCoordinates = (image.offsetY ? image.offsetY : 0) * meter;

    //rotating..

    let A = [-image.width / 2, image.height / 2];
    let B = [image.width / 2, image.height / 2];
    let C = [image.width / 2, -image.height / 2];
    let D = [-image.width / 2, -image.height / 2];

    let angle = - (image.rotation ? image.rotation : 0) * Math.PI / 180;

    let A_rotated = [Math.cos(angle) * A[0] - Math.sin(angle) * A[1], Math.sin(angle) * A[0] + Math.cos(angle) * A[1]];
    let B_rotated = [Math.cos(angle) * B[0] - Math.sin(angle) * B[1], Math.sin(angle) * B[0] + Math.cos(angle) * B[1]];
    let C_rotated = [Math.cos(angle) * C[0] - Math.sin(angle) * C[1], Math.sin(angle) * C[0] + Math.cos(angle) * C[1]];
    let D_rotated = [Math.cos(angle) * D[0] - Math.sin(angle) * D[1], Math.sin(angle) * D[0] + Math.cos(angle) * D[1]];

    let A_Merc = new mapboxgl.MercatorCoordinate(o_xy.x + A_rotated[0] * meter + offsetXinMercCoordinates, o_xy.y - A_rotated[1] * meter - offsetYinMercCoordinates, 0);
    let B_Merc = new mapboxgl.MercatorCoordinate(o_xy.x + B_rotated[0] * meter + offsetXinMercCoordinates, o_xy.y - B_rotated[1] * meter - offsetYinMercCoordinates, 0);
    let C_Merc = new mapboxgl.MercatorCoordinate(o_xy.x + C_rotated[0] * meter + offsetXinMercCoordinates, o_xy.y - C_rotated[1] * meter - offsetYinMercCoordinates, 0);
    let D_Merc = new mapboxgl.MercatorCoordinate(o_xy.x + D_rotated[0] * meter + offsetXinMercCoordinates, o_xy.y - D_rotated[1] * meter - offsetYinMercCoordinates, 0);

    this.map.getSource(imageId).setCoordinates(


      [

        A_Merc.toLngLat(),
        B_Merc.toLngLat(),
        C_Merc.toLngLat(),
        D_Merc.toLngLat()


      ]


    );





  }

  updateAllLayers() {
    this.dataService.compareImages.forEach(image => {
      this.updateImageLayer(image.id)
    })
  }

  orderLayers() {



    let layerOrder = [];
    if (this.dataService.viewConfig['pdfCompareMode']['layersOrderById']) {
      layerOrder = this.dataService.viewConfig['pdfCompareMode']['layersOrderById'];

    }

    this.dataService.compareImages.forEach(image => { //start pushing all ids that doesnt exist in config
      if (layerOrder.indexOf(image.id) == -1) {
        layerOrder.push(image.id);
      }

    })

    let lastId = null;
    layerOrder.forEach(id => { //now set renderOrder
      if (lastId == null) {
        lastId = id;
      } else {
        if (this.map.getLayer(id) && this.map.getLayer(lastId)) {
          this.map.moveLayer(id, lastId);
          lastId = id;

        }
      }


    })
  }

  getElevation(lng, lat) {

    return new Promise<number>((resolve, reject) => {



      let zoom = 22;
      let tile = tilebelt.pointToTile(lng, lat, zoom);


      let url = "https://api.mapbox.com/v4/mapbox.terrain-rgb/" + zoom + "/" + tile[0] + "/" + tile[1] + ".pngraw?access_token=pk.eyJ1IjoiYXNoa2VuYXppb3IiLCJhIjoiY2pveHhvcmhkMjVvMDN3cGg4eGtneWhxMSJ9.3pFGOEZUl_n-xkVNkS1kFg";

      this.http.get(
        url,
        {
          responseType: 'blob'
        }
      ).subscribe(
        (data: any) => {
          data.arrayBuffer().then(buffer => {
            const metadata = png.decode(buffer);

            const height = -10000 + ((metadata.data[0] * 256 * 256 + metadata.data[1] * 256 + metadata.data[2]) * 0.1)
            resolve(height)
          })
        }
      )
    })

  }
}





