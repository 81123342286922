import { Pipe, PipeTransform } from '@angular/core';
/*

*/
@Pipe({ name: 'filterItemsByCreatedBy',
pure:false })
export class FilterItemsByCreatedBy implements PipeTransform {

    transform(items: any[], createdBy: string): any {
        return items.filter(item => item.createdBy == createdBy);

    }
}