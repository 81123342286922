import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {

  tip:{title:string, text:string} = null;
  subscriptions: Subscription[] = [];


  @ViewChild('toolTipContainer', { static: true }) toolTipContainer: ElementRef;

  constructor(private appActionsService: AppActionsService, private renderer: Renderer2) { }
  toggledOn: boolean = false;
  
  ngOnInit() {

    this.subscriptions.push(this.appActionsService.toolTipMessage.subscribe(tip=>{
      
      
      if (tip) {
        this.tip=tip;
        this.toggleOn()
       
      }

      else {
        this.toggleOff();
     
      }
     
      
    }))
  }

  ngOnDestroy() {
   
    this.subscriptions.forEach( sub =>{sub.unsubscribe();})
  }

  toggleOn() {
    this.toggledOn = true;
    this.renderer.addClass(this.toolTipContainer.nativeElement,'on')
  }

  toggleOff() {
    this.renderer.removeClass(this.toolTipContainer.nativeElement,'on')
    this.toggledOn= false;
    setTimeout(() => {
      if(!this.toggledOn) {
      
        this.tip = null;
      }
     
    }, 500);
  }

}
