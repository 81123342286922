import { UUID } from 'angular2-uuid';

export class Note {
    public id: string;
    public title:string;
    public description:string;
    public createdBy:string;
    public type:string;
    public scale;
    public position;
    public cameraParameters;
    public date:string;
    public comments: any;
    public relatedPhotos: any;


    constructor(title,description,uid,type,options?){
        
        this.id=null;
        this.title = title;
        this.description = description;
        this.date = new Date().toString();
        this.createdBy = uid;
        this.type = type;
        this.scale = null;
        this.position = null;
        this.cameraParameters = null;
    
        if (options) {
            if (options.position) {
                this.position = options.position;
            }
    
            if (options.cameraParameters) {
                this.cameraParameters = options.cameraParameters;
            }

            if (options.scale) {
                this.scale = options.scale;
            }
        }
        
        
       
    }



}
