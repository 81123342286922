import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppActionsService } from '../../services/app-actions.service';
import { ToolboxEvent } from '../../../models/toolbox-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-measure-mode-tool',
  templateUrl: './measure-mode-tool.component.html',
  styleUrls: ['./measure-mode-tool.component.scss']
})
export class MeasureModeToolComponent implements OnInit, OnDestroy {

  distance: number = null;
  pointA = null;
  pointB = null;
  subscriptions: Subscription[] = [];

  constructor(private appActionsService: AppActionsService) { }

  ngOnInit() {
    this.subscriptions.push(this.appActionsService.toolboxEvents.subscribe(event => {
      if (event.tool == 'measureMode') {
        if (event.type == 'open') {
          this.initTool();
        }

        if (event.type == 'close') {
          this.closeTool();
        }

        if (event.type == 'updates') {
          if (event.emitter != this.constructor.name) {

            this.distance = event.updates.distance;
            this.pointA = event.updates.pointA;
            this.pointB = event.updates.pointB;

          }
        }
      }
    }))
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  initTool() {
    this.distance = null;
    this.pointA = null;
    this.pointB = null;
  }

  closeTool() {

  }



  magentButtonToggled(e) {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('measureMode', 'updates', this.constructor.name, { magnetOn: e.checked }))
  }

}
