import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppActionsService } from '../../services/app-actions.service';
import { DataService } from '../../services/data.service';
import { ToolboxEvent } from '../../../models/toolbox-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-note-tool',
  templateUrl: './add-note-tool.component.html',
  styleUrls: ['./add-note-tool.component.scss']
})
export class AddNoteToolComponent implements OnInit, OnDestroy {

  size = 1;
  pinMode: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(private appActionsService: AppActionsService, private dataService: DataService) { }

  ngOnInit() {
    this.subscriptions.push(this.appActionsService.toolboxEvents.subscribe(event => {
      if (event.tool == 'addNote') {
        if (event.type == 'updates') {
          if (event.updates.pinMode) {
            this.pinMode = event.updates.pinMode;
          }

          if (this.size == event.updates.size) {
            this.size = event.updates.size;
          }
        }
      }

      if(event.tool == null) {
        this.close();
      }

      
    }))
  }


  ngOnDestroy() {
   
    this.subscriptions.forEach( sub =>{sub.unsubscribe();})
  }


  addPinnedNoteClicked() {

    this.pinMode =  !this.pinMode;
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('addNote', 'updates', this.constructor.name, { pinMode: this.pinMode, size: 5 }));
  }

  addUnpinneddNoteClicked() {
    
    this.dataService.createNote('New unpinned note', 'type your description here', 'none')
    .then(
      resolved => {
        console.log(resolved)
        this.appActionsService.openNote.next(resolved);
        this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));
        


      },
      rejected => {
        console.log('note was not created!');
        this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name));

      }
    ).catch(err => { console.warn(err) })
  }


  sizeChanged(e) {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('addNote', 'updates', this.constructor.name, { pinMode: this.pinMode, size: e.value }));

  }
  close() {
    this.pinMode = false;


  }

}
