import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, Inject, ViewChild, ElementRef } from '@angular/core';
import { AppActionsService } from '../services/app-actions.service';
import { DataService } from '../services/data.service';
import { Subscription, config } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { min } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
declare var THREE: any;

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-materials-editor',
  templateUrl: './materials-editor.component.html',
  styleUrls: ['./materials-editor.component.scss']

})
export class MaterialsEditorComponent implements OnInit, OnDestroy {

  lastMovedPath = null;
  materials: any[];
  @Input('model') model: any;
  @ViewChild('editButton', { static: false }) editButton: ElementRef;
  subscriptions: Subscription[] = [];

  waitingForTexture = null;

  configs = null;
  openedConfigKey = null;
  defaultConfig = null;
  configNameEditor = null;
  namesToKeys = {};


  constructor(private dataService: DataService, private appActionsService: AppActionsService, private http: HttpClient, private cdr: ChangeDetectorRef, public dialog: MatDialog) { }

  ngOnInit() {


    this.configs = this.dataService.materialsConfigs;


    this.subscriptions.push(this.appActionsService.materialsGenerated.subscribe(() => {

      this.materials = this.dataService.materials;
      console.log(this.materials.length)

      for (let material of this.materials) {

        material.color = material.material.color.getStyle();
        material.opacity = material.material.opacity;



      }


      console.log('init config')
      this.defaultConfig = this.initConfig();




    }))




    this.subscriptions.push(this.appActionsService.loadViewConfig.subscribe(viewConfig => {



      //console.log('react to loadViewConfig')
      if (viewConfig) {
        if (viewConfig.MaterialsMode) {
          if (viewConfig.MaterialsMode.openedConfigKey) {

            if (viewConfig.MaterialsMode.openedConfigKey != this.openedConfigKey) {

              this.selectConfig(viewConfig.MaterialsMode.openedConfigKey)



            }

            return;
          }

        }


      }


      //incase it didnt load config for some reason
      //console.log('loading default config??')

      this.loadDefaultConfig()




    }))


    this.subscriptions.push(this.dataService.materialsConfigsUpdated.subscribe((update) => { //need this to react to remote changes ( for when the client is a viewer.)
      if (update.type != 'child_changed') {
        return;
      }
      //console.log('lets react')
      // console.log(this.openedConfigKey, update.key)


      if (this.openedConfigKey == update.key) {


        //this.loadDefaultConfig(); 

        //this.selectConfig(update.key)
        this.openedConfigKey = update.key;
        this.loadConfig(this.configs[update.key])
      }


    }))

    this.subscriptions.push(
      this.appActionsService.objectDataAction.subscribe(e => {
        if (e.action == "materialClicked") {
          for (let mi of this.materials) {
            if (mi.material.name == e.materialName) {
              if (!mi.openInList) {
                mi['openInList'] = true;
              }
            } else {
              mi.openInList = false;
            }

          }
        }
        this.cdr.detectChanges();
        let domElement = document.getElementById('material_' + e.materialName);
        if (domElement) {
          setTimeout(() => {
            domElement.scrollIntoView(false)
          }, 20)

        }
      })
    )


    this.subscriptions.push(
      this.appActionsService.textureClicked.subscribe(texture => {

        this.onTextureClickedOnFolder(texture)
      })
    )

    this.subscriptions.push(
      this.appActionsService.textureFolderClosed.subscribe(() => {
        this.waitingForTexture = null;
      })
    )


  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }





  deleteConfigClicked(key) {
    if (this.openedConfigKey = key) {
      this.loadDefaultConfig();
    }
    this.dataService.removeMaterialConfig(key)
  }

  onInputKeyDown(e) {
    if (e.key === "Enter") {
      // Do work

      e.target.blur();
    }
  }

  materialConfigNameChanged(key, value) {

    this.configNameEditor = null;



    this.dataService.updateMaterialConfigName(key, value)
  }

  selectConfig(key) {

    if (this.openedConfigKey == key) {
      this.loadDefaultConfig();
    } else {
      this.openedConfigKey = key;
      this.loadConfig(this.configs[key])
    }

  }

  onConfigSelected(event) {
    if (event.value != null) {
      this.selectConfig(event.value)
    } else {
      this.loadDefaultConfig();
    }

  }

  loadDefaultConfig() {

    this.openedConfigKey = null;
    this.loadConfig(this.defaultConfig);

  }

  createNewConfig() {

    let newConfig = JSON.parse(JSON.stringify(this.defaultConfig));
    newConfig.name = 'Untitled Config';
    this.dataService.addNewMaterialConfig(newConfig).then((snapshot) => {

      this.selectConfig(snapshot.key)
    })
  }

  test(e) {

  }

  initConfig() { //Will work only first time, when materials are the original that threejs assigned to the dae model.
    let newConfig = { name: 'new config', materials: {} };
    for (let material of this.materials) {
      let id = UUID.UUID()
      newConfig.materials[id] = {
        name: material.material.name,
        texture: null,
        userName: material.material.userData.name,
        color: material.material.color.getStyle(),
        opacity: material.material.opacity,
        scale: 1
      }
    }
    console.log(newConfig)

    return newConfig;
  }

  saveOpenedConfigKeyToViewConfig() {
    this.dataService.viewConfig.MaterialsMode = { openedConfigKey: this.openedConfigKey }
  }

  saveToOpenedConfig(materialItem) {


    let update = {};

    update[this.namesToKeys[materialItem.material.name]] = {
      name: materialItem.material.name,
      textureId: materialItem.textureId ? materialItem.textureId : null,
      userName: materialItem.material.userData.name,
      color: materialItem.material.color.getStyle(),
      opacity: materialItem.opacity,
      scale: materialItem.scale
    }



    if (this.openedConfigKey) {
      this.dataService.updateMaterialInConfig(this.openedConfigKey, update);
    }




  }

  changeMaterialOpacity(materialItem, value, saveToConfig?) {
    materialItem.material.opacity = value;
    materialItem.material.transparent = value < 1 ? true : false;
    this.appActionsService.materialsUpdated.next();


    materialItem.opacity = value;


    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      this.saveToOpenedConfig(materialItem)

    }


  }

  changeMaterialOpacityForThreejsOnly(materialItem, value) {

    materialItem.material.opacity = value;
    materialItem.material.transparent = value < 1 ? true : false;
    this.appActionsService.materialsUpdated.next();
  }

  changeMaterialColor(materialItem, color) {

    materialItem.material.color.set(color)


    this.appActionsService.materialsUpdated.next();


  }



  changeScaleForThreejsOnly(materialItem, value) {

    console.log(materialItem)
    const texture = this.dataService.textures[materialItem.textureId];
    console.log(texture)
    if (texture) {
      const height = Number(texture.metadatas.height || 1);
      const width = Number(texture.metadatas.width || 1);

      materialItem.material.map.repeat.set(1 / (width * value), 1 / (height * value))
      this.appActionsService.materialsUpdated.next();
    }

  }

  changeScale(materialItem, scale, saveToConfig?) {
    console.log(scale)
    this.changeScaleForThreejsOnly(materialItem, scale)

    materialItem.scale = scale;

    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      console.log(materialItem)
      this.saveToOpenedConfig(materialItem)
    }
  }



  onTextureChosen(materialItem, textureId) {

    this.loadTexture(materialItem, textureId).then(() => {

      materialItem.scale = 1;
      this.changeMaterialColor(materialItem, 'white')

      this.saveToOpenedConfig(materialItem)

    })
  }



  loadTexture(materialItem, textureId) {



    return new Promise<any>((resolve, reject) => {
      const texture = this.dataService.textures[textureId];
      materialItem['textureId'] = textureId;
      if (texture == null) {
        reject();
        return
      }


      const height = Number(texture.metadatas.height || 1);
      const width = Number(texture.metadatas.width || 1);



      var loader = new THREE.TextureLoader();

      // load a resource
      loader.load(
        // resource URL
        texture.picture_download_url,


        // onLoad callback
        (texture) => {
          texture.wrapS = texture.wrapT = THREE.RepeatMirrorWrapping;
          texture.repeat.set(1 / width, 1 / height);
          materialItem.material.map = texture;

          // materialItem.material.color.set('white')
          materialItem.color = materialItem.material.color.getStyle();

          materialItem.material.transparent = true,

            materialItem.material.needsUpdate = true;

          this.appActionsService.materialsUpdated.next();
          resolve()



        },

        undefined,

        // onError callback
        (err) => {
          reject()
        }
      );
    })





  }

  // swapMaterial(materialItem) {

  //   let newMat = new THREE.MeshLambertMaterial({ side: THREE.DoubleSide })
  //   materialItem.usedByObjects.forEach(name => {
  //     let o = this.model.getObjectByName(name);

  //     o.material = newMat
  //     materialItem.material = newMat;

  //   })
  // }

  materialLabelClicked(materialItem) {

    for (let mi of this.materials) {
      if (mi == materialItem) {
        if (materialItem.openInList) {
          materialItem['openInList'] = false;
        } else {
          materialItem['openInList'] = true;
        }
      } else {
        mi['openInList'] = false;
        mi['highlight'] = false;
      }

    }




  }

  transparentAllMaterial(exclude?) {
    if (exclude == null) {
      exclude = [];
    }

    for (let materialItem of this.materials) {

      if (exclude.indexOf(materialItem) == -1) {
        if (!materialItem.transparent) {

          materialItem['originalOpacity'] = materialItem.material.opacity;
          materialItem['originalTransparency'] = materialItem.material.transparent;
          materialItem['transparent'] = true;


          materialItem.material.transparent = true;
          materialItem.material.opacity = 0.1
        }


      }

    }


    this.appActionsService.materialsUpdated.next();
  }

  highlightMaterial(materialItem) {

    materialItem.highlight = true;
    this.transparentAllMaterial([materialItem]);



  }

  returnAllMaterialsTocolor() {
    for (let materialItem of this.materials) {
      if (materialItem.transparent) {
        materialItem['transparent'] = false;



        materialItem.material.transparent = materialItem.originalTransparency;
        materialItem.material.opacity = materialItem.originalOpacity;
      }

    }
  }


  highlightOffMaterial(materialItem) {
    materialItem.highlight = false;
    this.returnAllMaterialsTocolor();
    this.appActionsService.materialsUpdated.next();
  }

  materialNameChanged(materialItem, value) {


    this.saveToOpenedConfig(materialItem)

  }

  uploadTextureClicked(input) {
    input.value = null;
    input.click();
  }





  stopEnterKeyPress(e) {
    if (e.which == 13) {
      e.target.blur();
    }
  }

  removeTextureFromMaterial(materialItem, saveToConfig?) {
    //console.log('remove texture from material run')
    materialItem.material.map = null;
    materialItem.material.needsUpdate = true;
    materialItem.textureId = null;

    if (saveToConfig) { //required in oreder to create a loop while updating (when changing is not needed - when it changed on firebase and we just need to update our client side). although it will not be a loop, as the 2nd save, will not trigger a child_changed.
      this.saveToOpenedConfig(materialItem)
    }



  }


  loadConfig(config) {

    if (config == null) {
      return;
    }


    let c = 0;
    for (let key in config.materials) {
      this.namesToKeys[config.materials[key].name] = key;
      c++;
    }

    for (let materialItem of this.materials) {

      let configedMaterial = config.materials[this.namesToKeys[materialItem.material.name]]
      // console.log(configedMaterial,this.namesToKeys[materialItem.material.name])
      if (configedMaterial && (this.namesToKeys[materialItem.material.name] != undefined)) {



        materialItem.material.color.set(configedMaterial.color)
        materialItem.color = configedMaterial.color;
        materialItem.scale = configedMaterial.scale || 1;
        materialItem.material.userData.name = configedMaterial.userName


        if (configedMaterial.textureId) {

          this.loadTexture(materialItem, configedMaterial.textureId).then(() => {
            if (configedMaterial.scale) {
              console.log(configedMaterial)
              this.changeScale(materialItem, configedMaterial.scale)
            }
          }).catch(
            err => { console.warn(err) }
          )

        } else {
          this.removeTextureFromMaterial(materialItem)
        }

        if (configedMaterial.opacity) {
          this.changeMaterialOpacity(materialItem, configedMaterial.opacity)
        }






        this.saveOpenedConfigKeyToViewConfig();

        this.appActionsService.materialsUpdated.next();



      }
    }



  }


  createNewConfigClicked(select) {
    this.createNewConfig()
    select.close()

  }

  onTextureClickedOnFolder(texture) {

    if (this.waitingForTexture) {
      this.appActionsService.textureFolderOpen = false;
      this.onTextureChosen(this.waitingForTexture, texture.key)

      this.waitingForTexture = false;
    }
  }


  browseTextures(material) {
    this.appActionsService.textureFolderOpen = true;
    this.waitingForTexture = material

  }


  openDialog(): void {
    const dialogRef = this.dialog.open(EditNameDialog, {
      width: '250px',
      data: { name: this.configs[this.openedConfigKey].name }
    });

    dialogRef.afterClosed().subscribe(result => {

      this.editButton['_elementRef'].nativeElement.classList.remove('cdk-focused')
      this.editButton['_elementRef'].nativeElement.classList.remove('cdk-program-focused')

      if (result != undefined) {

        if ((/\S/.test(result))) {
          this.materialConfigNameChanged(this.openedConfigKey, result)
        }

      }

    });
  }


}

@Component({
  selector: 'edit-name-dialog',
  templateUrl: 'edit-name-dialog.html',
})

export class EditNameDialog implements OnInit {
  nameIsValid = null

  constructor(
    public dialogRef: MatDialogRef<EditNameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.checkName()
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  checkName() {

    this.nameIsValid = (/\S/.test(this.data.name))

  }
}

