import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';

import { AppActionsService } from '../../services/app-actions.service';
import { ToolboxEvent } from '../../../models/toolbox-event';
import { DataService } from '../../services/data.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-place-on-map-tool',
  templateUrl: './place-on-map-tool.component.html',
  styleUrls: ['./place-on-map-tool.component.scss']
})
export class PlaceOnMapToolComponent implements OnInit, OnChanges, OnDestroy {
  subscriptions: Subscription[] = [];
  syncLonLat = false;
  inProcess = false;
  saveDataMessage = null;
  processMessage = null;
  mapboxModes = {
    'off': {
      icon: 'terrain',
      text: 'Reunion Island'
    },
    'map': {
      icon: 'map',
      text: 'Streets'
    },
    'sat': {
      icon: 'satellite',
      text: 'Satellite'
    }
  }


  constructor(private appActionsService: AppActionsService, private dataService: DataService) { }

  ngOnInit() {

    this.subscriptions.push(this.dataService.siteDataFetched.subscribe(() => {

      this.applyProperties();
    })
    )

    this.subscriptions.push(
      this.appActionsService.toolboxEvents.subscribe(event => {
        if (event.tool == 'placeOnMapMode') {
          if (event.type == 'updates') {
           
            if (this.syncLonLat) {
              if (event.updates['LLFromDblClick']) {
                let LL = event.updates['LLFromDblClick'];

                this.dataService.siteData.longitude = Number(LL.lon.toFixed(6));
                this.dataService.siteData.latitude = Number(LL.lat.toFixed(6));

                // this.dataService.siteData.offsetX = 0;
                // this.dataService.siteData.offsetY = 0;
                // this.dataService.siteData.offsetZ = 0;
                this.applyProperties();
              }
            }

          }
        }
      })
    )





  }

  ngOnChanges() {
    console.log('mapbox oncChanges')

  }

  ngOnDestroy() {
    console.log('ngOnDestroy')
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  getIfcSiteData() {

  }



  applyProperties() {

 
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { updateSiteProperties: this.dataService.siteData }))

  }

  flyToSite() {
    this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { flyToSite: true }))
  }

  increaseProperty(name, value) {
    this.dataService.siteData[name] = this.dataService.siteData[name] + value;
    this.applyProperties();
  }

  saveData() {
    this.inProcess = true;
    this.processMessage = "Saving data..."
    this.dataService.saveSiteData().then(
      resolved => {

        this.inProcess = false;
      },
      rejeted => {
        this.saveDataMessage = "Error saving data."
        this.inProcess = false;
      }
    )
  }

  onRestoreClicked() {
    this.dataService.restoreSiteData().then(
      resolved => {
        this.applyProperties();
      }
    )
  }


  onSyncClick() {
    this.syncLonLat = !this.syncLonLat;
  }

  mapSelectedChanged(e) {

    this.appActionsService.toolboxEvents.next(new ToolboxEvent('placeOnMapMode', 'updates', this.constructor.name, { switchMapMode: e.value }))
  }

}
