import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss']
})
export class LabelsComponent implements OnInit, OnChanges {
  @Input('labels') labels: any[];
  openLabel = null;



  constructor() { }

  ngOnInit() {

  }




  ngOnChanges(c) {
    console.log('changes',c)
  }

  toggleLabel(label) {
    
    if (label.alwaysShowText) { //dont do anything if this label is always showed. (it will not close other labels on click... mainly..)
      return;
    }

    if (this.openLabel == label) {
      this.openLabel = null;
    } else {
      this.openLabel  = label
    }
   
  }

}
