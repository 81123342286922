import { Pipe, PipeTransform } from '@angular/core';
/*

*/
@Pipe({ name: 'filterIn',pure:false  })

export class filterInPipe implements PipeTransform {

    transform(items: any[], property:string): any {
        
        return items.filter(item =>  {
        
          if (item[property] == true) {
              return true;
          } else {
              return false;
          }
           
        });

    }
}