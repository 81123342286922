import { Component, OnInit, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, HostListener } from '@angular/core';

import * as firebase from 'firebase';
import { DataService } from './services/data.service';
import { AppActionsService } from './services/app-actions.service';
import * as introJs from 'intro.js/intro.js';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  cookieOk=false;

  projectLoaded = false;
  windows = {
    projectFolder: true,
    userSettings: false,
    viewsEditor: false
  }
  frontElementId = null;

  introJS = introJs();
  statusThree = false;
  statusToolbox = false;
  statusMenu = false;
  viewEditorOpen = false;

  hideBrowserSupportWarning = false;
  
  projectDataReady = false;

  constructor(
    private translate: TranslateService,  private dataService: DataService, private appActionsService: AppActionsService, private renderer: Renderer2, private cdr: ChangeDetectorRef
  ) { 

    translate.setDefaultLang('en');
    translate.use('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    console.log(translate)
    if ( window.navigator.language.slice(0, 2) == 'fr') {
     
      console.log('Lang : fr');
      translate.use('fr');
    }


  }

  @HostListener('window:error', ['$event'])
  onKeyDown(event) {
    console.log(event)
  }

  ngOnInit() {

    this.appActionsService.cookieOk.subscribe( ()=>{
      this.cookieOk = true;
    })

    //subscribe to manage toggling windows:
    this.appActionsService.openCloseProjectsFolder.subscribe(status => {
      if (status == 'open') {
        this.closeAllWindows();
        this.windows.projectFolder = true;
      }

      if (status == 'close') {
        this.windows.projectFolder = false;
      }
      this.cdr.detectChanges();
    })

    this.appActionsService.openCloseUserSettings.subscribe(status => {

      if (status == 'open') {
        this.closeAllWindows();
        this.windows.userSettings = true;
      }

      if (status == 'close') {
        this.windows.userSettings = false;
      }
    })

    this.appActionsService.openCloseViewsEditor.subscribe(status => {

      if (status == 'open') {
        this.closeAllWindows();
        this.windows.viewsEditor = true;
        this.moveToFront('app-view-editor')
      }

      if (status == 'close') {
        this.windows.viewsEditor = false;
      }
    })

    this.appActionsService.openPhoto.subscribe(() => {

      this.moveToFront('app-photo')

    })

    this.appActionsService.openNote.subscribe(() => {

      this.moveToFront('app-note')

    })

   
    this.appActionsService.projectDataReady.subscribe(isReady => {
      this.projectDataReady = isReady;
      this.cdr.detectChanges();
    })

    //subscribe for loading project:
    this.appActionsService.openProject.subscribe(project => {
      if (this.projectLoaded) {
        this.projectLoaded = false; // soppose to destroy last loaded project if was...
        this.appActionsService.threeJsIsReady = false;
        this.cdr.detectChanges();
      }

      this.projectLoaded = project;
      this.cdr.detectChanges();
   


    })

    

     

   
  }

  /*
  * Tuto with intro.js : https://introjs.com/docs/intro/api/
  * TODO : use the "en.json" and the "fr.json" file from ngx-translate
  */
  startTuto(){
    //Dom is loaded and rdy
    if(this.statusThree && this.statusToolbox && this.statusMenu) {
      
      // si on es en mode viewer
      if (!this.appActionsService.editorMode) {
      
        //Click on menu button to switch to the editor mode
        let projectButtonElement : HTMLElement = document.getElementById('projectButton') as HTMLElement;      

        projectButtonElement.addEventListener("click", function() {
          document.getElementById('swapButtonMode').click();  
          
        }, {once : true});

        projectButtonElement.click();     

      }    
      if(this.translate.currentLang == 'en'){
        //setup tuto
      this.introJS
      .setOptions({
        steps: [
        {
          intro: 
            "<h1>Welcome</h1>"+            
            "<i>Quick tutorial to Learn how to use the app</i>"+   
            
            
            "<div class='zoneKey'>"+     
              "<span class='star'>*</span>"+

              "<div class='labelKey'>"+
                "<p>After this introduction, you could use the keyboard & the mouse to move on the project</p>"+
                "<span class='material-icons'>keyboard</span>"+  
                "<span class='material-icons'>mouse</span>"+  
              "</div>"+

              "<span class='material-icons'>keyboard_arrow_left</span>"+
              "<span class='material-icons'>keyboard_arrow_right</span>"+  
              "<span class='material-icons'>keyboard_arrow_up</span>"+
              "<span class='material-icons'>keyboard_arrow_down</span>"+

              
              
              "<div class='labelKeyLeft'>"+      
                "<p>More navigation options</p>"+          
                "<div class='line'><span class='material-icons'>keyboard</span> <p>With the SHIFT key pressed</p></div>"+  
                "<div class='line'><span class='material-icons'>mouse</span> <p>With the mouse wheel</p></div>"+                  
              "</div>"+


            "</div>",
            
                  
          
           tooltipClass : "tooltipClassStep1",
           showStepNumbers : false,
          // highlightClass : "highlightClass"
          nextLabel : "Start"
        },
        {
          element: '#navTools',
          step : 1,
          showStepNumbers : true,
          intro: "<p>This is the toolbox of navigation options, let's see this in details <span class='material-icons'>search</span></p>",
          disableInteraction : true,
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-3',
          intro: '<p>This is the <b>Fullscreen mode</b>, may you could try ?</p>',
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-4',
          intro: '<p>Video game mode to move in the project at the 1st person with the keyboard and the mouse</p>',
          disableInteraction : true,
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-5',
          intro: '<p>A button to zoom in, you could use the mouse wheel too</p>',
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-6',
          intro: '<p>A button to zoom out, you could use the mouse wheel too</p>',
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-7',
          intro: '<p>Click on this button to recenter the zoom view</p>',
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-8',
          intro: '<p>Here a way to recenter the rotation</p>',
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-9',
          intro: "<p>A shortcut to create notes</p>",
          position: 'left'         
        },
        {
           element: '.step-10',
           intro: '<p>The <strong>transparent</strong> mode</p>',
           position: 'left'
        },
        {
          element: '.step-11',
          intro: '<p>Clipping Planes</p>',
          position: 'bottom'
       },
       {
         element: '.step-12',
         intro: '<p>The <strong>rumble</strong> tool</p>',
         position: 'right'
        },
        {
          element: '.step-13',
          intro: '<p>Place on map button</p>',
          position: 'right'
        },
        {
          element: '.step-14',
          intro: '<p>Éditorial mode toolbox</p>',
          position: 'right'      
        },      
        {
          element: '.step-15',
          intro: '<p><strong>Tree view</strong> mode</p>',
          position: 'right'      
        },
        {
          element: '.step-16',
          intro: '<p><strong>Layer view</strong> mode</p>',
          position: 'right'      
        },
        {
          element: '.step-17',
          intro: '<p><strong>Zone view</strong> mode</p>',
          position: 'right'      
        },
        {
          element: '.step-18',
          intro: '<p>A shortcut to create notes</p>',
          position: 'right'      
        },
        {
          element: '.step-19',
          intro: '<p>A tool to add layer pdf</p>',
          position: 'right'      
        },
        {
          element: '.step-20',
          intro: '<p>A shortcut to create labels</p>',
          position: 'right'      
        },
        {
          element: '.step-21',
          intro: '<p>Manage materials and textures</p>',
          position: 'right'      
        },
        {      
          element: '.step-22',
          intro: '<p>Capture a screenshot</p>',
          position: 'right'      
        },
        {
          element: '.step-23',
          intro: '<p>Galery photo</p>',
          position: 'right'
        },
        {
          element: '.step-24',
          intro: '<p>swap menu & views list</p>',
          position: 'bottom'
        }
        ],
        //option : https://introjs.com/docs/intro/options/
        hidePrev: true,        
        showProgress : true,
        hideNext: true,
        scrollToElement : false,
        exitOnEsc : false,
        exitOnOverlayClick : false,
        showBullets : false
      })
      .onafterchange(function(targetElement) {
        
        targetElement.firstClass = targetElement.className.split(' ')[0];

        // Special instruction 
        if(targetElement.firstClass == 'step-14'){                   
          
          let introHelper : HTMLElement = document.getElementsByClassName('introjs-helperNumberLayer')[0] as HTMLElement;     
          introHelper.style.display = "none" ;
          
        }

      })      
      .start()          
      }else{
      //setup tuto
      this.introJS
      .setOptions({
        steps: [
        {
          intro:
            "<h1>Bienvenue</h1>"+            
            "<i>Tutoriel rapide de prise en main de l'application Bimshow</i>"+  
           
           
            "<div class='zoneKey'>"+    
              "<span class='star'>*</span>"+

              "<div class='labelKey'>"+
                "<p>Après cette introduction, vous pourrez naviguer dans le projet.</p>"+
                "<span class='material-icons'>keyboard</span>"+  
                "<span class='material-icons'>mouse</span>"+  
              "</div>"+

              "<span class='material-icons'>keyboard_arrow_left</span>"+
              "<span class='material-icons'>keyboard_arrow_right</span>"+  
              "<span class='material-icons'>keyboard_arrow_up</span>"+
              "<span class='material-icons'>keyboard_arrow_down</span>"+

             
             
              "<div class='labelKeyLeft'>"+      
                "<p>Plus d'options de navigation</p>"+          
                "<div class='line'><span class='material-icons'>clavier</span> <p>Avec la touche SHIFT activée</p></div>"+  
                "<div class='line'><span class='material-icons'>souris</span> <p>Avec la moellete de la souris </p></div>"+                  
              "</div>"+


            "</div>",
           
                 
         
           tooltipClass : "tooltipClassStep1",
           showStepNumbers : false,
          // highlightClass : "highlightClass"
          nextLabel : "Commencer"
        },
        {
          element: '#navTools',
          step : 1,
          showStepNumbers : true,
          intro: "<p>Voici la boîte à outils, regardons en détail<span class='material-icons'>search</span></p>",
          disableInteraction : true,
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-3',
          intro: "<p>Voici le mode  <b>Plein écran</b>, vous pourriez l'essayer ?</p>",
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-4',
          intro: '<p>Mode jeu vidéo pour se déplacer avec les flèches et la souris et explorer les pièces du prjet.</p>',
          disableInteraction : true,
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-5',
          intro: '<p>Bouton pour zoomer, vous pouvez aussi utiliser la molette de la souris</p>',
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-6',
          intro: '<p>Bouton pour dézoomer, tout comme la molette de la souris</p>',
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-7',
          intro: '<p>Cliquer pour recentrer le projet</p>',
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-8',
          intro: "<p>Cliquer ici pour remettre le nord en haut de l'écran</p>",
          highlightClass : "roundElement",
          tooltipClass : "justifyIntro",
          position: 'top'
        },
        {
          element: '.step-9',
          intro: "<p>Raccourci pour créer des notes</p>",
          position: 'left'        
        },
        {
           element: '.step-10',
           intro: '<p>Le mode <strong>transparent</strong></p>',
           position: 'left'
        },
        {
          element: '.step-11',
          intro: '<p>Mode plan de coupe pour afficher une partie du projet. Créer une oîte de découpe.</p>',
          position: 'bottom'
       },
       {
         element: '.step-12',
         intro: '<p>La <strong>règle</strong> de mesure</p>',
         position: 'right'
        },
        {
          element: '.step-13',
          intro: '<p><strong>Geolocalisation</strong> du projet.</p>',
          position: 'right'
        },
        {
          element: '.step-14',
          intro: '<p>Boîte à outil du <strong>Mode Edition</strong></p>',
          position: 'right'      
        },      
        {
          element: '.step-15',
          intro: '<p><strong>Arborescence de fichier IFC</strong> du projet</p>',
          position: 'right'      
        },
        {
          element: '.step-16',
          intro: '<p>Affichage des <strong>calques</strong> du projet</p>',
          position: 'right'      
        },
        {
          element: '.step-17',
          intro: '<p>Affichage des<strong>zones</strong> du projet</p>',
          position: 'right'      
        },
        {
          element: '.step-18',
          intro: '<p>Raccourci pour créer des notes</p>',
          position: 'right'      
        },
        {
          element: '.step-19',
          intro: "<p>Outil ajout d'images, plans ou photos</p>",
          position: 'right'      
        },
        {
          element: '.step-20',
          intro: '<p>Outil étiquettes (labels)</p>',
          position: 'right'      
        },
        {
          element: '.step-21',
          intro: "<p>Outil d'édition des materiaux et textures</p>",
          position: 'right'      
        },
        {      
          element: '.step-22',
          intro: '<p>Prendre une photo et la placer dans la galerie</p>',
          position: 'right'      
        },
        {
          element: '.step-23',
          intro: '<p>Galerie de photos</p>',
          position: 'right'
        },
        {
          element: '.step-24',
          intro: '<p>Menu éditeur de vues</p>',
          position: 'bottom'
        }
        ],
        //option : https://introjs.com/docs/intro/options/
        hidePrev: true,        
        showProgress : true,
        hideNext: true,
        scrollToElement : false,
        exitOnEsc : false,
        exitOnOverlayClick : false,
        showBullets : false
      })
      .onafterchange(function(targetElement) {
        
        targetElement.firstClass = targetElement.className.split(' ')[0];

        // Special instruction 
        if(targetElement.firstClass == 'step-14'){                   
          
          let introHelper : HTMLElement = document.getElementsByClassName('introjs-helperNumberLayer')[0] as HTMLElement;     
          introHelper.style.display = "none" ;
          
        }

      })      
      .start() 
      }
      
    }
      
  }

  // receiveMessageOfstaturtTuto($event){
  //   this.startTuto();
  // }
 
  // receiveMessageOfstatusToolbox($event){    
  //   this.statusToolbox = $event; 
  //   this.startTuto();
  // }
  
  // receiveMessageOfstatusThree($event){    
  //   this.statusThree = $event;
  //   this.startTuto();    
  // }

  // receiveMessageOfstatusMenu($event){    
  //   this.statusMenu = $event;    
  //   this.startTuto();    
  // }
  
  toggleViewsEditor() {
    if (this.viewEditorOpen) {
      this.appActionsService.openCloseViewsEditor.next('close')
    } else {
      this.appActionsService.openCloseViewsEditor.next('open');
    }
  }

  closeAllWindows() {
    for (let key in this.windows) {
      this.windows[key] = false;
    }

    this.appActionsService.openCloseProjectsFolder.next('close')
    this.appActionsService.openCloseUserSettings.next('close')
    this.appActionsService.openCloseViewsEditor.next('close')
  }

  moveToFront(id) {

    this.frontElementId = id;

  }

  browserSupportWarningDismissed() {
    this.hideBrowserSupportWarning = true;
    console.log(  'asd')
  }



}
