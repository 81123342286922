import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

  sliderHueBackground = null;
  @Input('color') color: any;
  @Input('iconAsButton') iconAsButton: boolean;
  @Output() colorChange = new EventEmitter<string>(); 
  @Output() change = new EventEmitter<string>(); 
  @Output() closed = new EventEmitter<string>(); 
  constructor() { }

  ngOnInit() {
    this.generateHueBackground();
  }

  labelColorChanged(a, b) {
    console.log(a, b)
  }


  generateHueBackground() {
    this.sliderHueBackground = 'linear-gradient(to right'

    for (let i = 0; i < 360; i++) {
      this.sliderHueBackground = this.sliderHueBackground + ',hsl(' + i + ',100%,50%)'
    }

    this.sliderHueBackground = this.sliderHueBackground + ')'
  }

  onColorChanged(e) {
    this.colorChange.emit(this.color)
    this.change.emit(this.color)
  }

  onMenuClosed() {
    console.log('closed')
    this.closed.emit(this.color)
  }

}
