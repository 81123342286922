import { Component, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { User } from '../../models/user.model';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToolboxEvent } from 'src/models/toolbox-event';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {

  user = null;
  fullscreen = false;
  currentLoadedProject = null;
  viewsList = [];
  viewEditorOpen = false;
  isAvailable = false;
  isOwner = false;
  projectFolderOpen = false;

  subscriptions: Subscription[] = [];

  // to output a message to parent when the user-menu is loaded and rdy to start tutoJS
  statusMenu = false;
  @Output() eventStatusMenu = new EventEmitter<any>();
  @Output() eventStartTuto = new EventEmitter<any>();

  constructor(private translate: TranslateService, private appActionsService: AppActionsService, private dataService: DataService, private router: Router, private cdr:ChangeDetectorRef) { }


  ngOnInit() {
    console.log('init ')
    this.subscriptions.push(
      this.appActionsService.openProject.subscribe(project => {
        this.currentLoadedProject = project;
        
        if (project.roles[this.dataService.user.id] == 'owner') {
          this.isOwner = true;
        } else {
          this.isOwner = false;
        }
      })
    )



    this.subscriptions.push(
      this.appActionsService.viewsDataLoaded.subscribe(() => {
        this.generateViewsList();

      })
    )

    this.subscriptions.push(
      this.appActionsService.viewsDataUpdated.subscribe(() => {
        this.generateViewsList();

      })
    )

    this.subscriptions.push(
      this.appActionsService.openCloseViewsEditor.subscribe(status => {

        if (status == 'open') {
          this.viewEditorOpen = true;
        }

        if (status == 'close') {
          this.viewEditorOpen = false;
        }
      })
    )

    this.subscriptions.push(
      this.appActionsService.openCloseProjectsFolder.subscribe( status => {
     
        if (status == 'open') {
          this.projectFolderOpen = true;
        }

        if (status == 'close') {
          this.projectFolderOpen = false;
        }
      })
    )

    // send message to parent to inform three is loaded
    this.statusMenu = true
    this.sendMessage();

  }

  ngAfterViewInit() {
    // this.updateLangIcon();
  }
  updateLangIcon() {

    let flagBtn = document.getElementById('flagBtn');
    console.log(this.translate.currentLang);
    (this.translate.currentLang == 'en') ? (

      flagBtn.classList.remove('flag-icon-fr'),
      flagBtn.classList.add('flag-icon-gb'),
      console.log(flagBtn)

    )
      : (

        flagBtn.classList.remove('flag-icon-gb'),
        flagBtn.classList.add('flag-icon-fr')

      );
  }

  changeLang() {
    //toggle switch current language and flag with : https://github.com/lipis/flag-icon-css/blob/master/css/flag-icon.css


    (this.translate.currentLang == 'en') ? (
      this.translate.use('fr'),

      console.log('Lang : Fr')
    )
      : (
        this.translate.use('en'),

        console.log('Lang : En')
      );
  }

  startTuto() {
    console.log('startTuto');
    this.eventStartTuto.emit(true);
  }

  sendMessage() {
    this.eventStatusMenu.emit(this.statusMenu);
  }

  ngOnDestroy() {

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }



  generateViewsList() {
    console.log('generating viewlist ')
    this.viewsList = []

    //old views list (for multiple eidtors version.. )
    // for (let viewId in this.dataService.views) {

    //   if (this.viewsList[this.dataService.views[viewId].createdBy] == null) {
    //     this.viewsList[this.dataService.views[viewId].createdBy] = {views: []};
    //     this.dataService.getUserById(this.dataService.views[viewId].createdBy).then(
    //       user => {
    //         this.viewsList[this.dataService.views[viewId].createdBy]['details'] = user
    //       }
    //     )
    //   }

    //   this.viewsList[this.dataService.views[viewId].createdBy].views.push(this.dataService.views[viewId])
    // }


    //new view list only for owner:
    
    for (let viewId in this.dataService.views) {
      console.log(this.dataService.views[viewId].createdBy , this.dataService.user.id)
      if (this.dataService.views[viewId].createdBy == this.dataService.user.id) {
        this.viewsList.push(this.dataService.views[viewId])
      }


    }

    this.cdr.detectChanges();
    console.log(this.viewsList)
  }

  menuBtnClicked(event) {

    let e = event.srcElement;
    let newState = null;

    if (!e.classList.contains('active')) {
      newState = 'active';
    }

    let buttons = document.getElementsByClassName('user-menu-btn');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('active');
    }

    if (newState) {
      e.classList.add(newState);
    }

  }

  openProjectFolder() {
    this.appActionsService.openCloseProjectsFolder.next('open');
  }

  toggleProjectsFolder() {

    if (this.projectFolderOpen) {
      this.appActionsService.openCloseProjectsFolder.next('close')
    } else {
      this.appActionsService.openCloseProjectsFolder.next('open');
    }
  }

  myProfileClicked() {
    this.appActionsService.openCloseUserSettings.next('open');
  }



  logoutClicked() {

    this.dataService.logout().then(() => {

      window.location.replace('/')
    })
  }

  loadView(viewId) {
    this.dataService.loadView(viewId)
  }

  toggleEditorViewerMode(e) {
    //e.stopPropagation();
    this.appActionsService.editorMode = !this.appActionsService.editorMode;
    if (this.dataService.viewConfig.selectedMode == 'measureMode') {
      this.appActionsService.toolboxEvents.next(new ToolboxEvent(null, null, this.constructor.name))
    }

  }

  toggleViewsEditor() {

    if (this.viewEditorOpen) {
      this.appActionsService.openCloseViewsEditor.next('close')
    } else {
      this.appActionsService.openCloseViewsEditor.next('open');
    }




  }


}
