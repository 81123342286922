

import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';

@Injectable({
    providedIn: 'root',
})


export class ZipService {
    constructor() {
        console.log('zipService constructor')

    }


    zip(file) {
        return new Promise<any>((resolve, reject) => {
            var zip = new JSZip();
            zip.file(file.name, file)
        



            zip.generateAsync({ 
                type: "blob" ,
                compression: "DEFLATE",
                compressionOptions: {
                    level: 9
                }})
                .then( (content) => {
                    console.log('zipped file, resolved as blog')
                    resolve(content);
                }).catch(err => {
                    reject(err)
                });
        })

    }

    getZipContent(file) {
        console.log('zip runs')
        const jszip = new JSZip();
        jszip.loadAsync(file).then((zip) => {
            // Do something with the zipcontent
            console.log(zip)
        });
    }


    unzip(file) {
        return new Promise<any>((resolve, reject) => {
            const jszip = new JSZip();
            jszip.loadAsync(file).then((zip) => {
                console.log(zip)
                Object.keys(zip.files).forEach((filename) => {
                    
                    zip.files[filename].async('blob').then((blobFile) => {
                       
                        resolve(blobFile)
                    });
                });
            }).catch(err=>{reject(err)});
        }).catch(err =>{ console.warn(err)})

    }

    downloadContent(content, name) {
        let dataurl = window.URL.createObjectURL(content);
        var a = document.createElement("a");
        a.href = dataurl;
        a.setAttribute('target', '_blank');
        a.setAttribute('download', name)
        a.click();
    }
} { }