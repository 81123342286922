import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppActionsService } from '../services/app-actions.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-object-data',
  templateUrl: './object-data.component.html',
  styleUrls: ['./object-data.component.scss']
})
export class ObjectDataComponent implements OnInit, OnDestroy {
  objectData = null;
  relatedNotes = [];
  openNoteId = null;

  layerName = null;
  materials = [];
  label = null;

  oid = null;
  guid = null;
  fieldNamesTranslater = {
    '_t': 'Type',
    'GlobalId': 'GUID',
    'ObjectType': 'Object Type'
  }
  fieldFilterOut = { //need to build a pipe...
    oid: true,
    Tag: true,
  }
  subscriptions: Subscription[] = [];


  constructor(private appActionsService: AppActionsService, private dataService: DataService) { }


  ngOnInit() {


    this.subscriptions.push(this.appActionsService.chosenObjectOid.subscribe((oid) => {

      if (oid) {

        this.loadObject(oid);


      }

      else {
        this.closeObject();
      }

    }))

    this.subscriptions.push(this.appActionsService.openNote.subscribe(note => {
      if (note) {
        this.openNoteId = note.id;
      } else {
        this.openNoteId = null;
      }
    }))


    this.subscriptions.push(this.dataService.labelsUpdated.subscribe(() => {

      if (this.oid) {
        console.log('updated')
        this.loadObject(this.oid)
      }
    }))



    this.subscriptions.push(this.dataService.notesObjectsRelationsDataFetched.subscribe(() => {

      if (this.oid) {

        this.loadObject(this.oid)
      }

    }))

    this.subscriptions.push(this.dataService.notesDataFetched.subscribe(() => {

      if (this.oid) {

        this.loadObject(this.oid)
      }

    }))




  }



  ngOnDestroy() {
    this.closeObjectDataClicked();

    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
  }

  loadObject(oid) {

    this.oid = oid;
    this.guid = this.dataService.getGuidOfObjectId(oid);
    this.objectData = this.dataService.getObjectDataByObjectId(oid);

    this.relatedNotes = this.dataService.getRelatedNoteOfObject(this.guid);
    this.layerName = this.dataService.objectsData[oid].layerName;
    if (Array.isArray(this.dataService.objectsData[oid].material)) {
      this.materials = this.dataService.objectsData[oid].material.filter( (item, pos) =>{
        return this.dataService.objectsData[oid].material.indexOf(item) == pos;
      });
    } else {
      this.materials = [this.dataService.objectsData[oid].material]
    }

    this.label = this.dataService.getLabelOfObject(this.guid)

    if (this.appActionsService.selectedDataMode == 'materials-editor') {
      console.log('opening material')
      this.materialClicked(this.materials.length ? this.materials[0] : this.materials)

    }



  }

  closeObject() {
    this.guid = null;
    this.oid = null;
    this.objectData = null;
    this.relatedNotes = null;
  }

  closeObjectDataClicked() {

    this.appActionsService.chosenObjectOid.next(null);

  }

  noteClickedOnList(note) {
    this.appActionsService.openNote.next(note);
  }

  addNewNoteClicked() {
    this.dataService.createNote('New note title', 'Enter note description here', 'none').then(note => {

      this.dataService.addRelatedObjectToNote(note.id, this.guid).then(() => {
        this.appActionsService.openNote.next(note)
      })

    })
  }

  createLabelClicked() {

    this.dataService.addLabel(this.guid, 'New Label', '', true).then(
      resolved => {
        this.appActionsService.objectDataAction.next({ action: 'labelToggleVisible', guid: this.guid })
      }

    )
  }

  labelClicked() {

    this.appActionsService.objectDataAction.next({ action: 'labelClicked', guid: this.guid })
  }

  materialClicked(material) {

    this.appActionsService.objectDataAction.next({ action: 'materialClicked', materialName: material.name })
  }

  layerClicked() {
    this.appActionsService.objectDataAction.next({ action: 'layerClicked', layerName: this.layerName })
  }


}
